<template>
  <b-card class="a-card">
    <div
      class="a-card-header"
      @click="subcategorie == true ? (view = !view) : null"
    >
      <div
        class="a-card-header-flex"
        :class="{
          'a-card-header-switch': IconSwitch,
          'a-card-header-montant': titleMontant,
          'a-card-header-type-project': titleTypeProject,
          'a-card-header-subcategorie': subcategorie == true
        }"
      >
        <div class="flex align-center">
          <div
            class="title-card"
            :class="{
              'color-title': epinglerNote,
              'style-title': titleTypeProject,
              'grey-color-title': greyColorTitle
            }"
          >
            <slot name="title"></slot>
          </div>
          <div
            v-if="IconSwitch && IconSwitch.define_subtype"
            class="button-switch"
          >
            <b-form-checkbox
              switch
              size="lg"
              :value="1"
              :unchecked-value="0"
              v-model="IconSwitch.is_active"
              @change="toggleSwitch(IconSwitch)"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="groupe-body-card">
          <div v-if="titleTypeProject" class="style-title-type">
            {{ titleTypeProject }}
          </div>

          <div
            class="body-card flex justify-between align-center mr-4"
            v-if="titleMontant"
          >
            <div v-for="item in titleMontant" :key="item.id">
              <div v-if="item.is_header" class="flex">
                <div class="bold">{{ item.name }}</div>
                <div class="value-ttc">
                  {{
                    item.value && item.value.name ? item.value.name : item.value
                  }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="subcategorie == true"
            :class="{
              'red-circle': subCategoryColor == 'red',
              'green-circle': subCategoryColor == 'green',
              'grey-circle': subCategoryColor == 'grey',
              'orange-circle': subCategoryColor == 'orange'
            }"
          >
            <font-awesome-icon
              icon="angle-down"
              class="flesh-down"
              v-if="
                subCategoryColor != 'green' &&
                  subCategoryColor != 'red' &&
                  subCategoryColor != 'grey' &&
                  subCategoryColor != 'orange' &&
                  view == false
              "
            />
            <font-awesome-icon
              icon="angle-up"
              class="flesh-up"
              v-if="
                subCategoryColor != 'green' &&
                  subCategoryColor != 'red' &&
                  subCategoryColor != 'grey' &&
                  subCategoryColor != 'orange' &&
                  view == true
              "
            />
          </div>
        </div>
        <div
          class="delete-project-card flex justify-between align-center"
          v-if="deleteProjet"
        >
          <div class="delete-project" v-if="currentUser && currentUser.isSuper">
            <a href="#" @click.prevent="handleDeleteProject">
              <font-awesome-icon class="mr-1" icon="trash" />
              Supprimer ce projet
            </a>
          </div>
        </div>
      </div>
      <hr
        class="line-hr"
        v-if="!cardPrincipal && !notBody"
        :class="{ 'display-body': subcategorie == true && view == false }"
      />
    </div>
    <div
      class="a-card-body"
      :class="{ 'display-body': subcategorie == true && view == false }"
    >
      <slot name="body"></slot>
      <!-- <div class="show-button" v-if="showButton && IconSwitch.id == 63">
        {{ showButton }}
      </div> -->
    </div>
    <!-- Delete project Modal -->
    <b-modal
      v-if="project"
      no-close-on-backdrop
      ref="delete-project"
      id="delete-project"
      title="Supprimer un projet"
      :hide-footer="true"
    >
      <div>
        Êtes-vous certain de vouloir supprimer ce projet
        <strong>
          {{ project.title ? ', ' + project.title : '' }}
        </strong>
        ?
        <div class="danger-message mt-2 mb-2">
          <font-awesome-icon class="mr-1" icon="exclamation-circle" /> La
          suppression sera définitive !
        </div>
      </div>
      <div class="message">
        <div
          v-if="getProjectsProcessing || getContactsProcessing"
          class="three-dots-loading"
        >
          Chargement en cours
        </div>
        <div v-if="getProjectsError" class="error">
          <ul v-if="Array.isArray(getProjectsError)">
            <li v-for="(e, index) in getProjectsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('delete-project')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button @click="doDeleteProject" variant="success">
          Valider
        </b-button>
      </div>
    </b-modal>
    <!-- END Delete project Modal -->
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    cardPrincipal: {
      required: false
    },
    titleMontant: {
      required: false
    },
    notBody: {
      type: Boolean,
      required: false
    },
    IconSwitch: {
      required: false
    },
    project: {
      required: false
    },
    showButton: {
      type: String,
      required: false
    },
    epinglerNote: {
      type: Boolean,
      required: false
    },
    titleTypeProject: { type: String, required: false },
    subtypes: {
      required: false
    },
    categorie: {
      required: false
    },
    deleteProjet: {
      required: false
    },
    subcategorie: {
      default: false
    },
    subCategoryColor: {
      required: false
    },
    greyColorTitle: {
      default: false
    },
    ongletMenu: {
      required: false
    }
  },
  data() {
    return {
      view:
        this.IconSwitch &&
        this.categorie &&
        ((this.categorie.id == 1 &&
          this.IconSwitch.id == 1 &&
          this.ongletMenu == 1) ||
          (this.categorie.id == 4 &&
            this.IconSwitch.id == 9 &&
            this.ongletMenu == 2))
          ? true
          : false
    }
  },
  methods: {
    ...mapActions(['deleteProject']),
    toggleSwitch() {
      let payload = {
        project_id: this.project.id,
        sousCategorie: this.IconSwitch,
        sousType: this.subtypes,
        categorie: this.categorie
      }
      this.$emit('switchActive', payload)
    },
    hideModal(ref) {
      if (ref && this.$refs[ref]) {
        this.$refs[ref].hide()
      }
    },
    handleDeleteProject() {
      this.$refs['delete-project'].show()
    },
    async doDeleteProject() {
      const response = await this.deleteProject(this.project)
      if (response) {
        this.hideModal('delete-project')
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getProjectsError',
      'getProjectsProcessing',
      'getContactsProcessing'
    ])
  },
  watch: {
    ongletMenu() {
      this.view =
        this.IconSwitch &&
        this.categorie &&
        ((this.categorie.id == 1 &&
          this.IconSwitch.id == 1 &&
          this.ongletMenu == 1) ||
          (this.categorie.id == 4 &&
            this.IconSwitch.id == 9 &&
            this.ongletMenu == 2))
          ? true
          : false
    }
  }
}
</script>

<style lang="scss" scoped>
.a-card {
  margin-top: 13px;
}
.a-card-header {
  cursor: default;
  .a-card-header-flex {
    display: flex;
    align-items: center;
    &.a-card-header-montant,
    &.a-card-header-type-project,
    &.a-card-header-subcategorie {
      justify-content: space-between;
    }
    .title-card {
      font-weight: bold;
      color: #5e5e5e;
      font-size: 13px;
      &.color-title {
        // color: #9460f2;
        color: #6c757d;
        font-weight: initial;
      }
      &.style-title {
        font-style: italic;
      }
      &.grey-color-title {
        color: #9460f2;
      }
    }
    .style-title-type {
      font-size: 12px;
      font-weight: bold;
      // margin-left: 281px;
    }
    .style-flex {
      display: flex;
      align-items: center;
    }
    .type-projet {
      font-weight: bold;
    }
    .groupe-body-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .body-card {
        .value-ttc {
          margin-left: 12px;
        }
      }
    }
    .delete-project-card {
      margin-left: 201px;
      .delete-project {
        a {
          color: #ff5722;
          font-weight: bold;
        }
      }
    }
  }
  .line-hr {
    margin-top: 10px;
  }
}
.card-body {
  padding: 7px 11px 7px 11px;
  .show-button {
    background-color: #e5e5e580;
    border: 1px solid #6c757d52;
    margin-left: 270px;
    text-align: center;
    padding: 3px;
    border-radius: 10px;
    font-weight: bold;
    min-width: 50px;
    max-width: 100px;
  }
}
#delete-project {
  .danger-message {
    color: #e73e01;
  }
}
.display-body {
  display: none;
}
.red-circle {
  background-color: #ed210d;
  height: 15px;
  width: 15px;
  border-radius: 50px;
}
.green-circle {
  background-color: #60d937;
  height: 15px;
  width: 15px;
  border-radius: 50px;
}
.grey-circle {
  background-color: #d3d3d3;
  height: 15px;
  width: 15px;
  border-radius: 50px;
}
.orange-circle {
  background-color: #ff9a06;
  height: 15px;
  width: 15px;
  border-radius: 50px;
}
.flesh-down,
.flesh-up {
  font-size: 15px;
}
</style>
<style lang="scss">
.a-card-header {
  .a-card-header-flex {
    &.a-card-header-switch {
      .button-switch {
        margin-top: -8px;
        margin-left: 15px;
        .custom-switch.b-custom-control-lg .custom-control-label::before,
        .input-group-lg .custom-switch .custom-control-label::before {
          top: 4px;
          height: 23px;
          left: -2.8125rem;
          width: 50px;
          border-radius: 7px;
        }
        .custom-switch.b-custom-control-lg .custom-control-label::after,
        .input-group-lg .custom-switch .custom-control-label::after {
          transform: translateX(-1.25rem);
          top: calc(0.3125rem + 2px);
          left: -19px;
          width: 20px;
          height: 16px;
          border-radius: 5px;
          background-size: 50% 50%;
        }
        .custom-control-input:checked ~ .custom-control-label::before {
          color: #9460f2;
          border-color: #e5e5e5;
          background-color: #e5e5e5;
        }
        .custom-switch.b-custom-control-lg
          .custom-control-input:checked
          ~ .custom-control-label::after,
        .input-group-lg
          .custom-switch
          .custom-control-input:checked
          ~ .custom-control-label::after {
          -webkit-transform: translateX(-0.0625rem);
          transform: translateX(-0.0625rem);
        }
        .custom-switch
          .custom-control-input:checked
          ~ .custom-control-label::after {
          background-color: #9460f2c4;
          -webkit-transform: translateX(0.75rem);
          transform: translateX(0.75rem);
        }
        .custom-control-input:not(:disabled):active
          ~ .custom-control-label::before {
          color: #fff;
          background-color: #e5e5e5;
          border-color: #e5e5e5;
        }
        .custom-control-label::before {
          position: absolute;
          top: 0.25rem;
          left: -1.5rem;
          display: block;
          width: 1rem;
          height: 1rem;
          pointer-events: none;
          content: '';
          background-color: #e5e5e5;
          border: #e5e5e5 solid 1px;
        }
      }
    }
  }
}
</style>
