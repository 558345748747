<template>
  <div
    class="editable-input"
    :class="{ small: small, classSpecifique: classSpecifique }"
  >
    <div class="edit-view" v-if="editing">
      <date-picker
        v-if="type === 'date'"
        v-model="newValue"
        format="DD-MM-YYYY"
        value-type="format"
        :type="type"
        ref="autofocus"
      ></date-picker>
      <date-picker
        v-else-if="type === 'time'"
        v-model="newValue"
        format="HH:mm"
        value-type="format"
        :type="type"
        ref="autofocus"
      ></date-picker>
      <input
        v-else
        class="border-input"
        :type="type"
        v-model="newValue"
        @keyup.enter="update"
        @keyup.esc="editing = false"
        ref="autofocus"
      />
      <div class="edit-action flex justify-content-end mt-1">
        <div @click="editing = false" class="edit-action-icon">
          <font-awesome-icon v-if="small || classSpecifique" icon="times" />
          <template v-else>
            <div @click="reset()" class="sans-border">Annuler</div></template
          >
        </div>
        <div @click="update" class="green edit-action-icon ml-2">
          <font-awesome-icon v-if="small || classSpecifique" icon="check" />
          <template v-else>Enregistrer</template>
        </div>
      </div>
    </div>
    <div class="show-view" v-else>
      <div class="value mr-2" :class="{ 'auto-width': autoWidth }">
        {{ value || '-' }}
      </div>
      <div v-if="!readonly" class="show-action" @click="editing = true">
        <font-awesome-icon class="icon" icon="pencil-alt" />
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: 'EditableInput',
  components: {
    DatePicker
  },
  props: {
    field: { required: true },
    entity: { required: true },
    value: { required: false },
    type: { default: 'text' },
    updateFunction: { required: true },
    autoWidth: { default: false },
    small: { default: false },
    readonly: { default: false },
    refrechProject: { required: false },
    project: { required: false },
    classSpecifique: { required: false }
  },
  data() {
    return {
      editing: false,
      newValue: this.value
    }
  },
  methods: {
    async update() {
      if (this.value != this.newValue) {
        const payload = {
          entity: this.entity,
          field: this.field,
          value: this.newValue
        }
        if (this.type == 'date' && this.newValue) {
          payload.value = moment(this.newValue, 'DD-MM-YYYY').format(
            'YYYY-MM-DD HH:mm:ss'
          )
        }
        await this.updateFunction(payload)
        if (this.field == 'first_name' || this.field == 'last_name')
          this.refrechProject(this.project)
      }
      this.editing = false
    },
    reset() {
      this.newValue = this.value
    }
  },
  watch: {
    editing(value) {
      if (value) {
        this.newValue = this.value
        this.$nextTick(() => {
          if (this.$refs.autofocus) {
            this.$refs.autofocus.focus()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.editable-input {
  .edit-view {
    .mx-datepicker {
      width: 186px;
      .mx-input-wrapper {
        .mx-input {
          width: 186px;
          height: 26px;
          border: 1px solid #e5e5e5;
          border-radius: 2px;
          padding: 6px 28px;
          padding-left: 10px;
        }
        .mx-icon-calendar {
          right: 10px;
        }
      }
    }
  }
  &.small {
    width: 114px;
    margin-left: 16px;
    margin-right: 10px;
    .value {
      width: 45px;
      padding-left: 6px;
    }
    .edit-view {
      .mx-datepicker {
        width: 114px;
        .mx-input-wrapper {
          .mx-input {
            width: 114px;
            height: 26px;
            border: 1px solid #e5e5e5;
            border-radius: 2px;
            padding: 6px 28px;
            padding-left: 10px;
          }
          .mx-icon-calendar {
            right: 7px;
          }
        }
      }
    }
  }
  &.classSpecifique {
    width: 114px;
    margin-left: 16px;
    margin-right: 10px;
    .value {
      width: 45px;
      padding-left: 6px;
    }
    .edit-view {
      .mx-datepicker {
        width: 114px;
        .mx-input-wrapper {
          .mx-input {
            width: 114px;
            height: 26px;
            border: 1px solid #e5e5e5;
            border-radius: 2px;
            padding: 6px 28px;
            padding-left: 10px;
          }
          .mx-icon-calendar {
            right: 7px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.editable-input {
  width: 54%;
  .edit-view {
    display: block;
    .border-input {
      border: 1px solid #e5e5e5;
    }
    .edit-action {
      .sans-border {
        border: 0px;
      }
      div {
        color: #5e5e5e;
        border: 1px solid #a9adb1;
        user-select: none;
        margin: 0;
        padding: 0px 2px 0px 2px;
        border-radius: 2px;
        font-size: 11px;
        font-weight: 600;
        &.green {
          background: #8bc34a;
          color: #fff;
          border: 1px solid #a9adb1;
        }
      }
    }
  }

  .show-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      .show-action {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #bdbdbd;
        border-radius: 4px;
        border: 1px solid #bdbdbd;
        width: 25px;
        height: 20px;
      }
    }

    .show-action {
      display: none;
      cursor: pointer;
      &:hover {
        border: 1px solid #404346 !important;
        color: #404346 !important;
      }
    }

    .value {
      width: 130px;
      &.auto-width {
        width: auto;
      }
    }
  }

  &.small {
    width: 114px;
    margin-left: 16px;
    margin-right: 10px;
    .value {
      width: 45px;
      padding-left: 6px;
    }
    .edit-view {
      .edit-action {
        .edit-action-icon {
          width: 23px;
          height: 20px;
          text-align: center;
          font-size: 10px;
        }
      }
      input {
        width: 100%;
      }
    }
  }
  &.classSpecifique {
    width: 61px;
    // margin-left: 16px;
    // margin-right: 10px;
    .value {
      width: 45px;
      padding-left: 6px;
    }
    .edit-view {
      .edit-action {
        .edit-action-icon {
          width: 36px;
          text-align: center;
          font-size: 14px;
        }
      }
      input {
        width: 100%;
      }
    }
    .show-view {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        .show-action {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #bdbdbd;
          border-radius: 4px;
          border: 1px solid #bdbdbd;
          width: 161px;
          height: 20px;
        }
      }

      .show-action {
        display: none;
        cursor: pointer;
        &:hover {
          border: 1px solid #404346 !important;
          color: #404346 !important;
          // width: 130px;
        }
      }

      .value {
        width: 130px;
        &.auto-width {
          width: auto;
        }
      }
    }
  }
}
</style>
