<template>
  <div class="dynamic-input-wrapper-has-regroupement">
    <!-- <label v-show="input.label" :for="input.name">{{ input.label }}</label> -->

    <!-- error span -->
    <!-- <div v-show="error" class="error">
      <ul v-if="error instanceof Array">
        <li v-for="(e, index) in error" :key="'e-' + index">{{ e }}</li>
      </ul>
      <span v-else>{{ error }}</span>
    </div> -->

    <!-- start input type if -->
    <div v-if="input.hasRegroupementSelect == 1">
      <div
        class="content-amo-agence"
        :class="{ vt: hasRegroupementSelectkey_1.vt }"
      >
        <!-- set(multiselect) -->
        <div
          class="input"
          :class="{
            'input-vt': hasRegroupementSelectkey_1.vt
          }"
          v-if="input.parent == 0"
        >
          <multiselect
            class="set"
            v-model="valueOptions"
            :value="valueOptions"
            @input="handleMultiselectSetAgenceAmo($event, 'filiale')"
            :options="[{ id: null, label: '' }, ...options] || []"
            trackBy="id"
            label="label"
            :showLabels="false"
            :closeOnSelect="true"
            :allow-empty="true"
            :multiple="selectedOptions"
            :placeholder="input.placeholder || null"
            :limit="2"
            :limit-text="count => `et ${count} de plus`"
            :loading="getRouteDynamicProcessing"
          ></multiselect>
        </div>
        <!-- set(multiselect) -->
        <div
          class="input-0-lt"
          :class="{
            'input-0-lt':
              hasRegroupementSelectkey_1.classlogistique &&
              input.logistiqueTravaux,
            'input-0-vt': hasRegroupementSelectkey_1.vt
          }"
          v-if="input.parent == 0"
        >
          <multiselect
            class="set"
            v-model="valueOptionsAgence"
            :value="valueOptionsAgence"
            @input="handleMultiselectSetAgenceAmo($event, 'agence')"
            :options="
              [{ id: null, label: '' }, ...optionsRegroupementSelectAgence] ||
                []
            "
            trackBy="id"
            label="label"
            :showLabels="false"
            :allow-empty="true"
            :closeOnSelect="true"
            :multiple="selectedOptions"
            :placeholder="input.placeholder || null"
            :limit="2"
            :limit-text="count => `et ${count} de plus`"
            :loading="getRouteDynamicProcessing"
            :disabled="
              (valueOptions && valueOptions.id == null) ||
                !valueOptions ||
                !optionsRegroupementSelectAgence ||
                !optionsRegroupementSelectAgence.length
            "
          ></multiselect>
        </div>
        <!-- set(multiselect) -->
        <div
          class="input-1-lt"
          :class="{
            'input-1-lt':
              hasRegroupementSelectkey_1.classlogistique &&
              input.logistiqueTravaux,
            'input-1-vt': hasRegroupementSelectkey_1.vt
          }"
          v-if="input.parent == 0"
        >
          <multiselect
            class="set"
            v-model="valueOptionsAmo"
            :value="valueOptionsAmo"
            @input="handleMultiselectSetAgenceAmo($event, 'amo')"
            :options="
              [{ id: null, label: '' }, ...optionsRegroupementSelectAmo] || []
            "
            trackBy="id"
            label="label"
            :showLabels="false"
            :closeOnSelect="true"
            :allow-empty="true"
            :multiple="selectedOptions"
            :placeholder="input.placeholder || null"
            :limit="2"
            :limit-text="count => `et ${count} de plus`"
            :loading="getRouteDynamicProcessing"
            :disabled="
              (valueOptions && valueOptions.id == null) ||
                !valueOptions ||
                !valueOptionsAgence ||
                !optionsRegroupementSelectAmo ||
                !optionsRegroupementSelectAmo.length
            "
          ></multiselect>
        </div>
      </div>
    </div>
    <div v-else>
      <span>not supported</span>
    </div>
    <div v-if="!input.message">Aucun option</div>
    <!-- end input type if -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import multiselect from 'vue-multiselect'
import * as moment from 'moment'
moment.locale('fr')
/**
 * @Module Dynamic Input - all supported inputs
 * @param {Object} input - an input configuration
 * @param {String} input.type(Required) - The type of input supported inputtypes(text,email,password,hidden,tel,date,number,select,textarea)
 * @param {String} input.name(Required) - The name of the input field
 * @param {any} input.value(Optional) - The value of the input field
 * @param {String} input.label(Optional) - The label of the input field
 * @param {String} input.class(Optional) - The Css class(es) of the input field
 *
 * **Input specific parameters**
 * @param input.min - minimum value of the input field(date,number)
 * @param input.max - maximum value of the input field(date,number)
 * @param {Number} input.step - increments of the value on increase & decrease(number)
 * @param {Array} options - al the options in a selectbox
 */
export default {
  name: 'dynamic-input',
  components: {
    multiselect
  },
  props: {
    error: {
      required: false
    },
    input: {
      type: Object,
      required: true
    },
    value: {
      required: false
    },
    doubleValue: { required: false },
    formatDate: {
      required: false
    },
    classInput: {
      required: false
    },
    objectProject: { required: false },
    indexInput: { required: false },
    message: { required: false },
    sousCategory: { required: false },
    logistiqueTravaux: { required: false },
    typeIsolation: { required: false },
    parent: { required: false },
    categorie: { required: false }
  },
  data() {
    return {
      options: [],
      selectedOptions: false,
      valueOptionsAgence: null,
      valueOptions: null,
      valueOptionsAmo: null,
      hasRegroupementSelectkey_1: {},
      hasRegroupementSelectkey_2: {},
      hasRegroupementSelectkey_3: {},
      optionsRegroupementSelectAmo: [],
      optionsRegroupementSelectAgence: []
    }
  },
  methods: {
    ...mapActions(['fetchProjectRouteDynamic']),
    async handleMultiselectSetAgenceAmo(data, name) {
      // intialisser les champs
      if (data == null && name == 'filiale') {
        this.valueOptionsAmo = null
        this.valueOptionsAgence = null
        this.valueOptions = null
      } else if (data == null && name == 'agence') {
        this.valueOptionsAmo = null
      }
      //
      if (name == 'amo') {
        this.valueOptionsAmo = data
      } else if (name == 'agence' && data) {
        this.valueOptionsAgence = data
        this.valueOptionsAmo = null
        this.optionsRegroupementSelectAmo = []
        if (
          data &&
          data.id &&
          this.hasRegroupementSelectkey_3 &&
          this.hasRegroupementSelectkey_3.link &&
          this.hasRegroupementSelectkey_3.link.length
        ) {
          for (
            let index = 0;
            index < this.hasRegroupementSelectkey_3.link.length;
            index++
          ) {
            let route = this.hasRegroupementSelectkey_3.link[
              index
            ].value.replace('{depot}', data.id)

            await this.fetchProjectRouteDynamic(route)
          }
          this.optionsRegroupementSelectAmo = []
          if (
            this.getProjectRouteDynamic &&
            this.getProjectRouteDynamic.length
          ) {
            this.optionsRegroupementSelectAmo = this.getProjectRouteDynamic
              .map(t => {
                if (t.full_name) {
                  return { label: t.full_name, id: t.id }
                } else {
                  return { label: t.name, id: t.id }
                }
              })
              .sort(function compare(a, b) {
                if (a.label < b.label) return -1
                if (a.label > b.label) return 1
                return 0
              })
          }
        }
      } else if (name == 'filiale' && data) {
        this.valueOptions = data
        this.valueOptionsAmo = null
        this.valueOptionsAgence = null
        this.optionsRegroupementSelectAmo = []
        this.optionsRegroupementSelectAgence = []
        if (
          data &&
          data.id &&
          this.hasRegroupementSelectkey_2 &&
          this.hasRegroupementSelectkey_2.link &&
          this.hasRegroupementSelectkey_2.link.length
        ) {
          for (
            let index = 0;
            index < this.hasRegroupementSelectkey_2.link.length;
            index++
          ) {
            let route = this.hasRegroupementSelectkey_2.link[
              index
            ].value.replace('{team}', data.id)

            await this.fetchProjectRouteDynamic(route)
          }
          this.optionsRegroupementSelectAgence = []
          if (
            this.getProjectRouteDynamic &&
            this.getProjectRouteDynamic.length
          ) {
            this.optionsRegroupementSelectAgence = this.getProjectRouteDynamic
              .map(t => {
                if (t.full_name) {
                  return { label: t.full_name, id: t.id }
                } else {
                  return { label: t.name, id: t.id }
                }
              })
              .sort(function compare(a, b) {
                if (a.label < b.label) return -1
                if (a.label > b.label) return 1
                return 0
              })
          }
        }
      }
      let payload = {
        // filiale
        value_1:
          this.valueOptions && this.valueOptions.id
            ? this.valueOptions.id
            : this.valueOptions,
        key_1:
          this.hasRegroupementSelectkey_1 && this.hasRegroupementSelectkey_1.key
            ? this.hasRegroupementSelectkey_1.key
            : this.hasRegroupementSelectkey_1,
        //  agence
        value_2:
          this.valueOptionsAgence && this.valueOptionsAgence.id
            ? this.valueOptionsAgence.id
            : this.valueOptionsAgence,
        key_2:
          this.hasRegroupementSelectkey_2 && this.hasRegroupementSelectkey_2.key
            ? this.hasRegroupementSelectkey_2.key
            : this.hasRegroupementSelectkey_2,
        // amo
        value_3:
          this.valueOptionsAmo && this.valueOptionsAmo.id
            ? this.valueOptionsAmo.id
            : this.valueOptionsAmo,
        key_3:
          this.hasRegroupementSelectkey_3 && this.hasRegroupementSelectkey_3.key
            ? this.hasRegroupementSelectkey_3.key
            : this.hasRegroupementSelectkey_3
      }
      this.$emit('save', payload, this.valueOptions, this.valueOptionsAmo)
    }
  },
  computed: {
    ...mapGetters(['getProjectRouteDynamic', 'getRouteDynamicProcessing'])
  },
  filters: {},
  async mounted() {
    if (this.input.hasRegroupementSelect == 1) {
      let tabHasRegroupement = []
      let tabFields = []
      for (
        let index = 0;
        index < this.input.sousCategory.fields.length;
        index++
      ) {
        if (this.input.sousCategory.fields[index].has_regroupement == 1) {
          tabFields.push(this.input.sousCategory.fields[index])
        }
      }
      if (tabFields && tabFields.length) {
        for (let index_1 = 0; index_1 < tabFields.length; index_1++) {
          if (
            tabFields[index_1].logistique_travaux ==
              this.input.logistiqueTravaux &&
            tabFields[index_1].type_isolation == null
          ) {
            tabHasRegroupement.push(tabFields[index_1])
          } else if (
            tabFields[index_1].logistique_travaux ==
              this.input.logistiqueTravaux &&
            tabFields[index_1].type_isolation == this.input.typeIsolation
          ) {
            tabHasRegroupement.push(tabFields[index_1])
          }
        }
      }
      // Appele route dynamique + tabHasRegroupement contient 2 object
      if (tabHasRegroupement && tabHasRegroupement.length) {
        if (
          tabHasRegroupement[0].links &&
          tabHasRegroupement[0].links.length &&
          this.input.parent == 0
        ) {
          if (
            tabHasRegroupement[0].key == 'team_comm_ite_id' ||
            tabHasRegroupement[0].key == 'team_visiteur_tech_id' ||
            tabHasRegroupement[0].key == 'team_tech_id'
          ) {
            for (
              let index = 0;
              index < tabHasRegroupement[0].links.length;
              index++
            ) {
              let route = `${tabHasRegroupement[0].links[index].value}?subcategory_id=${this.input.sousCategory.id}`
              await this.fetchProjectRouteDynamic(route)
            }
          } else {
            for (
              let index = 0;
              index < tabHasRegroupement[0].links.length;
              index++
            ) {
              await this.fetchProjectRouteDynamic(
                tabHasRegroupement[0].links[index].value
              )
            }
          }
          if (
            this.getProjectRouteDynamic &&
            this.getProjectRouteDynamic.length
          ) {
            this.options = this.getProjectRouteDynamic
              .map(t => {
                if (t.full_name) {
                  return { label: t.full_name, id: t.id }
                } else {
                  return { label: t.name, id: t.id }
                }
              })
              .sort(function compare(a, b) {
                if (a.label < b.label) return -1
                if (a.label > b.label) return 1
                return 0
              })
            if (this.value) {
              this.valueOptions = this.options.find(e => {
                return e.id == this.value.id
              })
            }
          }
        }
        if (
          tabHasRegroupement[0].value &&
          tabHasRegroupement[0].value.id &&
          this.input.parent == 0
        ) {
          if (
            tabHasRegroupement[1].links &&
            tabHasRegroupement[1].links.length
          ) {
            for (
              let index = 0;
              index < tabHasRegroupement[1].links.length;
              index++
            ) {
              let route = tabHasRegroupement[1].links[index].value.replace(
                '{team}',
                tabHasRegroupement[0].value && tabHasRegroupement[0].value.id
                  ? tabHasRegroupement[0].value.id
                  : tabHasRegroupement[0].value
              )

              await this.fetchProjectRouteDynamic(route)
            }
          }
          this.optionsRegroupementSelectAgence = []
          if (
            this.getProjectRouteDynamic &&
            this.getProjectRouteDynamic.length
          ) {
            this.optionsRegroupementSelectAgence = this.getProjectRouteDynamic
              .map(t => {
                if (t.full_name) {
                  return { label: t.full_name, id: t.id }
                } else {
                  return { label: t.name, id: t.id }
                }
              })
              .sort(function compare(a, b) {
                if (a.label < b.label) return -1
                if (a.label > b.label) return 1
                return 0
              })
          }
        }
        if (
          tabHasRegroupement[1].value &&
          tabHasRegroupement[1].value.id &&
          this.input.parent == 0
        ) {
          if (
            tabHasRegroupement[2].links &&
            tabHasRegroupement[2].links.length
          ) {
            for (
              let index = 0;
              index < tabHasRegroupement[2].links.length;
              index++
            ) {
              let route = tabHasRegroupement[2].links[index].value.replace(
                '{depot}',
                tabHasRegroupement[1].value && tabHasRegroupement[1].value.id
                  ? tabHasRegroupement[1].value.id
                  : tabHasRegroupement[1].value
              )

              await this.fetchProjectRouteDynamic(route)
            }
          }
          this.optionsRegroupementSelectAmo = []
          if (
            this.getProjectRouteDynamic &&
            this.getProjectRouteDynamic.length
          ) {
            this.optionsRegroupementSelectAmo = this.getProjectRouteDynamic
              .map(t => {
                if (t.full_name) {
                  return { label: t.full_name, id: t.id }
                } else {
                  return { label: t.name, id: t.id }
                }
              })
              .sort(function compare(a, b) {
                if (a.label < b.label) return -1
                if (a.label > b.label) return 1
                return 0
              })
          }
        }
        //  Value select one
        this.valueOptions = {
          label:
            tabHasRegroupement[0].value && tabHasRegroupement[0].value.name
              ? tabHasRegroupement[0].value.name
              : tabHasRegroupement[0].value,
          id:
            tabHasRegroupement[0].value && tabHasRegroupement[0].value.id
              ? tabHasRegroupement[0].value.id
              : tabHasRegroupement[0].value
        }
        //  Value select Two
        this.valueOptionsAgence = {
          label:
            tabHasRegroupement[1].value && tabHasRegroupement[1].value.name
              ? tabHasRegroupement[1].value.name
              : tabHasRegroupement[1].value,
          id:
            tabHasRegroupement[1].value && tabHasRegroupement[1].value.id
              ? tabHasRegroupement[1].value.id
              : tabHasRegroupement[1].value
        }
        //  Value select three
        this.valueOptionsAmo = {
          label:
            tabHasRegroupement[2].value && tabHasRegroupement[2].value.name
              ? tabHasRegroupement[2].value.name
              : tabHasRegroupement[2].value,
          id:
            tabHasRegroupement[2].value && tabHasRegroupement[2].value.id
              ? tabHasRegroupement[2].value.id
              : tabHasRegroupement[2].value
        }
        this.hasRegroupementSelectkey_1 = {
          name: tabHasRegroupement[0].name ? tabHasRegroupement[0].name : '',
          key: tabHasRegroupement[0].key,
          link: tabHasRegroupement[0].links,
          vt: tabHasRegroupement[0].vt
        }
        this.hasRegroupementSelectkey_2 = {
          name: tabHasRegroupement[1].name ? tabHasRegroupement[1].name : '',
          key: tabHasRegroupement[1].key,
          link: tabHasRegroupement[1].links,
          vt: tabHasRegroupement[1].vt
        }
        this.hasRegroupementSelectkey_3 = {
          name: tabHasRegroupement[2].name ? tabHasRegroupement[2].name : '',
          key: tabHasRegroupement[2].key,
          link: tabHasRegroupement[2].links,
          vt: tabHasRegroupement[2].vt
        }
      }
    }
    if (this.$refs.autofocus) {
      this.$refs.autofocus.focus()
    }
  }
}
</script>

<style lang="scss">
.dynamic-input-wrapper-has-regroupement {
  .input-list {
    border: 1px solid #e5e5e5;
    // width: 100%;
  }
  .list-element {
    display: flex;
    align-items: center;
    .value-input {
      flex-shrink: 2;
      box-shadow: unset;
      border: 1px solid #e5e5e5;
      height: 26px;
      text-overflow: ellipsis;
      width: 105px;
    }
    .value-type {
      flex-shrink: 3;
      box-shadow: unset;
      border: 0px solid #e5e5e5;
      width: 130px;
      font-size: 16px;
    }
    .action {
      cursor: pointer;
    }
  }
  .add-to-list-action {
    color: #2dabe2;
    cursor: pointer;
    width: max-content;
    font-weight: bold;
    margin-left: 18px;
    .icon-plus {
      font-size: 10px;
    }
    &.disabled {
      color: #ced4da;
      cursor: default;
    }
  }
  .multiselect {
    width: 186px;
    height: 26px;
    min-height: 26px;

    &.set {
      height: 25px;
    }

    .multiselect__select {
      height: 26px;
    }
    .multiselect__tags {
      min-height: 26px;
      padding: 2px 40px 0 8px;
      border: 1px solid #e5e5e5;
      border-radius: 2px;
      font-size: 12px;
      .multiselect__tags-wrap {
        .multiselect__tag {
          padding: 5px 20px 5px 10px;
          border-radius: 3px;
          margin-right: 4px;
          margin-bottom: 0;
          line-height: 12px;
        }
        .multiselect__strong {
          margin-bottom: 0px;
          line-height: 12px;
        }
      }

      .multiselect__placeholder,
      .multiselect__single {
        margin-bottom: 0;
        padding-top: 0;
        font-size: 12px;
      }
      .multiselect__input,
      .multiselect__single {
        max-height: 25px;
        background: transparent;
        width: 212%;
      }
    }
    .multiselect__spinner {
      position: absolute;
      right: 1px;
      top: 1px;
      width: 48px;
      height: 22px;
      background: #fff;
      display: block;
    }
    .multiselect__content-wrapper {
      bottom: unset;
      position: relative;
      .multiselect__content {
        .multiselect__element {
          .multiselect__option {
            padding: 6px 12px;
            min-height: 30px;
          }
        }
      }
    }
  }
  .radio-style-input {
    .custom-checkbox .custom-control-label::before {
      border-radius: 1.25rem;
    }
  }
  .radio-oui-non {
    .custom-control-label {
      position: relative;
      margin-bottom: 0;
      vertical-align: top;
      padding-top: 4px;
    }
  }
  .input-comments-content {
    .anciene-comment::-webkit-scrollbar {
      height: 7px;
      width: 7px;
    }
    .anciene-comment::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #80808054;
      border-radius: 10px;
    }
    .anciene-comment::-webkit-scrollbar-thumb {
      background: #dcdee0;
      border-radius: 10px;
    }
    .anciene-comment::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
    .anciene-comment {
      white-space: normal;
      max-height: 116px;
      overflow-y: auto;
      overflow-x: hidden;
      width: 156%;
      margin-right: 5px;
      .content-created-note {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .user {
          font-weight: bold;
          font-size: 11px;
        }
        .icon-delete-note {
          font-size: 15px;
          color: #ef6f64;
          margin-right: 3px;
          &:hover {
            color: #f22d1d;
          }
        }
      }
      .date {
        color: #9e9e9e;
        font-weight: bold;
        font-size: 9px;
      }
    }
    .line-note {
      margin-top: 6px;
      margin-bottom: 4px;
      width: 97%;
    }
  }
  .select-search {
    height: auto;
    // width: 130%;
    width: 100%;
    // margin-left: -2px;
    font-size: 11px;
    // width: 97px;
    // height: 26px;
    // border-radius: 0px;
    // min-height: 26px;
    .multiselect__select {
      height: 20px;
      right: -7px;
    }
    .multiselect__content {
      max-height: 82px;
      list-style: none;
      display: inline-block;
      padding: 0;
      margin: 0;
      min-width: 100%;
      vertical-align: top;
    }
  }
  .select-search-date-select {
    height: auto;
    width: 100%;
    font-size: 11px;
    .multiselect__select {
      height: 20px;
      right: -7px;
    }
    .multiselect__content-wrapper {
      .multiselect__content {
        .multiselect__element {
          .multiselect__option {
            padding: 6px 12px;
            min-height: 25px;
          }
        }
      }
    }
    .multiselect__content {
      max-height: 46px;
      list-style: none;
      display: inline-block;
      padding: 0;
      margin: 0;
      min-width: 100%;
      vertical-align: top;
      .multiselect__option {
        display: block;
        padding: 12px;
        min-height: 40px;
        line-height: 12px;
        text-decoration: none;
        text-transform: none;
        position: relative;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
  .select-search-client {
    height: auto;
    width: 111.36px;
    font-size: 11px;
  }
  .select-search-tootltip {
    height: auto;
    width: 100%;
    font-size: 11px;
  }
  .select-search-client-border {
    height: auto;
    width: 98%;
    font-size: 11px;
  }
  .select-date {
    width: 100%;
  }
  .double-select-date {
    .not-style {
      width: 0;
      margin-left: 0;
      margin-right: 0;
    }
    &.mx-datepicker {
      width: 107px;
      .mx-input-wrapper {
        .mx-input {
          width: 111px;
          height: 26px;
          border: 1px solid #e5e5e5;
          border-radius: 2px;
          padding: 6px 28px;
          padding-left: 10px;
        }
        .mx-icon-calendar {
          position: absolute;
          top: 50%;
          right: 3px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 12px;
          line-height: 1;
          color: rgba(0, 0, 0, 0.5);
          vertical-align: middle;
        }
        .mx-icon-clear {
          position: absolute;
          top: 50%;
          right: 3px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 16px;
          line-height: 1;
          color: rgba(0, 0, 0, 0.5);
          vertical-align: middle;
        }
      }
    }
  }
  .mx-datepicker {
    width: 136px;
    .mx-input-wrapper {
      .mx-input {
        width: 166px;
        height: 26px;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        padding: 6px 28px;
        padding-left: 10px;
      }
    }
    &.date-picker-range {
      .mx-input-wrapper {
        .mx-input {
          font-size: 11px;
        }
      }
    }
    &.date-input {
      .mx-input-wrapper {
        .mx-input {
          width: 100%;
          /* height: 26px; */
          border: 1px solid #e5e5e5;
          border-radius: 0px;
          font-size: 10px;
          // margin-right: 115px;
          /* padding: 0px; */
          /* padding-left: 0px;*/
        }
        .mx-icon-calendar {
          position: absolute;
          top: 50%;
          right: 7px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 12px;
          line-height: 1;
          color: rgba(0, 0, 0, 0.5);
          vertical-align: middle;
        }
        .mx-icon-clear {
          position: absolute;
          top: 50%;
          right: 7px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 16px;
          line-height: 1;
          color: rgba(0, 0, 0, 0.5);
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.dynamic-input-wrapper-has-regroupement {
  width: 100%;
  .content-amo-agence {
    width: 100%;
    height: 22px;
    &.vt {
      height: 28px;
    }
    .input {
      margin-bottom: 6px;
      &.input-vt {
        margin-bottom: 27px;
      }
    }
    .input-0-lt {
      margin-bottom: 6px;
      &.input-0-vt {
        margin-bottom: 26px;
      }
    }
  }
  .set {
    height: 25px;
  }
  .error {
    float: right;
  }
  .text-input {
    width: 100%;
    height: 25px;
  }
  .text-input-double {
    // width: 50%;
    height: 25px;
  }
  .input-comments-content {
    margin-left: 0px;
    margin-top: 2px;
    textarea {
      line-height: 1rem;
      font-size: 0.75rem;
      margin-left: -3px;
      margin-top: 3px;
      width: 158%;
      max-width: 260px;
      resize: vertical;
      border: 1px solid #e5e5e5;
      outline: unset;
      margin-bottom: 5px;
      height: 55px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  // .input-double-1 {
  //   margin-left: -2px;
  // }
  // .input-double-2 {
  //   margin-left: 5px;
  // }
  .tooltip-input {
    border: 1px solid #e5e5e5;
    width: 100%;
    height: 25px;
    margin-left: 6px;
  }
  .text-input-popUp {
    border: 1px solid #e5e5e5;
    // margin-left: -1px;
    width: 100%;
    text-align: center;
    height: 25px;
  }
  .text-input-popUp-icon {
    border: 1px solid #e5e5e5;
    margin-right: 40px;
    width: 100%;
    text-align: center;
    height: 25px;
  }
  .text-input-total-ht {
    width: 140%;
    height: 25px;
    margin-left: -10px;
  }

  textarea {
    width: 166px;
    max-width: 166px;
    resize: vertical;
    border: 1px solid #e5e5e5;
    outline: unset;
  }
  input {
    border: 1px solid #e5e5e5;
    width: 112%;
  }
  .subventions {
    .selectSubventions {
      width: 100%;
      height: 27px;
      border: 1px solid #e5e5e5;
      outline: unset;
    }
  }
}
</style>
