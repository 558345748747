<template>
  <div>
    <div v-if="getCategorieLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <b-tabs
      v-else-if="computedCategoriesPj && computedCategoriesPj.length"
      class="files-sub-tabs"
      lazy
    >
      <b-tab
        no-body
        :title-link-class="linkClass(categorie)"
        v-for="(categorie, index) in computedCategoriesPj"
        :key="'categorie' + index"
        @click="clickCateg(categorie)"
      >
        <template #title>
          <div
            v-tooltip.top-center="
              ` Fichiers : ${
                project.files && project.files.length ? project.files.length : 0
              }`
            "
          >
            <span
              v-if="
                categorie.name != 'Voir plus...' && categorie.name == 'Tous'
              "
            >
              {{ categorie.name }}</span
            >
            <span class="counter" v-if="categorie.name == 'Tous'">
              {{
                project.files && project.files.length ? project.files.length : 0
              }}
            </span>
          </div>
          <div
            v-tooltip.top-center="
              ` Fichiers : ${categorie.count_files ? categorie.count_files : 0}`
            "
          >
            <span
              v-if="
                categorie.name != 'Voir plus...' && categorie.name != 'Tous'
              "
            >
              {{ categorie.name }}</span
            >
            <span
              class="counter"
              v-if="
                categorie.name != 'Tous' && categorie.name != 'Voir plus...'
              "
            >
              {{ categorie.count_files ? categorie.count_files : 0 }}
            </span>
          </div>
          <div v-if="categorie.name == 'Voir plus...'" @click="addCategorie">
            Voir plus...
          </div>
        </template>
        <template>
          <div
            class="group-file"
            v-if="
              (categorie.files && categorie.files.length) ||
                (categorie.name == 'Tous' &&
                  project.files &&
                  project.files.length)
            "
          >
            <b-form-checkbox
              class="file-tous-upload"
              v-model="allSelected"
              :indeterminate="indeterminate"
              @change="toggleAll($event, categorie)"
            >
              {{
                allSelected
                  ? 'Selectionner tous les fichiers'
                  : 'Aucun fichier selectionné'
              }}
            </b-form-checkbox>
            <div>
              <b-button
                v-if="allSelected"
                class="btn-upload"
                @click="uploadFile"
                title="Télécharger"
                variant="success"
              >
                <b-spinner
                  class="style-spinner"
                  v-if="getProjectProcessingFiles"
                  :style="{
                    opacity: getProjectProcessingFiles ? 1 : 0
                  }"
                  label="Loading..."
                ></b-spinner>
                <font-awesome-icon v-else class="" icon="download" />
              </b-button>
              <b-button
                v-if="allSelected"
                class="btn-delete"
                variant="danger"
                @click="showDeleteFile"
                title="Supprimer"
              >
                <b-spinner
                  class="style-spinner"
                  v-if="getProjectsProcessing"
                  :style="{
                    opacity: getProjectsProcessing ? 1 : 0
                  }"
                  label="Loading..."
                ></b-spinner>
                <font-awesome-icon v-else class="" icon="trash" />
              </b-button>
            </div>
          </div>
          <div v-if="categorie.name == 'Tous'">
            <ProjectFile
              v-for="file in project.files"
              :key="'project-file-1-' + file.id"
              :entity="file"
              :project="project"
              :preview="true"
              :readonly="readonly"
              :categorie="categorie"
              :gestionFile="gestionFile"
              :checkedTous="selectedcheckBox"
              @checkedBox="checkedBox"
            />
          </div>
          <div>
            <ProjectFile
              v-for="(file, index) in categorie.files"
              :key="'project-file-2-' + index"
              :entity="file"
              :project="project"
              :preview="true"
              :readonly="readonly"
              :categorie="categorie"
              :gestionFile="gestionFile"
              :checkedTous="selectedcheckBox"
              @checkedBox="checkedBox"
            />
          </div>
        </template>
      </b-tab>
    </b-tabs>
    <div
      class="aucun-subCategory"
      v-if="
        (!computedCategoriesPj || !computedCategoriesPj.length) &&
          !getCategorieLoading
      "
    >
      Aucun sous catégorie pièce jointe
    </div>
    <b-modal
      id="delete-file"
      no-close-on-backdrop
      ref="delete-file"
      :hide-footer="true"
      title="Supprimer le(s) fichier(s)"
    >
      <div class="text">
        Êtes-vous sûr de vouloir supprimer ce(s) fichier(s) ?
      </div>
      <div class="form-actions">
        <b-button variant="outline-secondary" @click="hideModal('delete-file')">
          Annuler
        </b-button>
        <b-button variant="danger" @click="deletedFile">
          Supprimer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ProjectFile from '@/components/ui/ProjectFile'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FilesSubCategoryPJ',
  props: {
    project: {
      required: true
    },
    readonly: {
      default: false
    },
    gestionFile: { required: false }
  },
  components: {
    ProjectFile
  },
  data() {
    return {
      voirPlus: false,
      selectedcheckBox: null,
      allSelected: false,
      indeterminate: false,
      fileSelected: null
    }
  },
  methods: {
    ...mapActions(['downloadtousFiles', 'deletedTousFiles']),
    hideModal(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].hide()
      }
    },
    toggleAll(checked, categ) {
      this.fileSelected = null
      this.selectedcheckBox = checked == true ? 1 : 0
      // refresh checkbox das categorie
      if (!checked && categ && categ.files && categ.files.length) {
        for (let index = 0; index < categ.files.length; index++) {
          categ.files[index].checkUpload = 0
        }
      }
      if (
        !checked &&
        categ &&
        categ.name &&
        categ.name == 'Tous' &&
        this.project &&
        this.project.files &&
        this.project.files.length
      ) {
        for (let index = 0; index < this.project.files.length; index++) {
          this.project.files[index].checkUpload = 0
        }
      }
      // selectionner tous fichiers
      if (checked) {
        if (categ.name != 'Tous') {
          this.fileSelected = categ.files
        } else {
          this.fileSelected = this.project.files
        }
      }
    },
    uploadFile() {
      let files_ids = this.fileSelected.map(item => item.id)
      if (this.fileSelected && this.fileSelected.length) {
        this.downloadtousFiles({
          fichiers: files_ids,
          files: this.fileSelected
        })
      }
    },
    showDeleteFile() {
      this.$refs['delete-file'].show()
    },
    async deletedFile() {
      let files_ids = this.fileSelected.map(item => item.id)
      if (this.fileSelected && this.fileSelected.length) {
        const response = await this.deletedTousFiles({
          files: files_ids,
          project: this.project,
          file: this.fileSelected
        })
        if (response) {
          this.hideModal('delete-file')
          this.selectedcheckBox = null
          this.allSelected = false
          this.indeterminate = false
          this.fileSelected = null
        }
      }
    },
    checkedBox(newValue, categ) {
      this.fileSelected = newValue
      //  categ tous
      if (categ.name == 'Tous') {
        if (newValue.length === 0) {
          this.indeterminate = false
          this.allSelected = false
        } else if (newValue.length === this.project.files.length) {
          this.indeterminate = false
          this.allSelected = true
        } else if (newValue && newValue.length) {
          this.indeterminate = true
          this.allSelected = true
        } else {
          this.indeterminate = true
          this.allSelected = false
        }
      } else {
        if (newValue.length === 0) {
          this.indeterminate = false
          this.allSelected = false
        } else if (newValue.length === categ.files.length) {
          this.indeterminate = false
          this.allSelected = true
        } else if (newValue && newValue.length) {
          this.indeterminate = true
          this.allSelected = true
        } else {
          this.indeterminate = true
          this.allSelected = false
        }
      }
    },
    addCategorie() {
      this.voirPlus = true
    },
    linkClass(catg) {
      if (
        catg.name != 'Voir plus...' &&
        catg.name != 'Tous' &&
        catg.count_files
      ) {
        return ['files-tab-title-green']
      } else {
        if (
          catg.name == 'Tous' &&
          this.project &&
          this.project.files &&
          this.project.files.length
        ) {
          return ['files-tab-title-tous']
        } else {
          return ['files-tab-title']
        }
      }
    },
    clickCateg(categ) {
      this.selectedcheckBox = null
      this.allSelected = false
      this.indeterminate = false
      this.fileSelected = null
      // refresh checkbox des catego Tous
      for (let index = 0; index < this.project.files.length; index++) {
        this.project.files[index].checkUpload = false
      }
      // refresh checkbox des categ
      if (categ && categ.files && categ.files.length) {
        for (let index = 0; index < categ.files.length; index++) {
          categ.files[index].checkUpload = 0
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsCategoriesFichiersParCount',
      'getCategorieLoading',
      'getProjectProcessingFiles',
      'getCategoriesPieceJointeFile',
      'getProjectsProcessing'
    ]),
    computedCategoriesPj: function() {
      let table =
        this.getProjectsCategoriesFichiersParCount &&
        this.getProjectsCategoriesFichiersParCount.length
          ? this.getProjectsCategoriesFichiersParCount
          : []
      let categorieListe = [
        { name: 'Tous' },
        ...table,
        { name: 'Voir plus...' }
      ]
      if (
        this.getProjectsCategoriesFichiersParCount &&
        this.getProjectsCategoriesFichiersParCount.length &&
        this.getProjectsCategoriesFichiersParCount.length < 5
      ) {
        categorieListe = [{ name: 'Tous' }, ...table]
      }
      if (
        (this.getProjectsCategoriesFichiersParCount &&
          this.getProjectsCategoriesFichiersParCount.length == 0) ||
        (this.getProjectsCategoriesFichiersParCount &&
          this.getProjectsCategoriesFichiersParCount.length == 0)
      ) {
        categorieListe = []
      }
      return categorieListe.filter((catg, key) => {
        if (!this.voirPlus) {
          if (key < 5) {
            return catg
          }
          if (categorieListe && key == categorieListe.length - 1) {
            return catg
          }
        } else {
          return catg.name != 'Voir plus...'
        }
      })
    }
  }
}
</script>

<style lang="scss">
.files-sub-tabs {
  .nav-tabs {
    justify-content: center;
    background-color: #f3f3f3;
    border-radius: 5px;
    border: none;
    padding: 6px;
    .files-tab-title {
      position: relative;
      font-size: 10px;
      font-weight: bold;
      color: #404346;
      background-color: #e5e5e5;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 4px;
      cursor: pointer;
      user-select: none;
      transition: all 0.4s ease;

      .counter {
        margin-left: 5px;
        padding: 1px 4px 2px 2px;
        background-color: #5e5e5e82;
        border-radius: 9px;
        font-weight: bold;
        border: 1px solid;
        color: white;
        font-size: 10px;
      }

      &.active {
        color: #5e5e5e;
        background-color: #fff;
        .counter {
          margin-left: 5px;
          padding: 1px 4px 2px 2px;
          background-color: #5e5e5e82;
          border-radius: 9px;
          font-weight: bold;
          border: 1px solid;
          color: white;
          font-size: 10px;
        }
      }
    }
    .files-tab-title-tous {
      position: relative;
      font-size: 10px;
      font-weight: bold;
      color: #404346;
      background-color: #e5e5e5;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 4px;
      cursor: pointer;
      user-select: none;
      transition: all 0.4s ease;
      .counter {
        padding: 1px 4px 2px 2px;
        background-color: #8bc34a;
        border-radius: 9px;
        font-weight: bold;
        border: 1px solid;
        color: #fff;
        font-size: 10px;
        margin-left: 5px;
        margin-left: 5px;
      }

      &.active {
        color: #5e5e5e;
        background-color: #fff;
        border: 1px solid #8bc34a;
        .counter {
          padding: 1px 4px 2px 2px;
          background-color: #8bc34a;
          border-radius: 9px;
          font-weight: bold;
          border: 1px solid;
          color: #fff;
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }
    .files-tab-title-green {
      position: relative;
      font-size: 10px;
      font-weight: bold;
      color: #ffffff;
      background-color: #8bc34a;
      border: 1px solid #8bc34a;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 4px;
      cursor: pointer;
      user-select: none;
      transition: all 0.4s ease;

      .counter {
        padding: 1px 4px 2px 2px;
        background-color: #f8f9fa;
        border-radius: 9px;
        font-weight: bold;
        border: 1px solid;
        color: #8bc34a;
        font-size: 10px;
        margin-left: 5px;
      }

      &.active {
        color: #5e5e5e;
        background-color: #fff;
        .counter {
          padding: 1px 4px 2px 2px;
          background-color: #8bc34a;
          border-radius: 9px;
          font-weight: bold;
          border: 1px solid;
          color: #fff;
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }
    .files-tab-title-categ {
      position: relative;
      font-size: 12px;
      color: #2dabe2;
      background-color: #f3f3f3;
      border: 1px solid #f3f3f3;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 4px;
      cursor: pointer;
      user-select: none;
      transition: all 0.4s ease;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .group-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-upload,
    .btn-delete {
      padding: 3px 8px;
      margin: 3px 1px;
      font-size: 11px;
    }
    // .btn-secondary {
    //   color: #5e5e5e;
    //   background-color: #e5e5e5;
    //   border-color: #d8d8d8;
    //   box-shadow: unset;
    //   &:focus {
    //     color: #5e5e5e;
    //     background-color: #e5e5e5;
    //     border-color: #d8d8d8;
    //     box-shadow: unset;
    //   }
    //   &:hover {
    //     color: #f8f9fa;
    //     background-color: #656565;
    //     border-color: #e5e5e5;
    //     box-shadow: unset;
    //   }
    // }
  }
  .file-tous-upload {
    padding: 9px 6px 4px 24px;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #8bc34a;
    background-color: #8bc34a;
  }
  .style-spinner {
    height: 9px;
    width: 18px;
    padding: 10px 11px 2px 2px;
    color: white;
    &:hover {
      color: white;
    }
  }
}
.aucun-subCategory {
  color: #2dabe2;
  text-align: center;
}
#delete-file {
  .text {
    padding-bottom: 12px;
  }
}
</style>
