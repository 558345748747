var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editable-input"},[(_vm.field.editing == true)?_c('div',{staticClass:"edit-view"},[_c('dynamicInputHasRegroupement',{attrs:{"input":{
        type: _vm.field.input_type,
        options: _vm.field.options ? _vm.field.options.data : [],
        name: _vm.field.key,
        links: _vm.field.links ? _vm.field.links : [],
        selected: _vm.field.selection_type,
        valuePossible: _vm.field.possible_values
          ? _vm.field.possible_values.data
          : [],
        formatDate: _vm.field.format ? true : false,
        message: _vm.computedButtonValider,
        sousCategory: _vm.sousCategory,
        hasRegroupementSelect: _vm.field.has_regroupement,
        logistiqueTravaux: _vm.field.logistique_travaux,
        typeIsolation: _vm.field.type_isolation,
        parent: _vm.field.parent,
        tableParent:
          _vm.computedHasRegroupementSelect &&
          _vm.computedHasRegroupementSelect.length
            ? _vm.computedHasRegroupementSelect
            : [],
        project: _vm.entity
      }},on:{"save":function($event){return _vm.update($event, false)}},model:{value:(_vm.newValue),callback:function ($$v) {_vm.newValue=$$v},expression:"newValue"}}),(
        _vm.computedAffichageButtonsActions &&
          _vm.computedAffichageButtonsActions.key == _vm.field.key
      )?_c('div',{staticClass:"edit-action flex justify-content-end mt-1"},[_c('div',{on:{"click":_vm.close}},[_vm._v("Annuler")]),(_vm.computedButtonValider)?_c('div',{staticClass:"green ml-2",on:{"click":function($event){return _vm.update(null, true)}}},[_vm._v(" Enregistrer ")]):_vm._e()]):_vm._e()],1):_c('div',{staticClass:"show-view"},[(
        _vm.field.input_type === 'multiple_select' &&
          _vm.field.selection_type === 'multiple'
      )?_c('div',{staticClass:"value mr-2",class:{ 'auto-width': _vm.autoWidth }},[_vm._v(" "+_vm._s(Array.isArray(_vm.value) && _vm.value.length ? _vm.value.join(', ') : '-')+" ")]):_c('div',{staticClass:"value mr-2",class:{ 'auto-width': _vm.autoWidth }},[_vm._v(" "+_vm._s(_vm.value && !_vm.value.name ? _vm.value : _vm.value && _vm.value.name ? _vm.value.name : '-')+" ")]),(!_vm.readonly)?_c('div',{staticClass:"show-action",on:{"click":_vm.openInput}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"pencil-alt"}})],1):_vm._e(),(!_vm.readonly && _vm.field.pre_rempli_field)?_c('div',{staticClass:"show-action ml-1",on:{"click":_vm.envoiCurrentUser}},[_c('DoightLeveIcon')],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }