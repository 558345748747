<template>
  <div
    class="activity-note"
    :class="{ 'sticky-comment': sticky || categoryProjet }"
  >
    <div class="icon-visibility">
      <font-awesome-icon v-if="entity.public" icon="globe-europe" />
      <font-awesome-icon v-else icon="lock" />
    </div>
    <div class="icon-entity-note">
      <font-awesome-icon class="icon-entity-note-comment" icon="comment-alt" />
    </div>
    <div class="icon-pin-note" v-if="!categoryProjet">
      <font-awesome-icon class="icon-pin-note-comment" icon="thumbtack" />
    </div>

    <div class="page-content-note">
      <div class="description-bloc-comment">
        <div class="description-note">
          <div class="informations capitalize">
            <span>
              {{ entity.created_at | date('DD MMM') }}
              {{ entity.created_at | time('HH:mm') }}
            </span>
            -
            <span>
              {{ entity.created_by.full_name }}
            </span>
            <div
              v-if="
                (entity.category && entity.category.name) ||
                  entity.category_name
              "
            >
              <span>
                catégorie :
              </span>
              <span>{{
                entity.category && entity.category.name
                  ? entity.category.name
                  : entity.category_name
              }}</span>
            </div>
          </div>
          <b-dropdown
            v-if="!readonly && !categoryProjet"
            size="lg"
            variant="transparent"
            toggle-class="activity-dropdown-button"
            no-caret
            text="..."
          >
            <b-dropdown-item @click="handelUpdateNote">
              Modifier
            </b-dropdown-item>
            <b-dropdown-item @click="handelEpingleNote">
              {{
                entity.epingler
                  ? 'Désépingler cette note'
                  : 'Épingler cette note'
              }}
            </b-dropdown-item>
            <b-dropdown-item @click="handelVisibility">
              {{ entity.public ? 'Rendre privé' : 'Rendre public' }}
            </b-dropdown-item>

            <b-dropdown-item @click="handleDeleteClick">
              Supprimer
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="body-note">
          <span v-html="entity.body">{{ entity ? entity.body : '' }}</span>
        </div>
      </div>
    </div>
    <b-modal
      id="Supprimer-comment"
      no-close-on-backdrop
      ref="Supprimer-comment"
      :hide-footer="true"
      title="Supprimer commentaire"
    >
      <div class="msg-delete">
        Êtes-vous sûr de vouloir supprimer ce commentaire ?
      </div>
      <div class="form-actions">
        <b-button
          variant="outline-secondary"
          @click="hideModal('Supprimer-comment')"
        >
          Annuler
        </b-button>
        <b-button variant="danger" type="submit" @click="clickDeleteNote">
          Supprimer
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="Modifier-comment"
      no-close-on-backdrop
      ref="Modifier-comment"
      :hide-footer="true"
      title="Modifier un commentaire"
    >
      <b-form-group label="Vous pouvez modifier un commentaire ici :">
        <!-- <e-editor v-model="commentBody" /> -->
        <NoteMention v-model="commentBody" :modal="true" />
      </b-form-group>
      <b-form-group label="Catégories de projet">
        <multiselect
          v-model="selectedCategory"
          :options="getListCategorieProjet || []"
          placeholder="Rechercher Catégories"
          @input="handlSelectedCategory"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="true"
          label="name"
          track-by="id"
          :preselect-first="false"
          :max-height="100"
          :allow-empty="false"
        >
          <span slot="noResult">Aucun Catégorie trouvé.</span>
          <span slot="noOptions">Aucun Catégorie trouvé.</span>
        </multiselect>
      </b-form-group>
      <div class="message">
        <div v-if="getProjectsProcessing" class="loading m-2">
          Chargement en cours...
        </div>
        <div v-if="getProjectsError" class="error">
          <ul v-if="Array.isArray(getProjectsError)">
            <li v-for="(e, index) in getProjectsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          variant="outline-secondary"
          @click="hideModal('Modifier-comment')"
        >
          Annuler
        </b-button>
        <b-button
          variant="success"
          class="btn-valider"
          @click="clickupdateNote"
        >
          Enregistrer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import EEditor from '@/views/Projects/EEditor'
import NoteMention from '@/views/Projects/noteMention/NoteMention'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
moment.locale('fr')
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    // EEditor
    NoteMention
  },
  props: {
    entity: { required: true },
    project: { required: false },
    sticky: { default: false },
    readonly: { defalut: false },
    categoryProjet: { required: false }
  },
  data() {
    return {
      commentBody: this.entity.body,
      reset: 0,
      selectedCategory: this.entity.category
        ? this.entity.category
        : { id: this.entity.category_id, name: this.entity.category_name }
    }
  },
  methods: {
    ...mapActions([
      'updateNote',
      'deleteNote',
      'resetPageValue',
      'fetchProjectHistoriques',
      'fetchComments',
      'fetchListCategorieProjets',
      'resetErrorProject'
    ]),

    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetErrorProject()
    },
    clickDeleteNote() {
      this.deleteNote({ comment: this.entity, project: this.project })
      this.hideModal('Supprimer-comment')
      this.resetPageValue({ object: 'comment' })
      this.fetchProjectHistoriques({
        project: this.project,
        page: 1,
        reset: 1
      })
      this.fetchComments({
        project: this.project,
        page: 1,
        reset: 1
      })
    },
    handelEpingleNote() {
      this.$nextTick(() => {
        const epingler = this.entity.epingler ? 0 : 1
        this.updateNote({
          data: { epingler: epingler },
          entity: this.entity,
          project: this.project
        })
      })
    },
    handelVisibility() {
      const public_comment = this.entity.public ? 0 : 1
      this.updateNote({
        data: { public: public_comment },
        entity: this.entity,
        project: this.project
      })
    },
    handlSelectedCategory(category) {
      this.selectedCategory = category
    },
    async clickupdateNote() {
      if (
        this.commentBody != this.entity.body ||
        this.selectedCategory != this.entity.category
      ) {
        const response = await this.updateNote({
          data: {
            body: this.commentBody,
            category_id:
              this.selectedCategory && this.selectedCategory.id
                ? this.selectedCategory.id
                : null
          },
          entity: this.entity,
          project: this.project
        })
        if (response) {
          this.hideModal('Modifier-comment')
        }
      } else {
        this.hideModal('Modifier-comment')
      }
    },
    handelUpdateNote() {
      this.$refs['Modifier-comment'].show()
      this.fetchListCategorieProjets()
      this.commentBody = this.entity.body
      this.selectedCategory = this.entity.category
        ? this.entity.category
        : { id: this.entity.category_id, name: this.entity.category_name }
    },
    handleDeleteClick() {
      this.$refs['Supprimer-comment'].show()
    }
  },
  computed: {
    ...mapGetters([
      'getListCategorieProjet',
      'getProjectsProcessing',
      'getProjectsError'
    ])
  },
  filters: {
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return '--:--'
      return moment(value).format(format)
    },
    key: value => {
      switch (value) {
        case 'status':
          return 'Statut'
        case 'cstage_id':
          return 'Stade'
        case 'csubstage_id':
          return 'Sous-stade'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss">
.activity-note {
  .activity-dropdown-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
    padding: 9px;
    width: 28px;
    height: 25px;
    line-height: 0;
    box-shadow: none !important;
  }
}
</style>
<style lang="scss" scoped>
.activity-note {
  position: relative;
  .icon-visibility {
    position: absolute;
    top: 0px;
    right: 10px;
    z-index: 1;
    font-size: 10px;
  }

  .icon-entity-note {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 35px;
    height: 35px;
    z-index: 2;
    top: 12px;
    left: -34px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 50%;
    font-size: 16px;
    .icon-entity-note-comment {
      transform: rotateY(180deg);
    }
  }

  .icon-pin-note {
    display: none;
    position: absolute;
    left: -7px;
    top: -6px;
    font-size: 18px;
    z-index: 1;
    .icon-pin-note-comment {
      transform: rotateZ(-45deg);
    }
  }

  .page-content-note {
    padding-bottom: 3px;
    outline: none;
    .actionNote {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px;
      .btn-valider,
      .btn-annuler {
        &.btn-annuler {
          color: #5e5e5e;
          margin-right: 5px;
          background-color: #e5e5e5;
          border-color: #f8f9fa;
        }
      }
    }
    .description-bloc-comment {
      position: relative;
      height: auto;
      font-size: 12px;
      margin-top: 3px;
      padding: 3px 11px;
      background-color: #fffcdc;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      margin-left: 26px;

      .description-note {
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        color: #5e5e5ed1;
        border-bottom: 1px solid #ececec;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }

      &:before {
        content: '';
        position: absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #d8d8d8;
        left: -9px;
        top: 20px;
      }

      &:after {
        content: '';
        position: absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #fffcdc;
        left: -8px;
        top: 20px;
      }

      &.error {
        background-color: #ff6961;
        &:before {
          border-right: 20px solid #ff6961;
        }
      }
    }
    .editor {
      position: relative;
      background-attachment: fixed;
      border: 1px solid #e9eab3;
      background-color: #fbfdc2;
      padding: 6px;
      max-width: inherit;
      margin-left: 25px;
    }
  }

  &.sticky-comment {
    .icon-entity-note {
      display: none;
    }
    .icon-pin-note {
      display: block;
    }

    .page-content-note {
      .description-bloc-comment {
        margin-left: 0;
        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }

      .editor {
        margin-left: 0;
      }
    }
  }
}

#Supprimer-comment {
  .msg-delete {
    padding-bottom: 12px;
  }
}
</style>
