<template>
  <div
    class="editable-input"
    :class="{
      'email-input': field.key == 'emails' && field.value && field.value.length
    }"
  >
    <div class="edit-view" v-if="editing">
      <dynamic-input
        :input="{
          type: field.input_type,
          options: field.options ? field.options.data : [],
          name: field.key,
          links: field.links ? field.links : [],
          selected: field.selection_type,
          valuePossible: field.possible_values
            ? field.possible_values.data
            : [],
          formatDate: field.format ? true : false,
          message: computedButtonValider,
          sousCategory: sousCategory,
          hasRegroupementSelect: field.has_regroupement,
          logistiqueTravaux: field.logistique_travaux,
          typeIsolation: field.type_isolation
        }"
        v-model="newValue"
        @save="update"
        @cancel="editing = false"
      ></dynamic-input>
      <div v-if="message_error_hour_date" class="error">
        Valeur erronée Merci d'introduire une valeur comprise entre 7h du matin
        et 20h après-midi
      </div>
      <div v-if="message_error_hour_date_obligatoire" class="error">
        Vous ne pouvez pas vider le champ date fin previsite vu que le champ
        date debut prévisite et rempli
      </div>
      <div v-if="message_error_weekend" class="error">
        <!-- Vous ne pouvez pas sélectionner une date de fin de semaine ( samedi ou dimanche-->
        Vous ne pouvez pas sélectionner une date de fin de semaine (dimanche )
      </div>
      <div
        class="edit-action flex justify-content-end mt-1"
        :class="{
          'button-phone-emails':
            field.key === 'phones' || field.key === 'emails'
        }"
      >
        <div @click="close">Annuler</div>
        <div @click="update" v-if="computedButtonValider" class="green ml-2">
          Enregistrer
        </div>
      </div>
    </div>
    <div class="show-view" v-else>
      <!-- daterange / timerange  -->
      <div
        class="value mr-2"
        :class="{ 'auto-width': autoWidth }"
        v-if="
          field.input_type === 'daterange' || field.input_type === 'timerange'
        "
      >
        {{ value ? value.join('~') : '-' }}
      </div>
      <!-- date / datetime -->
      <div
        class="value mr-2"
        :class="{ 'auto-width': autoWidth }"
        v-if="
          (field.input_type === 'date' || field.input_type === 'datetime') &&
            field.format == null
        "
      >
        <div v-if="value">
          {{ value | date }}
          <span v-if="value && field.input_type === 'datetime'"
            >à {{ value | time }}</span
          >
        </div>
        <div v-else>{{ '-' }}</div>
      </div>
      <!-- table -->
      <div
        class="value mr-2"
        :class="{ 'auto-width': autoWidth }"
        v-else-if="field.input_type === 'table'"
      >
        {{ cumputedValue }}
      </div>
      <!-- set -->
      <div
        v-else-if="field.input_type === 'set'"
        class="value mr-2"
        :class="{ 'auto-width': autoWidth }"
      >
        {{ value ? value.join(', ') : '-' }}
      </div>
      <!-- multiple_select && type de selection multiple  -->
      <div
        v-else-if="
          field.input_type === 'multiple_select' &&
            field.selection_type === 'multiple'
        "
        class="value mr-2"
        :class="{ 'auto-width': autoWidth }"
      >
        <!-- set -->
        {{ Array.isArray(value) && value.length ? value.join(', ') : '-' }}
      </div>
      <!-- subventions -->
      <div
        v-else-if="field.input_type === 'subventions'"
        class="value mr-2"
        :class="{ 'auto-width': autoWidth }"
      >
        <div class="subventions-sub-field">
          <div class="bold">LOGIN</div>
          {{ value && value.login ? value.login : '-' }}
        </div>
        <div class="subventions-sub-field">
          <div class="bold">MOT DE PASSE</div>
          {{ value && value.mdp ? value.mdp : '-' }}
        </div>
        <div class="subventions-sub-field">
          <div class="bold">STATUT</div>
          {{ value && value.status ? value.status : '-' }}
        </div>
        <div class="subventions-sub-field">
          <div class="bold">MONTANT DISPONIBLE</div>
          {{
            value && value.montant_disponible ? value.montant_disponible : '-'
          }}
        </div>
      </div>
      <!-- phones -->
      <div
        class="value mr-2"
        :class="{ 'auto-width': autoWidth }"
        v-else-if="field.key === 'phones'"
      >
        <div v-if="value && value.length">
          <div v-for="item in value" :key="item.id" class="item">
            <div v-if="field.key === 'phones'" class="width-value">
              <a :href="'tel:' + item.number" class="value-phones">{{
                $options.filters.formatTel(item.number)
              }}</a
              ><br v-if="item.typeP" />
              <span v-if="item.typeP"> ({{ item.typeP }})</span>
            </div>
          </div>
        </div>
        <div v-else>{{ '-' }}</div>
      </div>
      <!-- emails -->
      <div
        class="value mr-2"
        :class="{ 'auto-width': autoWidth }"
        v-else-if="field.key === 'emails'"
      >
        <div v-if="value && value.length">
          <div v-for="item in value" :key="item.id" class="item">
            <div v-if="field.key === 'emails'" class="width-value">
              <a :href="'mailto:' + item.email" class="value-emails">{{
                item.email
              }}</a>
              <br v-if="item.typeE" />
              <span v-if="item.typeE"> ({{ item.typeE }})</span>
            </div>
          </div>
        </div>
        <div v-else>{{ '-' }}</div>
      </div>
      <div v-else class="value mr-2" :class="{ 'auto-width': autoWidth }">
        {{
          value && !value.name ? value : value && value.name ? value.name : '-'
        }}
      </div>
      <div
        v-if="
          !readonly &&
            !fieldKeyEdit &&
            computedNotModifiable &&
            computedInfoFiscalEditableFieldKeyNotRegie
        "
        class="show-action"
        :class="{ disabled: field.disable }"
        @click="openInput"
      >
        <font-awesome-icon class="icon" icon="pencil-alt" />
      </div>
      <div
        v-if="
          readonly &&
            (computedModifiablefieldKeyRegie ||
              computedInfoFiscalEditableFieldKeyRegie)
        "
        class="show-action"
        :class="{ disabled: field.disable }"
        @click="openInput"
      >
        <font-awesome-icon class="icon" icon="pencil-alt" />
      </div>
      <div
        class="show-action ml-1"
        @click="envoiCurrentUser"
        v-if="
          !readonly &&
            !fieldKeyEdit &&
            computedNotModifiable &&
            field.pre_rempli_field
        "
      >
        <DoightLeveIcon></DoightLeveIcon>
      </div>
    </div>
  </div>
</template>

<script>
import dynamicInput from '@/components/ui/dynamic-form/dynamic-input.vue'
import DoightLeveIcon from '@/components/ui/DoightLeveIcon.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'EditableCustomFieldCategorie',
  components: {
    dynamicInput,
    DoightLeveIcon
  },
  props: {
    field: { required: true },
    entity: { required: true },
    value: { required: false },
    updateFunction: { required: true },
    autoWidth: { default: false },
    readonly: { default: false },
    fieldKeyEdit: { required: false },
    sousCategory: { required: false },
    openInputAuto: { required: false },
    contentInfoFiscal: { required: false }
  },
  data() {
    return {
      editing: false,
      newValue: this.value,
      message_error_hour_date: false,
      message_error_hour_date_obligatoire: false,
      message_error_weekend: false
    }
  },
  mounted() {
    this.newValue = this.value
    if (this.field.input_type === 'table') {
      if (this.field.table_name === 'users') {
        this.field.options.data = this.usersList
      }
    }
    if (this.field.input_type === 'multiple_select') {
      this.value = this.field.value
    }
  },
  methods: {
    envoiCurrentUser() {
      const payload = {
        entity: this.entity
      }
      let field_group = [
        {
          key: this.field.key,
          value: this.currentUser.id
        },
        {
          key: 'pre_rempli_field',
          value: this.field.pre_rempli_field == 1 ? 1 : 0
        }
      ]
      payload.field = field_group
      payload.value = field_group
      //  pour tester ce fields dans file projet js
      payload.field_group = true
      this.updateFunction(payload)
    },
    openInput() {
      this.editing = true
      this.newValue = this.value
      this.message_error_hour_date = false
      this.message_error_hour_date_obligatoire = false
      this.message_error_weekend = false
    },
    close() {
      this.editing = false
      this.message_error_hour_date = false
      this.message_error_hour_date_obligatoire = false
      this.message_error_weekend = false
      this.newValue = this.value
    },
    update() {
      this.message_error_hour_date = false
      this.message_error_hour_date_obligatoire = false
      this.message_error_weekend = false
      if (
        this.newValue &&
        Array.isArray(this.newValue) &&
        this.field.key != 'phones' &&
        this.field.key != 'emails'
      ) {
        //array of string or integer ( type set )
        let oldvalue = this.value.map(oldval => oldval)
        let newvalue = this.newValue.map(newval => newval)
        let diffoldWithnewValue =
          oldvalue.length === newvalue.length &&
          oldvalue.every(function(element) {
            return newvalue.includes(element)
          })
        if (!diffoldWithnewValue) {
          const payload = {
            entity: this.entity,
            field: this.field.key,
            value: newvalue
          }
          // envoyer dans body
          if (this.field.logistique_travaux) {
            payload.logistique_travaux = this.field.logistique_travaux
          }
          if (this.field.type_travaux) {
            payload.type_travaux = this.field.type_travaux
          }
          if (this.field.type_isolation) {
            payload.type_isolation = this.field.type_isolation
          }
          this.updateFunction(payload)
        }
        this.editing = false
      } else if (this.field.key == 'phones' || this.field.key == 'emails') {
        const payload = {
          entity: this.entity,
          field: this.field.key,
          value: this.newValue
        }
        // envoyer dans body
        if (this.field.logistique_travaux) {
          payload.logistique_travaux = this.field.logistique_travaux
        }
        if (this.field.type_travaux) {
          payload.type_travaux = this.field.type_travaux
        }
        if (this.field.type_isolation) {
          payload.type_isolation = this.field.type_isolation
        }
        this.updateFunction(payload)
        this.editing = false
      } else {
        if (this.value != this.newValue) {
          const payload = {
            entity: this.entity,
            field: this.field.key,
            value: this.newValue ? this.newValue : null
          }
          // envoyer dans body
          if (this.field.logistique_travaux) {
            payload.logistique_travaux = this.field.logistique_travaux
          }
          if (this.field.type_travaux) {
            payload.type_travaux = this.field.type_travaux
          }
          if (this.field.type_isolation) {
            payload.type_isolation = this.field.type_isolation
          }
          // TYPE TIME RANGE AND DATE RANGE
          if (
            this.field.input_type == 'timerange' ||
            this.field.input_type == 'daterange'
          ) {
            payload.value = this.newValue.join('/')
          }
          // TYPE DATE AND DATE TIME
          if (
            this.field.input_type == 'date' ||
            this.field.input_type == 'datetime'
          ) {
            //  FORMAT DATE  YYYY-MM-DD HH:mm:ss
            if (
              this.field.key == 'date_fin_previsite' ||
              this.field.key == 'date_fin_visite_tech'
            ) {
              payload.value = this.newValue
                ? moment(this.newValue, 'YYYY-MM-DDTHH:mm').format(
                    'YYYY-MM-DD HH:mm:ss'
                  )
                : this.newValue
            } else {
              // FORMAT DATE  YYYY-MM-DD HH:mm
              payload.value = this.newValue
                ? moment(this.newValue, 'YYYY-MM-DDTHH:mm').format(
                    'YYYY-MM-DD HH:mm'
                  )
                : this.newValue
            }

            // value date and time sous format objet date : YYYY-MM-DD heure : HH:mm:ss
            //  date_travaux /date_closing /date_previsite'/
            if (
              this.field.regroupement_date_time == 1 &&
              this.field.key_date_time
            ) {
              let key_1 = this.field.key
              let key_2 = this.field.key_date_time
              //  value groupe date end time
              let value_date_time_groupe = this.newValue
                ? moment(this.newValue, 'YYYY-MM-DDTHH:mm').format(
                    'YYYY-MM-DD HH:mm:ss'
                  )
                : this.newValue
              //  separetor le value date et value time
              let field_group = [
                {
                  key: key_1,
                  value: value_date_time_groupe
                    ? moment(
                        value_date_time_groupe,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('YYYY-MM-DD HH:mm:ss')
                    : value_date_time_groupe
                },
                {
                  key: key_2,
                  value: value_date_time_groupe
                    ? moment(
                        value_date_time_groupe.split(' ')[1],
                        'HH:mm:ss'
                      ).format('HH:mm')
                    : value_date_time_groupe
                }
              ]
              payload.field = field_group
              payload.value = field_group
              //  pour tester ce fields dans file projet js
              payload.field_group = true
            }
          }
          // TYPE INPUT GROUPE SELECT
          //  has regroupement select
          if (this.field.has_regroupement == 1) {
            let field_group = [
              {
                key: this.newValue.key_1,
                value: this.newValue.value_1
              },
              {
                key: this.newValue.key_2,
                value: this.newValue.value_2
              },
              {
                key: this.newValue.key_3,
                value: this.newValue.value_3
              }
            ]
            payload.field = field_group
            payload.value = field_group
            //  pour tester ce fields dans file projet js
            payload.field_group = true
          }
          if (
            this.field.key == 'date_previsite' ||
            this.field.key == 'date_visite_tech'
          ) {
            if (payload.field && payload.field.length) {
              for (let index = 0; index < payload.field.length; index++) {
                const element = payload.field[index]
                if (
                  element.key == 'hour_previsite' ||
                  element.key == 'hour_visite_tech'
                ) {
                  var hours = element.value.split(':')[0]
                  if (hours != '') {
                    if (hours >= 7 && hours <= 20) {
                      this.updateFunction(payload)
                      this.editing = false
                      this.message_error_hour_date = false
                      this.message_error_hour_date_obligatoire = false
                      this.$emit('openInput', true)
                    } else {
                      this.message_error_hour_date = true
                      this.message_error_hour_date_obligatoire = false
                    }
                  } else {
                    this.updateFunction(payload)
                    this.editing = false
                    this.message_error_hour_date = false
                    this.message_error_hour_date_obligatoire = false
                    this.$emit('openInput', true)
                  }
                }
              }
            }
          } else if (
            this.field.key == 'date_fin_previsite' ||
            this.field.key == 'date_fin_visite_tech'
          ) {
            if (payload.value) {
              var dateTime = payload.value.split(' ')[1]
              var hour = dateTime.split(':')[0]
              if (hour != '') {
                if (hour >= 7 && hour <= 20) {
                  this.updateFunction(payload)
                  this.editing = false
                  this.message_error_hour_date = false
                  this.message_error_hour_date_obligatoire = false
                } else {
                  this.message_error_hour_date = true
                  this.message_error_hour_date_obligatoire = false
                }
              } else {
                this.editing = false
                this.message_error_hour_date = false
                this.message_error_hour_date_obligatoire = false
              }
            } else {
              this.editing = true
              this.message_error_hour_date = false
              this.message_error_hour_date_obligatoire = true
            }
          }
          // logistique && travaux selon date weekend and heure
          else if (
            this.field.key == 'date_rdv' ||
            this.field.key == 'fin_rdv'
          ) {
            if (
              this.field.key == 'date_rdv' &&
              payload.field &&
              payload.field.length
            ) {
              for (let index = 0; index < payload.field.length; index++) {
                const element = payload.field[index]
                if (element.key == 'date_rdv') {
                  var day = new Date(element.value).getUTCDay()
                  //  ([6, 0].includes(day)) samedi and dimanche width index day
                  if ([0].includes(day)) {
                    this.editing = true
                    this.message_error_weekend = true
                    this.message_error_hour_date = false
                    this.message_error_hour_date_obligatoire = false
                  } else {
                    if (payload.field && payload.field.length) {
                      for (
                        let index = 0;
                        index < payload.field.length;
                        index++
                      ) {
                        const element = payload.field[index]
                        if (element.key == 'hour_rdv') {
                          var hoursWeek = element.value.split(':')[0]
                          if (hoursWeek != '') {
                            if (hoursWeek >= 7 && hoursWeek <= 20) {
                              this.updateFunction(payload)
                              this.editing = false
                              this.message_error_hour_date = false
                              this.message_error_hour_date_obligatoire = false
                              this.$emit('openInput', true)
                            } else {
                              this.message_error_hour_date = true
                              this.message_error_hour_date_obligatoire = false
                            }
                          } else {
                            this.updateFunction(payload)
                            this.editing = false
                            this.message_error_hour_date = false
                            this.message_error_hour_date_obligatoire = false
                            this.$emit('openInput', true)
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else if (this.field.key == 'fin_rdv') {
              if (payload.value) {
                var dayWeek = new Date(payload.value).getUTCDay()
                //  ([6, 0].includes(day)) samedi and dimanche width index day
                if ([0].includes(dayWeek)) {
                  this.editing = true
                  this.message_error_weekend = true
                  this.message_error_hour_date = false
                  this.message_error_hour_date_obligatoire = false
                } else {
                  var dateTimeWeek = payload.value.split(' ')[1]
                  var hourWeek = dateTimeWeek.split(':')[0]
                  if (hourWeek != '') {
                    if (hourWeek >= 7 && hourWeek <= 20) {
                      this.updateFunction(payload)
                      this.editing = false
                      this.message_error_hour_date = false
                      this.message_error_hour_date_obligatoire = false
                    } else {
                      this.message_error_hour_date = true
                      this.message_error_hour_date_obligatoire = false
                    }
                  } else {
                    this.editing = false
                    this.message_error_hour_date = false
                    this.message_error_hour_date_obligatoire = false
                  }
                }
              } else {
                this.editing = true
                this.message_error_hour_date = false
                this.message_error_hour_date_obligatoire = true
              }
            }
          } else {
            this.updateFunction(payload)
            this.editing = false
            this.message_error_hour_date = false
            this.message_error_hour_date_obligatoire = false
          }
        } else {
          this.editing = false
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getProjectsUsers', 'currentUser']),
    usersList: function() {
      return this.getProjectsUsers.map(u => {
        return {
          text: u.full_name,
          value: u.id
        }
      })
    },
    cumputedValue: function() {
      if (!this.usersList || !this.value) return null
      let value = ''
      this.usersList.forEach(u => {
        if (this.value == u.id) {
          value = u.label
        }
      })
      return value
    },
    computedInfoFiscalEditableFieldKeyNotRegie: function() {
      if (
        this.currentUser &&
        !this.currentUser.isSuper &&
        this.sousCategory &&
        (this.sousCategory.id == 8 || this.sousCategory.id == 1)
      ) {
        // si info fiscal sont remplir
        if (
          this.field.key == 'adresse' ||
          this.field.key == 'zipCode' ||
          this.field.key == 'city' ||
          this.field.key == 'last_name' ||
          this.field.key == 'first_name' ||
          this.field.key == 'date_naissance'
        ) {
          if (
            this.contentInfoFiscal &&
            this.value &&
            this.currentUser &&
            this.currentUser.can_edit_fiscal == 0
          ) {
            return false
          }
          return true
        }
        // si info fiscal non remplir
        else {
          return true
        }
      } else {
        return true
      }
    },
    computedNotModifiable: function() {
      if (this.field.is_modifiable == 1) {
        return true
      } else {
        return false
      }
    },
    computedModifiablefieldKeyRegie: function() {
      //  editable les champ selon category Adresse des travaux
      if (this.sousCategory && this.sousCategory.id == 9) {
        if (
          this.field.key == 'adresse' ||
          this.field.key == 'zipCode' ||
          this.field.key == 'city' ||
          this.field.key == 'zone'
        ) {
          return true
        } else {
          return false
        }
      }
      // Type Renovation editable pour compte regie
      else if (
        this.field.key == '32bac4aa228aa42c73ce3024bf40d11f813c783fd255d8a3c3'
      ) {
        return true
      } else {
        return false
      }
    },
    computedInfoFiscalEditableFieldKeyRegie: function() {
      // editable les champ selon category Coordonnées (projet)  and Informations Logement and Coordonnées (contact)
      if (
        this.sousCategory &&
        (this.sousCategory.id == 10 || this.sousCategory.id == 3)
      ) {
        if (!this.value || !this.value.length) {
          return true
        } else {
          return false
        }
      } else if (
        this.sousCategory &&
        (this.sousCategory.id == 8 || this.sousCategory.id == 1)
      ) {
        // si info fiscal sont remplir
        if (
          this.field.key == 'adresse' ||
          this.field.key == 'zipCode' ||
          this.field.key == 'city' ||
          this.field.key == 'last_name' ||
          this.field.key == 'first_name' ||
          this.field.key == 'date_naissance'
        ) {
          if (
            this.contentInfoFiscal &&
            this.value &&
            this.currentUser &&
            this.currentUser.can_edit_fiscal == 0
          ) {
            return false
          } else {
            return true
          }
        }
        //sinon info fiscal non remplir
        else {
          if (!this.value || !this.value.length) {
            return true
          }
          return false
        }
      } else {
        return false
      }
    },
    computedButtonValider: function() {
      if (
        this.field.input_type == 'radio' &&
        (!this.field.possible_values ||
          !this.field.possible_values.data ||
          !this.field.possible_values.data.length)
      ) {
        return false
      } else {
        return true
      }
    }
  },
  filters: {
    formatTel: function(value) {
      if (!value) {
        return
      }
      return value.replace(/(\d\d(?!$))/g, '$1 ')
    },
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm') {
      if (!value) return '--:--'
      return moment(value).format(format)
    }
  },
  watch: {
    openInputAuto() {
      this.editing = this.openInputAuto
      this.newValue = this.value
    },
    readonly(e) {
      if (e) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.editable-input {
  width: 65%;
  &.email-input {
    width: 85%;
  }
  .edit-view {
    display: block;
    .border-input {
      border: 1px solid #e5e5e5;
    }
    .error {
      color: #e91e63;
      font-weight: bold;
      margin-top: 3px;
    }
    .edit-action {
      margin-left: 187px;
      cursor: pointer;

      div {
        color: #5e5e5e;
        border: 1px solid #a9adb1;
        user-select: none;
        margin: 0;
        padding: 0px 2px 0px 2px;
        border-radius: 2px;
        font-size: 11px;
        font-weight: 600;
        &.green {
          background: #8bc34a;
          color: #fff;
          border: 1px solid #a9adb1;
          &:hover {
            background: #6bc00a;
            color: #fff;
            border: 1px solid #a9adb1;
          }
        }
      }
      &.button-phone-emails {
        margin-left: 260px;
      }
    }
  }
  .show-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      .show-action {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #bdbdbd;
        border-radius: 4px;
        border: 1px solid #bdbdbd;
        width: 25px;
        height: 20px;
        opacity: 1;
        &.disabled {
          pointer-events: none;
          opacity: 0;
        }
      }
    }

    .show-action {
      display: none;
      cursor: pointer;
      &:hover {
        border: 1px solid #404346 !important;
        color: #404346 !important;
      }
    }

    .value {
      width: 130px;
      &.auto-width {
        width: auto;
      }
    }

    .value {
      width: 140px;
      &.auto-width {
        width: auto;
      }

      .subventions-sub-field {
        font-size: 11px;
        line-height: 14px;
        padding-bottom: 5px;
        padding-left: 5px;
        border-left: 2px solid #d5d5d5;
      }
      .item {
        .width-value {
          width: 200px;
          .value-phones,
          .value-emails {
            color: #5e5e5e;
            &.value-emails {
              text-decoration: underline;
            }
            &.value-phones {
              text-decoration: none;
            }
          }
          &:hover {
            width: 180px;
          }
        }
      }
    }
  }
}
</style>
