<template>
  <div>
    <div
      v-if="
        getCategorieLoading &&
          (!computedCategoriesType || !computedCategoriesType.length)
      "
      class="init-loading three-dots-loading"
    >
      Chargement en cours
    </div>
    <b-tabs
      class="files-tabs"
      lazy
      v-else-if="computedCategoriesType && computedCategoriesType.length"
    >
      <b-tab
        no-body
        :title-link-class="linkClass(categorie)"
        v-for="(categorie, index) in computedCategoriesType"
        :key="'categorie' + index"
        @click="clickCateg(categorie)"
      >
        <template #title>
          <div
            v-tooltip.top-center="
              `Sous catégorie : ${
                categorie.subCategorie && categorie.subCategorie.length
                  ? categorie.subCategorie.length
                  : 0
              } / Fichiers : ${
                getCounterFilesSubCategoriesPieceJointe &&
                getCounterFilesSubCategoriesPieceJointe.length &&
                getCounterFilesSubCategoriesPieceJointe[index].id ==
                  categorie.id
                  ? getCounterFilesSubCategoriesPieceJointe[index].count_files
                  : 0
              }`
            "
          >
            <span v-if="categorie.name != 'Voir plus...'">
              {{ categorie.name }}</span
            >
            <span
              class="counter"
              v-if="
                categorie.name != 'Tous' && categorie.name != 'Voir plus...'
              "
            >
              {{
                categorie.subCategorie && categorie.subCategorie.length
                  ? categorie.subCategorie.length
                  : 0
              }}
            </span>
          </div>
          <div v-if="categorie.name == 'Voir plus...'" @click="addCategorie">
            Voir plus...
          </div>
        </template>
      </b-tab>
    </b-tabs>
    <div
      class="aucun-subCategory"
      v-if="
        (!computedCategoriesType || !computedCategoriesType.length) &&
          !getCategorieLoading
      "
    >
      Aucun catégorie pièce jointe
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CategoryPJ',
  props: {
    project: {
      required: true
    },
    readonly: {
      default: false
    }
  },
  data() {
    return {
      voirPlus: false
    }
  },
  methods: {
    ...mapActions([
      'fetchFiles',
      'fetchCategorieFilesParLength',
      'fetchCategoriesPieceJointeFile'
    ]),
    addCategorie() {
      this.voirPlus = true
    },
    linkClass(catg) {
      if (
        catg.name != 'Voir plus...' &&
        catg.name != 'Tous' &&
        catg &&
        catg.subCategorie &&
        catg.subCategorie.length
      ) {
        return ['files-tab-title-green']
      } else {
        // if (catg && catg.subCategorie && catg.subCategorie.length) {
        return ['files-tab-title-tous']
        // } else {
        //   return ['files-tab-title']
        // }
      }
    },
    async clickCateg(categ) {
      await this.fetchCategoriesPieceJointeFile({
        loading: false,
        projet_id: this.project.id
      })
      this.fetchFiles({
        project: this.project,
        categorie: categ
      })
      this.fetchCategorieFilesParLength({
        projet_id: this.project.id,
        parent_id: categ.id
      })
    }
  },
  computed: {
    ...mapGetters([
      'getCategoriesPieceJointeFile',
      'getCategorieLoading',
      'getCounterFilesSubCategoriesPieceJointe'
    ]),
    computedCategoriesType: function() {
      let categorieListe = [
        // { name: 'Tous' },
        ...this.getCategoriesPieceJointeFile,
        { name: 'Voir plus...' }
      ]
      if (
        this.getCategoriesPieceJointeFile &&
        this.getCategoriesPieceJointeFile.length &&
        this.getCategoriesPieceJointeFile.length <= 4
      ) {
        categorieListe = [...this.getCategoriesPieceJointeFile]
      }
      if (
        !this.getCategoriesPieceJointeFile ||
        !this.getCategoriesPieceJointeFile.length
      ) {
        categorieListe = []
      }
      return categorieListe.filter((catg, key) => {
        if (!this.voirPlus) {
          if (key < 4) {
            return catg
          }
          if (categorieListe && key == categorieListe.length - 1) {
            return catg
          }
        } else {
          return catg.name != 'Voir plus...'
        }
      })
    }
  }
}
</script>

<style lang="scss">
.files-tabs {
  .nav-tabs {
    justify-content: center;
    background-color: #f3f3f3;
    border-radius: 5px;
    border: none;
    padding: 6px;
    .files-tab-title {
      position: relative;
      font-size: 10px;
      font-weight: bold;
      color: #404346;
      background-color: #e5e5e5;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 4px;
      cursor: pointer;
      user-select: none;
      transition: all 0.4s ease;

      .counter {
        // color: #858585;
        margin-left: 5px;
        padding: 1px 4px 2px 2px;
        background-color: #5e5e5e;
        border-radius: 9px;
        font-weight: bold;
        border: 1px solid;
        color: white;
        font-size: 10px;
      }

      &.active {
        color: #5e5e5e;
        background-color: #fff;
        .counter {
          // color: #2dabe2;
          padding: 1px 4px 2px 2px;
          background-color: #5e5e5e;
          border-radius: 9px;
          font-weight: bold;
          border: 1px solid;
          color: white;
          font-size: 10px;
        }
      }
    }
    .files-tab-title-tous {
      position: relative;
      font-size: 10px;
      font-weight: bold;
      color: #404346;
      background-color: #e5e5e5;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 4px;
      cursor: pointer;
      user-select: none;
      transition: all 0.4s ease;
      .counter {
        // color: #8bc34a;
        margin-left: 5px;
        padding: 1px 4px 2px 2px;
        background-color: #5e5e5e82;
        border-radius: 9px;
        font-weight: bold;
        border: 1px solid;
        color: white;
        font-size: 10px;
      }

      &.active {
        color: #5e5e5e;
        background-color: #fff;
        border: 1px solid #5e5e5e82;
        .counter {
          // color: #8bc34a;
          padding: 1px 4px 2px 2px;
          background-color: #5e5e5e82;
          border-radius: 9px;
          font-weight: bold;
          border: 1px solid;
          color: white;
          font-size: 10px;
        }
      }
    }
    .files-tab-title-green {
      position: relative;
      font-size: 10px;
      font-weight: bold;
      color: #ffffff;
      background-color: #8bc34a;
      border: 1px solid #8bc34a;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 4px;
      cursor: pointer;
      user-select: none;
      transition: all 0.4s ease;

      .counter {
        padding: 1px 4px 2px 2px;
        background-color: #f8f9fa;
        border-radius: 9px;
        font-weight: bold;
        border: 1px solid;
        color: #8bc34a;
        font-size: 10px;
        margin-left: 5px;
      }

      &.active {
        color: #5e5e5e;
        background-color: #fff;
        .counter {
          padding: 1px 4px 2px 2px;
          background-color: #8bc34a;
          border-radius: 9px;
          font-weight: bold;
          border: 1px solid;
          color: #fff;
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }
    .files-tab-title-categ {
      position: relative;
      font-size: 12px;
      color: #2dabe2;
      background-color: #f3f3f3;
      border: 1px solid #f3f3f3;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 4px;
      cursor: pointer;
      user-select: none;
      transition: all 0.4s ease;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .group-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-upload {
      padding: 3px 8px;
      margin: 3px 1px;
      font-size: 11px;
    }
    .btn-secondary {
      color: #5e5e5e;
      background-color: #e5e5e5;
      border-color: #d8d8d8;
      box-shadow: unset;
      &:focus {
        color: #5e5e5e;
        background-color: #e5e5e5;
        border-color: #d8d8d8;
        box-shadow: unset;
      }
      &:hover {
        color: #f8f9fa;
        background-color: #656565;
        border-color: #e5e5e5;
        box-shadow: unset;
      }
    }
  }
  .file-tous-upload {
    padding: 9px 6px 4px 24px;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #8bc34a;
    background-color: #8bc34a;
  }
  .style-spinner {
    height: 9px;
    width: 18px;
    padding: 10px 11px 2px 2px;
    color: #5e5e5e;
    &:hover {
      color: white;
    }
  }
}
.aucun-subCategory {
  color: #2dabe2;
  text-align: center;
}
</style>
