<template>
  <div class="page-content-history-pixel">
    <div
      class="page-content-history"
      v-for="history in historyPixel"
      :key="history.id"
    >
      <div class="description">
        Injection
        <span v-if="history.name_subtype">: {{ history.name_subtype }}</span> →
        {{ history.pixel_type }}
      </div>
      <div class="informations capitalize">
        <span>
          {{
            history.time ? $options.filters.date(history.time) : history.time
          }}
        </span>
        -
        <span v-if="history.user">
          {{ history.user }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    historyPixel: { required: true }
  },
  filters: {
    date: function(value, format = 'DD MMM HH:mm') {
      if (!value) return ''
      return moment(value, 'DD MMM HH:mm').format(format)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content-history-pixel {
  .page-content-history {
    position: relative;
    padding-bottom: 5px;
    .informations {
      color: #747678;
    }

    &::before {
      content: '';
      position: absolute;
      left: -24px;
      top: 4px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #e5e5e5;
      background: #fff;
      z-index: 2;
    }
  }
}
</style>
