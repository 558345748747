<template>
  <b-tabs class="history-tabs" lazy>
    <b-tab no-body title="Tous" title-link-class="history-tab-title">
      <div class="three-dots-loading" v-if="getProjectsProcessingHistory">
        Chargement en cours
      </div>
      <template v-if="project.timeline">
        <ProjectTimeLine
          v-for="(item, index) in project.timeline"
          :key="`${index}-${item.id}`"
          :entity="item"
          :project="project"
          :readonly="readonly"
          :type="type"
        />
      </template>
      <ProjectHistory
        :entity="{
          name: 'Prospect créé',
          date: project.created_at,
          user: project.user_creator
        }"
      />
      <div class="three-dots-loading" v-if="loadingVoirPlus">
        Chargement en cours
      </div>
      <div
        class="pagination-counter"
        @click="handlePaginationChange('tous')"
        v-if="calculTotalPageTous && !loadingVoirPlus"
      >
        Voir plus ...
      </div>
    </b-tab>
    <b-tab no-body title="Activités" title-link-class="history-tab-title">
      <div v-if="getProjectsProcessingHistory" class="three-dots-loading">
        Chargement en cours
      </div>
      <ProjectActivity
        v-for="activity in activityDone"
        :entity="activity"
        :key="'project-activity-' + activity.id"
        :project="project"
        :readonly="readonly"
        :type="type"
      />
      <div class="three-dots-loading" v-if="loadingVoirPlus">
        Chargement en cours
      </div>
      <div
        class="pagination-counter"
        @click="handlePaginationChange('activites')"
        v-if="calculTotalPageActivity && !loadingVoirPlus"
      >
        Voir plus ...
      </div>
    </b-tab>
    <b-tab no-body title="Notes" title-link-class="history-tab-title">
      <div v-if="getProjectsProcessingHistory" class="three-dots-loading">
        Chargement en cours
      </div>
      <ProjectComment
        v-for="note in project.comments"
        :entity="note"
        :key="'project-comment-' + note.id"
        :project="project"
        :readonly="readonly"
      />
      <div class="three-dots-loading" v-if="loadingVoirPlus">
        Chargement en cours
      </div>
      <div
        class="pagination-counter"
        @click="handlePaginationChange('notes')"
        v-if="calculTotalPageComments && !loadingVoirPlus"
      >
        Voir plus ...
      </div>
    </b-tab>
    <b-tab
      no-body
      title="Journal des modifications"
      title-link-class="history-tab-title"
    >
      <div v-if="getProjectsProcessingHistory" class="three-dots-loading">
        Chargement en cours
      </div>
      <ProjectHistory
        v-for="journal in project.journals"
        :entity="journal"
        :key="'project-history-' + journal.id"
        :project="project"
      />
      <ProjectHistory
        :entity="{
          name: 'Prospect créé',
          date: project.created_at,
          user: project.user_creator
        }"
      />
      <div class="three-dots-loading" v-if="loadingVoirPlus">
        Chargement en cours
      </div>
      <div
        class="pagination-counter"
        @click="handlePaginationChange('journal')"
        v-if="calculTotalPageJournal && !loadingVoirPlus"
      >
        Voir plus ...
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import ProjectTimeLine from '@/components/ui/ProjectTimeLine'
import ProjectHistory from '@/components/ui/ProjectHistory'
import ProjectActivity from '@/components/ui/ProjectActivity'
import ProjectComment from '@/components/ui/ProjectComment'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Files',
  props: {
    project: {
      required: true
    },
    readonly: {
      default: false
    },
    type: {
      required: false
    }
  },
  components: {
    ProjectTimeLine,
    ProjectHistory,
    ProjectActivity,
    ProjectComment
  },
  data() {
    return {
      pageNote: 1,
      pageActivity: 1,
      pageJournal: 1,
      pageTous: 1,
      loadingVoirPlus: false
    }
  },
  methods: {
    ...mapActions([
      'fetchComments',
      'fetchAllActivities',
      'fetchJournalDesModifications',
      'fetchProjectHistoriques'
    ]),
    async handlePaginationChange(tab) {
      this.loadingVoirPlus = true
      if (tab == 'notes') {
        this.pageNote = this.pageNote + 1
        await this.fetchComments({ project: this.project, page: this.pageNote })
      }
      if (tab == 'activites') {
        this.pageActivity = this.pageActivity + 1
        await this.fetchAllActivities({
          project: this.project,
          page: this.pageActivity
        })
      }
      if (tab == 'journal') {
        this.pageJournal = this.pageJournal + 1
        await this.fetchJournalDesModifications({
          project: this.project,
          page: this.pageJournal
        })
      }
      if (tab == 'tous') {
        this.pageTous = this.pageTous + 1
        await this.fetchProjectHistoriques({
          project: this.project,
          page: this.pageTous
        })
      }
      this.loadingVoirPlus = false
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsProcessingHistory',
      'getProjectsCommentstotalRows',
      'getProjectsActivitytotalRows',
      'getProjectsJournaltotalRows',
      'getProjectsHistoriquetotalRows',
      'getChangePageValue',
      'getObjectPrj'
    ]),
    activityDone() {
      return this.project.activities.filter(t => t.terminer == 1)
    },
    calculTotalPageComments: function() {
      if (
        this.getProjectsCommentstotalRows > 10 &&
        this.getProjectsCommentstotalRows - this.pageNote * 10 > 0
      ) {
        return true
      } else {
        return false
      }
    },
    calculTotalPageActivity: function() {
      if (
        this.getProjectsActivitytotalRows > 10 &&
        this.getProjectsActivitytotalRows - this.pageActivity * 10 > 0
      ) {
        return true
      } else {
        return false
      }
    },
    calculTotalPageJournal: function() {
      if (
        this.getProjectsJournaltotalRows > 10 &&
        this.getProjectsJournaltotalRows - this.pageJournal * 10 > 0
      ) {
        return true
      } else {
        return false
      }
    },
    calculTotalPageTous: function() {
      if (
        this.getProjectsHistoriquetotalRows > 10 &&
        this.getProjectsHistoriquetotalRows - this.pageTous * 10 > 0
      ) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    getChangePageValue: function() {
      if (this.getObjectPrj && this.getObjectPrj.object == 'comment') {
        this.pageTous = 1
        this.pageNote = 1
      }
      if (this.getObjectPrj && this.getObjectPrj.object == 'activity') {
        this.pageActivity = 1
        this.pageTous = 1
      }
      if (this.getObjectPrj && this.getObjectPrj.object == 'journal') {
        this.pageJournal = 1
      }
      if (this.getObjectPrj && this.getObjectPrj.object == 'tous') {
        this.pageTous = 1
      }
      if (this.getObjectPrj && this.getObjectPrj.object == 'tousWithjournal') {
        this.pageJournal = 1
        this.pageTous = 1
      }
    }
  }
}
</script>

<style lang="scss">
.history-tabs {
  .nav-tabs {
    justify-content: center;
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 6px 0;
    margin: 10px 0 36px 0;
    border: none;
    .history-tab-title {
      position: relative;
      font-size: 10px;
      font-weight: bold;
      color: #404346;
      background-color: #e5e5e5;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 4px;
      cursor: pointer;
      user-select: none;
      transition: all 0.4s ease;

      .counter {
        color: #858585;
        margin-left: 5px;
      }

      &.active {
        color: #5e5e5e;
        background-color: #fff;
        .counter {
          color: #2dabe2;
        }
      }
    }
  }
  .pagination-counter {
    font-size: 12px;
    color: #36afe3;
    cursor: pointer;
  }
}
</style>
