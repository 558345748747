var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity-form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleActivityUpdateForm.apply(null, arguments)}}},[_c('div',{staticClass:"activity-title"},[_c('b-form-input',{model:{value:(_vm.activity.name),callback:function ($$v) {_vm.$set(_vm.activity, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"activity.name"}})],1),_c('div',{staticClass:"activity-type"}),_c('div',{staticClass:"activity-date mt-3"},[_c('label',[_vm._v(" "+_vm._s(_vm.currentActivityType === 3 ? 'Suggestions des rendez-vous :' : 'Dates :')+" ")]),(_vm.currentActivityType === 3)?_c('div',{staticClass:"diponibility"},[(_vm.getProjectsSuggestionsProcessing)?_c('div',{staticClass:"three-dots-loading"},[_vm._v(" Chargement en cours ")]):[(_vm.planificationSuggestions)?[_c('div',{staticClass:"planning-description italic"},[_c('span',{staticClass:"planning-description--label bold"},[_vm._v(" Estimation de durée de pose : ")]),_c('span',{staticClass:"planning-description--value"},[_vm._v(" "+_vm._s(_vm._f("duration")(_vm.planificationSuggestions.pose_time_minutes))+" ")])]),_c('div',{staticClass:"suggestions-list mt-2 ml-2"},[(_vm.activity.type == 'Planning')?_c('label',{staticClass:"suggestion",class:{
                  active: _vm.selectedSuggestion == _vm.activity.date_start
                },attrs:{"for":"radio-suggest-update-selected"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activity.date_start),expression:"activity.date_start"}],attrs:{"type":"radio","name":"radio-suggest","id":"radio-suggest-update-selected"},domProps:{"value":_vm._f("date")(_vm.selectedSuggestion,'YYYY-MM-DD HH:mm:ss'),"checked":_vm._q(_vm.activity.date_start,_vm._f("date")(_vm.selectedSuggestion,'YYYY-MM-DD HH:mm:ss'))},on:{"change":function($event){_vm.$set(_vm.activity, "date_start", _vm._f("date")(_vm.selectedSuggestion,'YYYY-MM-DD HH:mm:ss'))}}}),_c('div',{staticClass:"suggestion-container"},[_c('div',{staticClass:"date-flag"},[_c('font-awesome-icon',{attrs:{"icon":"calendar-day"}})],1),_c('div',{staticClass:"date-details"},[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.selectedSuggestion,'ddd DD MMM'))+" ")]),_vm._v(" à "+_vm._s(_vm._f("time")(_vm.selectedSuggestion,'HH:mm'))+" ")])])]):_vm._e()]),_vm._l((_vm.planificationSuggestions.weeks),function(week,week_index){return _c('Collapse',{key:'planification-suggestions-update-' + week.week_id,attrs:{"init":week_index == 0 ? true : false,"selected":_vm.collapseSuggestions},on:{"toggle":function($event){_vm.collapseSuggestions = $event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(week_index == 0 ? ("Semaine en cours (N°" + (week.week_nbr) + ")") : week_index == 1 ? ("Semaine prochaine (N°" + (week.week_nbr) + ")") : ("Semaine (N°" + (week.week_nbr) + ")"))+" ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"suggestions-list"},_vm._l((week.available_dates_hours_techs),function(date,index){return _c('label',{key:'available-dates-hours-techs-' + index,staticClass:"suggestion",class:{
                      active:
                        _vm.activity.date_start == ((date.day) + " " + (date.hour) + ":00")
                    },attrs:{"for":("radio-suggest-update-" + week_index + "-" + index)}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activity.date_start),expression:"activity.date_start"}],attrs:{"id":("radio-suggest-update-" + week_index + "-" + index),"type":"radio","name":"radio-suggest"},domProps:{"value":((date.day) + " " + (date.hour) + ":00"),"checked":_vm._q(_vm.activity.date_start,((date.day) + " " + (date.hour) + ":00"))},on:{"change":function($event){return _vm.$set(_vm.activity, "date_start", ((date.day) + " " + (date.hour) + ":00"))}}}),_c('div',{staticClass:"suggestion-container"},[_c('div',{staticClass:"date-flag"},[_c('font-awesome-icon',{attrs:{"icon":"calendar-day"}})],1),_c('div',{staticClass:"date-details"},[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm._f("date")(date.day,'ddd DD MMM'))+" ")]),_vm._v(" à "+_vm._s(date.hour)+" ")])])])}),0)]},proxy:true}],null,true)})})]:_vm._e()]],2):_c('div',{staticClass:"activity-date-interval"},[_c('date-picker',{attrs:{"value":_vm.activity.date_start,"value-type":"format","format":"YYYY-MM-DD HH:mm","type":"datetime","placeholder":"Choisir date de début","required":""},model:{value:(_vm.activity.date_start),callback:function ($$v) {_vm.$set(_vm.activity, "date_start", $$v)},expression:"activity.date_start"}}),(_vm.activity.type == 'Appel' || _vm.activity.type == 'Autre')?_c('b-form-checkbox',{staticClass:"checkbox-terminer",attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.activity.terminer),callback:function ($$v) {_vm.$set(_vm.activity, "terminer", $$v)},expression:"activity.terminer"}},[_vm._v(" Activité terminée ")]):_vm._e()],1)]),(
        !_vm.activity.terminer &&
          (_vm.activity.type == 'Appel' || _vm.activity.type == 'Autre')
      )?_c('div',{staticClass:"activity-me-pevenir mt-3"},[_c('label',[_vm._v(" Me prévenir avant : ")]),_c('br'),_c('date-picker',{attrs:{"format":"HH:mm","value":_vm.activity.me_prevenir,"value-type":"format","type":"time","placeholder":"HH:mm"},model:{value:(_vm.activity.me_prevenir),callback:function ($$v) {_vm.$set(_vm.activity, "me_prevenir", $$v)},expression:"activity.me_prevenir"}})],1):_vm._e(),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Stade","label-for":"stage-input"}},[_c('Multiselect',{attrs:{"id":"stage-input","label":"name","track-by":"id","showLabels":false,"closeOnSelect":true,"allow-empty":true,"options":[{ id: -1, name: 'auccun' } ].concat( _vm.currentStages) || [
            { id: -1, name: 'auccun' }
          ],"searchable":true,"internal-search":true,"placeholder":"-- aucun --","required":""},on:{"input":_vm.handleStageChange},model:{value:(_vm.activity.cstage),callback:function ($$v) {_vm.$set(_vm.activity, "cstage", $$v)},expression:"activity.cstage"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Aucun stade trouvée.")]),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Aucun stade trouvée.")])])],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Sous-stade","label-for":"sub-stage-input"}},[_c('Multiselect',{attrs:{"id":"sub-stage-input","label":"name","track-by":"id","showLabels":false,"closeOnSelect":true,"allow-empty":true,"options":[{ id: -1, name: 'auccun' } ].concat( _vm.currentActivitySubStages) || [
            { id: -1, name: 'auccun' }
          ],"searchable":true,"internal-search":true,"placeholder":"-- aucun --"},model:{value:(_vm.activity.csubstage),callback:function ($$v) {_vm.$set(_vm.activity, "csubstage", $$v)},expression:"activity.csubstage"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Aucun sous-stade trouvée.")]),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Aucun sous-stade trouvée.")])])],1),_c('div',{staticClass:"activity-description mt-3"},[_c('label',[_vm._v(" Description : ")]),_c('e-editor',{model:{value:(_vm.activity.description),callback:function ($$v) {_vm.$set(_vm.activity, "description", $$v)},expression:"activity.description"}})],1),_c('div',{staticClass:"activity-user mt-3"},[_c('label',[_vm._v(" Affectée à : ")]),_c('b-form-group',[_c('Multiselect',{attrs:{"value":_vm.activity.user,"label":"text","track-by":"value","showLabels":false,"closeOnSelect":true,"open-direction":"bottom","allow-empty":false,"options":_vm.activityUsersList,"searchable":true,"internal-search":true,"placeholder":"Rechercher","loading":_vm.getProjectsUsersLoading,"required":""},model:{value:(_vm.activity.user),callback:function ($$v) {_vm.$set(_vm.activity, "user", $$v)},expression:"activity.user"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Aucun utilisateur trouvé.")]),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Aucun utilisateur trouvé.")])])],1)],1),(_vm.activityToAddError)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.activityToAddError)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }