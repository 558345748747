var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getCategorieLoading)?_c('div',{staticClass:"init-loading three-dots-loading"},[_vm._v(" Chargement en cours ")]):(_vm.computedCategoriesPj && _vm.computedCategoriesPj.length)?_c('b-tabs',{staticClass:"files-sub-tabs",attrs:{"lazy":""}},_vm._l((_vm.computedCategoriesPj),function(categorie,index){return _c('b-tab',{key:'categorie' + index,attrs:{"no-body":"","title-link-class":_vm.linkClass(categorie)},on:{"click":function($event){return _vm.clickCateg(categorie)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(
            (" Fichiers : " + (_vm.project.files && _vm.project.files.length ? _vm.project.files.length : 0))
          ),expression:"\n            ` Fichiers : ${\n              project.files && project.files.length ? project.files.length : 0\n            }`\n          ",modifiers:{"top-center":true}}]},[(
              categorie.name != 'Voir plus...' && categorie.name == 'Tous'
            )?_c('span',[_vm._v(" "+_vm._s(categorie.name))]):_vm._e(),(categorie.name == 'Tous')?_c('span',{staticClass:"counter"},[_vm._v(" "+_vm._s(_vm.project.files && _vm.project.files.length ? _vm.project.files.length : 0)+" ")]):_vm._e()]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(
            (" Fichiers : " + (categorie.count_files ? categorie.count_files : 0))
          ),expression:"\n            ` Fichiers : ${categorie.count_files ? categorie.count_files : 0}`\n          ",modifiers:{"top-center":true}}]},[(
              categorie.name != 'Voir plus...' && categorie.name != 'Tous'
            )?_c('span',[_vm._v(" "+_vm._s(categorie.name))]):_vm._e(),(
              categorie.name != 'Tous' && categorie.name != 'Voir plus...'
            )?_c('span',{staticClass:"counter"},[_vm._v(" "+_vm._s(categorie.count_files ? categorie.count_files : 0)+" ")]):_vm._e()]),(categorie.name == 'Voir plus...')?_c('div',{on:{"click":_vm.addCategorie}},[_vm._v(" Voir plus... ")]):_vm._e()]},proxy:true}],null,true)},[[(
            (categorie.files && categorie.files.length) ||
              (categorie.name == 'Tous' &&
                _vm.project.files &&
                _vm.project.files.length)
          )?_c('div',{staticClass:"group-file"},[_c('b-form-checkbox',{staticClass:"file-tous-upload",attrs:{"indeterminate":_vm.indeterminate},on:{"change":function($event){return _vm.toggleAll($event, categorie)}},model:{value:(_vm.allSelected),callback:function ($$v) {_vm.allSelected=$$v},expression:"allSelected"}},[_vm._v(" "+_vm._s(_vm.allSelected ? 'Selectionner tous les fichiers' : 'Aucun fichier selectionné')+" ")]),_c('div',[(_vm.allSelected)?_c('b-button',{staticClass:"btn-upload",attrs:{"title":"Télécharger","variant":"success"},on:{"click":_vm.uploadFile}},[(_vm.getProjectProcessingFiles)?_c('b-spinner',{staticClass:"style-spinner",style:({
                  opacity: _vm.getProjectProcessingFiles ? 1 : 0
                }),attrs:{"label":"Loading..."}}):_c('font-awesome-icon',{attrs:{"icon":"download"}})],1):_vm._e(),(_vm.allSelected)?_c('b-button',{staticClass:"btn-delete",attrs:{"variant":"danger","title":"Supprimer"},on:{"click":_vm.showDeleteFile}},[(_vm.getProjectsProcessing)?_c('b-spinner',{staticClass:"style-spinner",style:({
                  opacity: _vm.getProjectsProcessing ? 1 : 0
                }),attrs:{"label":"Loading..."}}):_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1)],1):_vm._e(),(categorie.name == 'Tous')?_c('div',_vm._l((_vm.project.files),function(file){return _c('ProjectFile',{key:'project-file-1-' + file.id,attrs:{"entity":file,"project":_vm.project,"preview":true,"readonly":_vm.readonly,"categorie":categorie,"gestionFile":_vm.gestionFile,"checkedTous":_vm.selectedcheckBox},on:{"checkedBox":_vm.checkedBox}})}),1):_vm._e(),_c('div',_vm._l((categorie.files),function(file,index){return _c('ProjectFile',{key:'project-file-2-' + index,attrs:{"entity":file,"project":_vm.project,"preview":true,"readonly":_vm.readonly,"categorie":categorie,"gestionFile":_vm.gestionFile,"checkedTous":_vm.selectedcheckBox},on:{"checkedBox":_vm.checkedBox}})}),1)]],2)}),1):_vm._e(),(
      (!_vm.computedCategoriesPj || !_vm.computedCategoriesPj.length) &&
        !_vm.getCategorieLoading
    )?_c('div',{staticClass:"aucun-subCategory"},[_vm._v(" Aucun sous catégorie pièce jointe ")]):_vm._e(),_c('b-modal',{ref:"delete-file",attrs:{"id":"delete-file","no-close-on-backdrop":"","hide-footer":true,"title":"Supprimer le(s) fichier(s)"}},[_c('div',{staticClass:"text"},[_vm._v(" Êtes-vous sûr de vouloir supprimer ce(s) fichier(s) ? ")]),_c('div',{staticClass:"form-actions"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.hideModal('delete-file')}}},[_vm._v(" Annuler ")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.deletedFile}},[_vm._v(" Supprimer ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }