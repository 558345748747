<template>
  <div class="dynamic-input-wrapper">
    <!-- <label v-show="input.label" :for="input.name">{{ input.label }}</label> -->

    <!-- error span -->
    <!-- <div v-show="error" class="error">
      <ul v-if="error instanceof Array">
        <li v-for="(e, index) in error" :key="'e-' + index">{{ e }}</li>
      </ul>
      <span v-else>{{ error }}</span>
    </div> -->

    <!-- start input type if -->

    <!-- string(input:text) -->
    <input
      v-if="input.type === 'text'"
      :class="input.class"
      type="text"
      :name="input.name"
      :value="value"
      @input="handleInput"
      @keyup.enter="handleKeyup"
      @keyup.esc="cancel"
      ref="autofocus"
    />
    <div
      v-else-if="input.hasRegroupementSelect == 1"
      class="content-amo-agence-flex"
    >
      <div class="flex">
        <div class="name">
          {{
            hasRegroupementSelectkey_1 && hasRegroupementSelectkey_1.name
              ? hasRegroupementSelectkey_1.name.split(/[()]+/)[0]
              : hasRegroupementSelectkey_1.name
          }}
        </div>
        <!-- set(multiselect) -->
        <div class="input">
          <multiselect
            class="set"
            :value="valueOptions"
            @input="handleMultiselectSetAgenceAmo($event, 'filiale')"
            :options="[...options] || []"
            trackBy="id"
            label="label"
            :showLabels="false"
            :closeOnSelect="true"
            :multiple="selectedOptions"
            :placeholder="input.placeholder || null"
            :limit="2"
            :limit-text="count => `et ${count} de plus`"
            :loading="getRouteDynamicProcessing"
          ></multiselect>
        </div>
      </div>
      <div class="flex">
        <div class="name">
          {{
            hasRegroupementSelectkey_2 && hasRegroupementSelectkey_2.name
              ? hasRegroupementSelectkey_2.name.split(/[()]+/)[0]
              : hasRegroupementSelectkey_2.name
          }}
        </div>
        <!-- set(multiselect) -->
        <div class="input">
          <multiselect
            class="set"
            :value="valueOptionsAgence"
            @input="handleMultiselectSetAgenceAmo($event, 'agence')"
            :options="[...optionsRegroupementSelectAgence] || []"
            trackBy="id"
            label="label"
            :showLabels="false"
            :closeOnSelect="true"
            :multiple="selectedOptions"
            :placeholder="input.placeholder || null"
            :limit="2"
            :limit-text="count => `et ${count} de plus`"
            :loading="getRouteDynamicProcessing"
          ></multiselect>
        </div>
      </div>
      <div class="flex">
        <!-- set(multiselect) -->
        <div class="name">
          {{
            hasRegroupementSelectkey_3 && hasRegroupementSelectkey_3.name
              ? hasRegroupementSelectkey_3.name.split(/[()]+/)[0]
              : hasRegroupementSelectkey_3.name
          }}
        </div>
        <div class="input">
          <multiselect
            class="set"
            :value="valueOptionsAmo"
            @input="handleMultiselectSetAgenceAmo($event, 'amo')"
            :options="[...optionsRegroupementSelectAmo] || []"
            trackBy="id"
            label="label"
            :showLabels="false"
            :closeOnSelect="true"
            :multiple="selectedOptions"
            :placeholder="input.placeholder || null"
            :limit="2"
            :limit-text="count => `et ${count} de plus`"
            :loading="getRouteDynamicProcessing"
          ></multiselect>
        </div>
      </div>
    </div>
    <!-- text(textarea) -->
    <textarea
      v-else-if="input.type === 'string'"
      :class="input.class"
      :name="input.name"
      :placeholder="input.placeholder || null"
      :value="value"
      @input="handleInput"
      ref="autofocus"
    ></textarea>
    <!-- integer|double(input:number) -->
    <input
      v-else-if="
        input.type === 'integer' ||
          input.type === 'double' ||
          input.type === 'number'
      "
      :class="input.class"
      type="number"
      :name="input.name"
      :min="input.min"
      :max="input.max"
      :step="input.step"
      :value="value"
      @input="handleInput"
      @keyup.enter="handleKeyup"
      @keyup.esc="cancel"
      ref="autofocus"
    />
    <!-- radio OUI / NON -->
    <b-form-radio-group
      class="radio-oui-non"
      v-else-if="input.type === 'radio_oui_non'"
      v-model="valueOptions"
      :value="valueOptions"
      :options="options || []"
      @change="handleRadio"
    >
    </b-form-radio-group>
    <!-- radio -->
    <div v-else-if="input.type === 'radio'" class="radio-style-input">
      <b-form-checkbox-group
        class="radio-oui-non"
        v-model="valueOptions"
        :value="valueOptions"
        :options="options || []"
        @change="handleRadio"
      ></b-form-checkbox-group>
    </div>
    <!-- multiple_list -->
    <div v-else-if="input.type === 'multiple_list'">
      <div
        class="list-element mb-2"
        v-for="(item, index) in options"
        :key="index"
      >
        <div class="flex align-center">
          <div class="flex" v-if="input.name == 'phones'">
            <input
              size="sm"
              class="value-input"
              type="text"
              :name="input.name"
              :value="item.number"
              @keyup.enter="handleKeyup"
              @keyup.esc="cancel"
              @input="handleInputList($event, 'numberPhone', index, 'phones')"
            />
            <multiselect
              size="sm"
              class="value-type ml-2"
              :value="item.typeP"
              @input="handleInputList($event, 'phoneTypes', index, 'phones')"
              :options="phoneTypes || []"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :show-labels="false"
              :placeholder="input.placeholder || null"
            ></multiselect>
          </div>
          <div class="flex" v-if="input.name == 'emails'">
            <input
              size="sm"
              class="value-input"
              type="text"
              :name="input.name"
              :value="item.email"
              @input="handleInputList($event, 'email', index, 'emails')"
              @keyup.enter="handleKeyup"
              @keyup.esc="cancel"
            />
            <multiselect
              size="sm"
              class="value-type ml-2"
              :value="item.typeE"
              @input="handleInputList($event, 'emailTypes', index, 'emails')"
              :options="emailTypes || []"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :show-labels="false"
              :placeholder="input.placeholder || null"
            ></multiselect>
          </div>
          <div
            class="action ml-2"
            v-if="options.length > 1"
            @click="removeInputList(index)"
          >
            <font-awesome-icon class icon="trash-alt" />
          </div>
        </div>
      </div>
      <div class="add-to-list-action" @click="ajouterInputList">
        <font-awesome-icon class="icon-plus" icon="plus" />
        Ajouter
        {{
          input.name == 'phones'
            ? 'un téléphone'
            : input.name == 'emails'
            ? 'un email'
            : ''
        }}
      </div>
    </div>

    <!-- enum(select) -->
    <multiselect
      v-else-if="input.type === 'enum'"
      :value="computedEnumValue"
      @input="handleMultiselectEnum"
      :options="input.options || []"
      trackBy="id"
      label="label"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :show-labels="false"
      :placeholder="input.placeholder || null"
    ></multiselect>

    <!-- set(multiselect) -->
    <multiselect
      class="set"
      v-else-if="input.type === 'multiple_select'"
      :value="valueOptions"
      @input="handleMultiselectSet"
      :options="[...options] || []"
      trackBy="id"
      label="label"
      :showLabels="false"
      :closeOnSelect="true"
      :multiple="selectedOptions"
      :placeholder="input.placeholder || null"
      :limit="2"
      :limit-text="count => `et ${count} de plus`"
      :loading="getRouteDynamicProcessing"
    ></multiselect>

    <!-- table -->
    <multiselect
      v-else-if="input.type === 'table'"
      :value="computedTableValue"
      @input="handleMultiselectTable"
      :options="input.options || []"
      trackBy="id"
      label="label"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :show-labels="false"
      :placeholder="input.placeholder || null"
    ></multiselect>

    <!-- date -->
    <date-picker
      v-else-if="input.type === 'date'"
      :format="input.formatDate ? 'DD-MM-YYYY' : 'YYYY-MM-DD'"
      value-type="format"
      type="date"
      :placeholder="input.placeholder || null"
      :value="value"
      @input="handleDateTimePicker($event)"
      ref="autofocus"
      :class="input.class"
      :editable="false"
    >
    </date-picker>
    <!-- daterange -->
    <date-picker
      class="date-picker-range"
      v-else-if="input.type === 'daterange'"
      format="DD-MM-YYYY"
      value-type="format"
      type="date"
      range
      :placeholder="input.placeholder || null"
      :value="value"
      @input="handleDateTimePicker($event)"
      ref="autofocus"
    ></date-picker>

    <!-- time -->
    <date-picker
      v-else-if="input.type === 'time'"
      format="HH:mm"
      value-type="format"
      type="time"
      :value="value"
      @input="handleDateTimePicker($event)"
      ref="autofocus"
    ></date-picker>

    <!-- timerange -->
    <date-picker
      v-else-if="input.type === 'timerange'"
      format="HH:mm"
      value-type="format"
      type="time"
      range
      :placeholder="input.placeholder || null"
      :value="value"
      @input="handleDateTimePicker($event)"
      ref="autofocus"
    ></date-picker>
    <!-- datetime -->
    <!-- <date-picker
      v-else-if="input.type === 'datetime'"
      value-type="format"
      format="YYYY-MM-DD HH:mm"
      type="datetime"
      :placeholder="input.placeholder || null"
      :value="value"
      @input="handleDateTimePicker($event)"
      ref="autofocus"
    ></date-picker> -->
    <input
      type="datetime-local"
      v-else-if="input.type === 'datetime'"
      :placeholder="input.placeholder || null"
      :value="valueDateTimeLocal"
      id="myLocalDate"
      min="1000-10-06T00:00:00"
      max="9999-11-16T00:00:00"
      @change="handleDateTimePicker($event, 'valueDateTimeLocal')"
      ref="autofocus"
      :class="input.class"
    />
    <!-- subventions / object_table -->
    <div
      class="subventions"
      v-else-if="input.type === 'subventions' || input.type === 'object_table'"
    >
      <div class="bold">LOGIN</div>
      <input
        type="text"
        :value="value.login"
        @input="handleInputStatus_login"
        @keyup.enter="handleKeyup"
        @keyup.esc="cancel"
        ref="autofocus"
      />
      <div class="bold">MOT DE PASSE</div>
      <input
        type="password"
        :value="value.mdp"
        @input="handleInputStatus_mdp"
        @keyup.enter="handleKeyup"
        @keyup.esc="cancel"
        ref="autofocus"
      />
      <div class="bold">STATUT</div>
      <select
        class="selectSubventions"
        :value="value.status"
        @input="handleInputStatus_status"
        @keyup.enter="handleKeyup"
        @keyup.esc="cancel"
        ref="autofocus"
      >
        <option
          v-for="statut in optionsStatus"
          :key="statut.value"
          :value="statut.label"
          >{{ statut.label }}</option
        ></select
      >
      <div class="bold">MONTANT DISPONIBLE</div>
      <input
        type="number"
        :value="value.montant_disponible"
        @input="handleInputStatus_montant"
        @keyup.enter="handleKeyup"
        @keyup.esc="cancel"
        ref="autofocus"
      />
    </div>

    <div v-else>
      <span>not supported</span>
    </div>
    <div v-if="!input.message">Aucun option</div>
    <!-- end input type if -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import multiselect from 'vue-multiselect'
import * as moment from 'moment'
moment.locale('fr')
/**
 * @Module Dynamic Input - all supported inputs
 * @param {Object} input - an input configuration
 * @param {String} input.type(Required) - The type of input supported inputtypes(text,email,password,hidden,tel,date,number,select,textarea)
 * @param {String} input.name(Required) - The name of the input field
 * @param {any} input.value(Optional) - The value of the input field
 * @param {String} input.label(Optional) - The label of the input field
 * @param {String} input.class(Optional) - The Css class(es) of the input field
 *
 * **Input specific parameters**
 * @param input.min - minimum value of the input field(date,number)
 * @param input.max - maximum value of the input field(date,number)
 * @param {Number} input.step - increments of the value on increase & decrease(number)
 * @param {Array} options - al the options in a selectbox
 */
export default {
  name: 'dynamic-input',
  components: {
    multiselect
  },
  props: {
    error: {
      required: false
    },
    input: {
      type: Object,
      required: true
    },
    value: {
      required: false
    },
    doubleValue: { required: false },
    formatDate: {
      required: false
    },
    classInput: {
      required: false
    },
    objectProject: { required: false },
    indexInput: { required: false },
    message: { required: false },
    sousCategory: { required: false },
    logistiqueTravaux: { required: false },
    typeIsolation: { required: false }
  },
  data() {
    return {
      test: '',
      login: this.value ? this.value.login : '',
      mdp: this.value ? this.value.mdp : '',
      montant_disponible: this.value ? this.value.montant_disponible : '',
      status: this.value ? this.value.status : '',
      optionsStatus: [
        { label: '', value: null },
        { label: 'Eligible', value: 'Eligible' },
        { label: 'Non éligible', value: 'Non éligible' }
      ],
      phoneTypes: ['Travail', 'Domicile', 'Mobile', 'Autre'],
      emailTypes: ['Personnel', 'Professionnel', 'Autre'],
      options: [],
      selectedOptions: false,
      valueOptionsAgence: null,
      valueOptions: null,
      valueOptionsAmo: null,
      valueDateTimeLocal: new Date(),
      hasRegroupementSelectkey_1: {},
      hasRegroupementSelectkey_2: {},
      hasRegroupementSelectkey_3: {},
      optionsRegroupementSelectAmo: [],
      optionsRegroupementSelectAgence: []
    }
  },
  methods: {
    ...mapActions(['fetchProjectRouteDynamic']),
    ajouterInputList() {
      if (this.input.name === 'emails') {
        this.options.push({ email: '', typeE: 'Personnel' })
      } else {
        this.options.push({ number: '', typeP: 'Travail' })
      }
    },
    removeInputList(index) {
      this.options.splice(index, 1)
    },
    handleInput(e) {
      this.$emit('input', e.target.value)
    },
    handleInputStatus_login(e) {
      this.login = e.target.value
      this.handleInputvalider()
    },
    handleInputStatus_montant(e) {
      this.montant_disponible = e.target.value
      this.handleInputvalider()
    },
    handleInputStatus_mdp(e) {
      this.mdp = e.target.value
      this.handleInputvalider()
    },
    handleInputStatus_status(e) {
      this.status = e.target.value
      this.handleInputvalider()
    },
    handleInputvalider() {
      const payload = {
        login: this.login,
        mdp: this.mdp,
        montant_disponible: this.montant_disponible,
        status: this.status
      }
      if (
        this.login != this.value.login ||
        this.status != this.value.status ||
        this.mdp != this.value.mdp ||
        this.montant_disponible != this.value.montant_disponible
      ) {
        this.$emit('input', payload)
      }
    },
    handleDateTimePicker(data, type) {
      if (type == 'valueDateTimeLocal') {
        this.valueDateTimeLocal = data.target.value
        this.$emit('input', data.target.value)
      } else {
        this.$emit('input', data)
      }
    },
    handleInputList(data, key, idx, name) {
      let tabValue = [...this.options]
      for (let index = 0; index < tabValue.length; index++) {
        if (index == idx) {
          if (name === 'phones') {
            if (key == 'numberPhone') {
              tabValue[index].number = data.target.value
                .replace(/[^0-9]/g, '')
                .replace(/(\..*)\./g, '$1')
            } else {
              tabValue[index].typeP = data
            }
            this.$emit('input', tabValue)
            tabValue = []
          }
          if (name === 'emails') {
            if (key == 'email') {
              tabValue[index].email = data.target.value
            } else {
              tabValue[index].typeE = data
            }
            this.$emit('input', tabValue)
            tabValue = []
          }
        }
      }
    },
    handleMultiselectEnum(data) {
      if (data) {
        this.$emit('input', data.label)
      }
    },
    handleMultiselectTable(data) {
      this.$emit('input', data.id)
    },
    handleRadio(data) {
      this.valueOptions = []
      let nbr = this.val++
      if (nbr % 2 == 0) {
        this.valueOptions = []
      } else {
        this.valueOptions = [data[data.length - 1]]
      }
      if (this.valueOptions && this.valueOptions.length) {
        this.$emit('input', this.valueOptions[0])
      } else {
        this.$emit('input', this.valueOptions)
      }
    },
    handleMultiselectSet(data) {
      this.valueOptions = data
      let dataMultipleSelect = ''
      if (data && data.length) {
        dataMultipleSelect = data.map(item => item.id)
        dataMultipleSelect = dataMultipleSelect.filter(data => data != null)
      } else {
        if (data && data.id) {
          dataMultipleSelect = data.id
        }
      }
      this.$emit('input', dataMultipleSelect)
    },
    async handleMultiselectSetAgenceAmo(data, name) {
      if (name == 'amo') {
        this.valueOptionsAmo = data
      } else if (name == 'agence' && data && data.id) {
        this.valueOptionsAgence = data
        this.valueOptionsAmo = null
        if (
          this.hasRegroupementSelectkey_3 &&
          this.hasRegroupementSelectkey_3.link &&
          this.hasRegroupementSelectkey_3.link.length
        ) {
          for (
            let index = 0;
            index < this.hasRegroupementSelectkey_3.link.length;
            index++
          ) {
            let route = this.hasRegroupementSelectkey_3.link[
              index
            ].value.replace('{depot}', data.id)

            await this.fetchProjectRouteDynamic(route)
          }
          this.optionsRegroupementSelectAmo = []
          if (
            this.getProjectRouteDynamic &&
            this.getProjectRouteDynamic.length
          ) {
            this.optionsRegroupementSelectAmo = this.getProjectRouteDynamic.map(
              t => {
                if (t.full_name) {
                  return { label: t.full_name, id: t.id }
                } else {
                  return { label: t.name, id: t.id }
                }
              }
            )
          }
        }
      } else if (name == 'filiale' && data && data.id) {
        this.valueOptions = data
        this.valueOptionsAmo = null
        this.valueOptionsAgence = null
        if (
          this.hasRegroupementSelectkey_2 &&
          this.hasRegroupementSelectkey_2.link &&
          this.hasRegroupementSelectkey_2.link.length
        ) {
          for (
            let index = 0;
            index < this.hasRegroupementSelectkey_2.link.length;
            index++
          ) {
            let route = this.hasRegroupementSelectkey_2.link[
              index
            ].value.replace('{team}', data.id)

            await this.fetchProjectRouteDynamic(route)
          }
          this.optionsRegroupementSelectAgence = []
          if (
            this.getProjectRouteDynamic &&
            this.getProjectRouteDynamic.length
          ) {
            this.optionsRegroupementSelectAgence = this.getProjectRouteDynamic.map(
              t => {
                if (t.full_name) {
                  return { label: t.full_name, id: t.id }
                } else {
                  return { label: t.name, id: t.id }
                }
              }
            )
          }
        }
      }
      this.$emit('input', {
        // filiale
        value_1:
          this.valueOptions && this.valueOptions.id
            ? this.valueOptions.id
            : this.valueOptions,
        key_1:
          this.hasRegroupementSelectkey_1 && this.hasRegroupementSelectkey_1.key
            ? this.hasRegroupementSelectkey_1.key
            : this.hasRegroupementSelectkey_1,
        //  agence
        value_2:
          this.valueOptionsAgence && this.valueOptionsAgence.id
            ? this.valueOptionsAgence.id
            : this.valueOptionsAgence,
        key_2:
          this.hasRegroupementSelectkey_2 && this.hasRegroupementSelectkey_2.key
            ? this.hasRegroupementSelectkey_2.key
            : this.hasRegroupementSelectkey_2,
        // amo
        value_3:
          this.valueOptionsAmo && this.valueOptionsAmo.id
            ? this.valueOptionsAmo.id
            : this.valueOptionsAmo,
        key_3:
          this.hasRegroupementSelectkey_3 && this.hasRegroupementSelectkey_3.key
            ? this.hasRegroupementSelectkey_3.key
            : this.hasRegroupementSelectkey_3
      })
    },
    handleKeyup(event) {
      this.$emit('save', event)
    },
    cancel(event) {
      this.$emit('cancel', event)
    }
  },
  computed: {
    ...mapGetters(['getProjectRouteDynamic', 'getRouteDynamicProcessing']),
    computedStyle() {
      if (this.input.indexInput == '2') {
        return {
          width: '50%',
          'text-align': 'left',
          'margin-left': '5px',
          'margin-right': '5px'
        }
      } else if (this.input.indexInput == '1') {
        return {
          width: '50%',
          'text-align': 'right',
          'margin-left': '5px',
          'margin-right': '5px'
        }
      } else {
        return { width: '100%', 'margin-left': '5px', 'margin-right': '5px' }
      }
    },
    computedEnumValue: function() {
      let value = null
      this.input.options.forEach(option => {
        if (option.label == this.value) {
          value = option
        }
      })
      return value
    },
    computedTableValue: function() {
      let value = null
      this.input.options.forEach(option => {
        if (option.id == this.value) {
          value = option
        }
      })
      return value
    },
    computedSetValue: function() {
      const value = []
      if (!this.value || !Array.isArray(this.value)) {
        return value
      }
      this.input.options.forEach(option => {
        if (this.value.find(v => v == option.label)) {
          value.push(option)
        }
      })
      return value
    }
  },
  filters: {
    time: function(value) {
      if (!value) return ''
      return moment(value, 'YYYY-MM-DD HH:mm:ss').format('LT DD MMM')
    }
  },
  async mounted() {
    if (this.input.type == 'datetime' && this.value) {
      this.valueDateTimeLocal = moment(
        this.value && this.value.target && this.value.target.value
          ? this.value.target.value
          : this.value,
        'YYYY-MM-DD HH:mm'
      ).format('YYYY-MM-DDTHH:mm')
    }
    // Project RENO Global + CPF
    if (this.input.name === 'phones' || this.input.name === 'emails') {
      if (!this.value || !this.value.length) {
        if (this.input.name === 'phones') {
          this.options = [{ number: '', typeP: 'Travail' }]
        } else {
          this.options = [{ email: '', typeE: 'Personnel' }]
        }
      }
      if (this.value && this.value.length) {
        this.options = this.value
      }
    }
    if (this.input.selected) {
      if (this.input.selected == 'one') {
        this.selectedOptions = false
      } else {
        this.selectedOptions = true
      }
    }

    if (this.input.links && this.input.links.length) {
      for (let index = 0; index < this.input.links.length; index++) {
        await this.fetchProjectRouteDynamic(this.input.links[index].value)
      }
      if (this.getProjectRouteDynamic && this.getProjectRouteDynamic.length) {
        this.options = this.getProjectRouteDynamic
          .map(t => {
            if (t.full_name) {
              return { label: t.full_name, id: t.id }
            } else {
              return { label: t.name, id: t.id }
            }
          })
          .sort(function compare(a, b) {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1
            return 0
          })
        if (this.value) {
          this.valueOptions = this.options.find(e => {
            return e.id == this.value.id
          })
        }
      }
    }
    if (this.input.hasRegroupementSelect == 1) {
      let tabHasRegroupement = []
      let tabFields = []
      for (
        let index = 0;
        index < this.input.sousCategory.fields.length;
        index++
      ) {
        if (this.input.sousCategory.fields[index].has_regroupement == 1) {
          tabFields.push(this.input.sousCategory.fields[index])
        }
      }
      if (tabFields && tabFields.length) {
        for (let index_1 = 0; index_1 < tabFields.length; index_1++) {
          if (
            tabFields[index_1].logistique_travaux ==
              this.input.logistiqueTravaux &&
            tabFields[index_1].type_isolation == null
          ) {
            tabHasRegroupement.push(tabFields[index_1])
          } else if (
            tabFields[index_1].logistique_travaux ==
              this.input.logistiqueTravaux &&
            tabFields[index_1].type_isolation == this.input.typeIsolation
          ) {
            tabHasRegroupement.push(tabFields[index_1])
          }
        }
      }
      // Appele route dynamique + tabHasRegroupement contient 2 object
      if (tabHasRegroupement && tabHasRegroupement.length) {
        if (tabHasRegroupement[0].value && tabHasRegroupement[0].value.id) {
          if (
            tabHasRegroupement[1].links &&
            tabHasRegroupement[1].links.length
          ) {
            for (
              let index = 0;
              index < tabHasRegroupement[1].links.length;
              index++
            ) {
              let route = tabHasRegroupement[1].links[index].value.replace(
                '{team}',
                tabHasRegroupement[0].value && tabHasRegroupement[0].value.id
                  ? tabHasRegroupement[0].value.id
                  : tabHasRegroupement[0].value
              )

              await this.fetchProjectRouteDynamic(route)
            }
          }
          this.optionsRegroupementSelectAgence = []
          if (
            this.getProjectRouteDynamic &&
            this.getProjectRouteDynamic.length
          ) {
            this.optionsRegroupementSelectAgence = this.getProjectRouteDynamic.map(
              t => {
                if (t.full_name) {
                  return { label: t.full_name, id: t.id }
                } else {
                  return { label: t.name, id: t.id }
                }
              }
            )
          }
        }
        if (tabHasRegroupement[1].value && tabHasRegroupement[1].value.id) {
          if (
            tabHasRegroupement[2].links &&
            tabHasRegroupement[2].links.length
          ) {
            for (
              let index = 0;
              index < tabHasRegroupement[2].links.length;
              index++
            ) {
              let route = tabHasRegroupement[2].links[index].value.replace(
                '{depot}',
                tabHasRegroupement[1].value && tabHasRegroupement[1].value.id
                  ? tabHasRegroupement[1].value.id
                  : tabHasRegroupement[1].value
              )

              await this.fetchProjectRouteDynamic(route)
            }
          }
          this.optionsRegroupementSelectAmo = []
          if (
            this.getProjectRouteDynamic &&
            this.getProjectRouteDynamic.length
          ) {
            this.optionsRegroupementSelectAmo = this.getProjectRouteDynamic.map(
              t => {
                if (t.full_name) {
                  return { label: t.full_name, id: t.id }
                } else {
                  return { label: t.name, id: t.id }
                }
              }
            )
          }
        }
        //  Value select one
        this.valueOptions = {
          label:
            tabHasRegroupement[0].value && tabHasRegroupement[0].value.name
              ? tabHasRegroupement[0].value.name
              : tabHasRegroupement[0].value,
          id:
            tabHasRegroupement[0].value && tabHasRegroupement[0].value.id
              ? tabHasRegroupement[0].value.id
              : tabHasRegroupement[0].value
        }
        //  Value select Two
        this.valueOptionsAgence = {
          label:
            tabHasRegroupement[1].value && tabHasRegroupement[1].value.name
              ? tabHasRegroupement[1].value.name
              : tabHasRegroupement[1].value,
          id:
            tabHasRegroupement[1].value && tabHasRegroupement[1].value.id
              ? tabHasRegroupement[1].value.id
              : tabHasRegroupement[1].value
        }
        //  Value select three
        this.valueOptionsAmo = {
          label:
            tabHasRegroupement[2].value && tabHasRegroupement[2].value.name
              ? tabHasRegroupement[2].value.name
              : tabHasRegroupement[2].value,
          id:
            tabHasRegroupement[2].value && tabHasRegroupement[2].value.id
              ? tabHasRegroupement[2].value.id
              : tabHasRegroupement[2].value
        }
        this.hasRegroupementSelectkey_1 = {
          name: tabHasRegroupement[0].name ? tabHasRegroupement[0].name : '',
          key: tabHasRegroupement[0].key,
          link: tabHasRegroupement[0].links
        }
        this.hasRegroupementSelectkey_2 = {
          name: tabHasRegroupement[1].name ? tabHasRegroupement[1].name : '',
          key: tabHasRegroupement[1].key,
          link: tabHasRegroupement[1].links
        }
        this.hasRegroupementSelectkey_3 = {
          name: tabHasRegroupement[2].name ? tabHasRegroupement[2].name : '',
          key: tabHasRegroupement[2].key,
          link: tabHasRegroupement[2].links
        }
      }
    }
    if (
      (this.input.type === 'radio_oui_non' || this.input.type === 'radio') &&
      this.input.valuePossible &&
      this.input.valuePossible.length
    ) {
      this.options = this.input.valuePossible
        .map(t => {
          return {
            text: t.name,
            value: t.id
          }
        })
        .sort(function compare(a, b) {
          if (a.text < b.text) return -1
          if (a.text > b.text) return 1
          return 0
        })
      this.valueOptions = [this.value]
    }
    if (this.input.type === 'radio_oui_non') {
      this.options = this.input.options.map(t => {
        return {
          text: t.name,
          value: t.id
        }
      })
      this.valueOptions = this.value == 1 || this.value == 'Oui' ? [1] : [0]
    }
    if (
      this.input.valuePossible &&
      this.input.valuePossible.length &&
      this.input.type != 'radio_oui_non' &&
      this.input.type != 'radio'
    ) {
      this.options =
        this.input.name == 'num_maison'
          ? this.input.valuePossible.map(t => {
              return {
                label: t.name,
                id: t.id
              }
            })
          : this.input.valuePossible
              .map(t => {
                return {
                  label: t.name,
                  id: t.id
                }
              })
              .sort(function compare(a, b) {
                if (a.label < b.label) return -1
                if (a.label > b.label) return 1
                return 0
              })
      if (this.input.selected == 'multiple') {
        //multilpe
        let arrayOfValues = []
        this.valueOptions = this.options.filter(t => {
          if (this.value && this.value.length) {
            this.value.map(item => {
              if (item == t.label) {
                arrayOfValues.push({
                  label: item,
                  id: item
                })
              }
            })
          }
        })
        this.valueOptions = arrayOfValues
      } else {
        //one
        this.valueOptions = this.options.find(t => {
          return t.label == this.value
        })
      }
    }
    if (this.$refs.autofocus) {
      this.$refs.autofocus.focus()
    }
  }
}
</script>

<style lang="scss">
.dynamic-input-wrapper {
  .input-list {
    border: 1px solid #e5e5e5;
    // width: 100%;
  }
  .list-element {
    display: flex;
    align-items: center;
    .value-input {
      flex-shrink: 2;
      box-shadow: unset;
      border: 1px solid #e5e5e5;
      height: 26px;
      text-overflow: ellipsis;
      width: 105px;
    }
    .value-type {
      flex-shrink: 3;
      box-shadow: unset;
      border: 0px solid #e5e5e5;
      width: 130px;
      font-size: 16px;
    }
    .action {
      cursor: pointer;
    }
  }
  .add-to-list-action {
    color: #2dabe2;
    cursor: pointer;
    width: max-content;
    font-weight: bold;
    margin-left: 18px;
    .icon-plus {
      font-size: 10px;
    }
    &.disabled {
      color: #ced4da;
      cursor: default;
    }
  }
  .multiselect {
    width: 186px;
    height: 26px;
    min-height: 26px;

    &.set {
      height: auto;
    }

    .multiselect__select {
      height: 26px;
    }
    .multiselect__tags {
      min-height: 26px;
      padding: 2px 40px 0 8px;
      border: 1px solid #e5e5e5;
      border-radius: 2px;
      font-size: 12px;
      .multiselect__tags-wrap {
        .multiselect__tag {
          padding: 5px 20px 5px 10px;
          border-radius: 3px;
          margin-right: 4px;
          margin-bottom: 0;
          line-height: 12px;
        }
        .multiselect__strong {
          margin-bottom: 0px;
          line-height: 12px;
        }
      }

      .multiselect__placeholder,
      .multiselect__single {
        margin-bottom: 0;
        padding-top: 0;
        font-size: 12px;
      }
      .multiselect__input,
      .multiselect__single {
        max-height: 25px;
        background: transparent;
        width: 212%;
      }
    }
    .multiselect__spinner {
      position: absolute;
      right: 1px;
      top: 1px;
      width: 48px;
      height: 22px;
      background: #fff;
      display: block;
    }
    .multiselect__content-wrapper {
      bottom: unset;
      position: relative;
      .multiselect__content {
        .multiselect__element {
          .multiselect__option {
            padding: 6px 12px;
            min-height: 30px;
          }
        }
      }
    }
  }
  .radio-style-input {
    .custom-checkbox .custom-control-label::before {
      border-radius: 1.25rem;
    }
  }
  .radio-oui-non {
    .custom-control-label {
      position: relative;
      margin-bottom: 0;
      vertical-align: top;
      padding-top: 4px;
    }
  }
  .input-comments-content {
    .anciene-comment::-webkit-scrollbar {
      height: 7px;
      width: 7px;
    }
    .anciene-comment::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #80808054;
      border-radius: 10px;
    }
    .anciene-comment::-webkit-scrollbar-thumb {
      background: #dcdee0;
      border-radius: 10px;
    }
    .anciene-comment::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
    .anciene-comment {
      white-space: normal;
      max-height: 116px;
      overflow-y: auto;
      overflow-x: hidden;
      width: 156%;
      margin-right: 5px;
      .content-created-note {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .user {
          font-weight: bold;
          font-size: 11px;
        }
        .icon-delete-note {
          font-size: 15px;
          color: #ef6f64;
          margin-right: 3px;
          &:hover {
            color: #f22d1d;
          }
        }
      }
      .date {
        color: #9e9e9e;
        font-weight: bold;
        font-size: 9px;
      }
    }
    .line-note {
      margin-top: 6px;
      margin-bottom: 4px;
      width: 97%;
    }
  }
  .select-search {
    height: auto;
    // width: 130%;
    width: 100%;
    // margin-left: -2px;
    font-size: 11px;
    // width: 97px;
    // height: 26px;
    // border-radius: 0px;
    // min-height: 26px;
    .multiselect__select {
      height: 20px;
      right: -7px;
    }
    .multiselect__content {
      max-height: 82px;
      list-style: none;
      display: inline-block;
      padding: 0;
      margin: 0;
      min-width: 100%;
      vertical-align: top;
    }
  }
  .select-search-date-select {
    height: auto;
    width: 100%;
    font-size: 11px;
    .multiselect__select {
      height: 20px;
      right: -7px;
    }
    .multiselect__content-wrapper {
      .multiselect__content {
        .multiselect__element {
          .multiselect__option {
            padding: 6px 12px;
            min-height: 25px;
          }
        }
      }
    }
    .multiselect__content {
      max-height: 46px;
      list-style: none;
      display: inline-block;
      padding: 0;
      margin: 0;
      min-width: 100%;
      vertical-align: top;
      .multiselect__option {
        display: block;
        padding: 12px;
        min-height: 40px;
        line-height: 12px;
        text-decoration: none;
        text-transform: none;
        position: relative;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
  .select-search-client {
    height: auto;
    width: 111.36px;
    font-size: 11px;
  }
  .select-search-tootltip {
    height: auto;
    width: 100%;
    font-size: 11px;
  }
  .select-search-client-border {
    height: auto;
    width: 98%;
    font-size: 11px;
  }
  .select-date {
    width: 100%;
  }
  .double-select-date {
    .not-style {
      width: 0;
      margin-left: 0;
      margin-right: 0;
    }
    &.mx-datepicker {
      width: 107px;
      .mx-input-wrapper {
        .mx-input {
          width: 111px;
          height: 26px;
          border: 1px solid #e5e5e5;
          border-radius: 2px;
          padding: 6px 28px;
          padding-left: 10px;
        }
        .mx-icon-calendar {
          position: absolute;
          top: 50%;
          right: 3px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 12px;
          line-height: 1;
          color: rgba(0, 0, 0, 0.5);
          vertical-align: middle;
        }
        .mx-icon-clear {
          position: absolute;
          top: 50%;
          right: 3px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 16px;
          line-height: 1;
          color: rgba(0, 0, 0, 0.5);
          vertical-align: middle;
        }
      }
    }
  }
  .mx-datepicker {
    width: 136px;
    .mx-input-wrapper {
      .mx-input {
        width: 166px;
        height: 26px;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        padding: 6px 28px;
        padding-left: 10px;
      }
    }
    &.date-picker-range {
      .mx-input-wrapper {
        .mx-input {
          font-size: 11px;
        }
      }
    }
    &.date-input {
      .mx-input-wrapper {
        .mx-input {
          width: 100%;
          /* height: 26px; */
          border: 1px solid #e5e5e5;
          border-radius: 0px;
          font-size: 10px;
          // margin-right: 115px;
          /* padding: 0px; */
          /* padding-left: 0px;*/
        }
        .mx-icon-calendar {
          position: absolute;
          top: 50%;
          right: 7px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 12px;
          line-height: 1;
          color: rgba(0, 0, 0, 0.5);
          vertical-align: middle;
        }
        .mx-icon-clear {
          position: absolute;
          top: 50%;
          right: 7px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 16px;
          line-height: 1;
          color: rgba(0, 0, 0, 0.5);
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.dynamic-input-wrapper {
  width: 100%;
  .content-amo-agence-flex {
    margin-top: 50px;
    margin-left: -73px;
    .flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 125%;
      justify-content: flex-end;
      /* margin-top: 10px; */
      margin-bottom: 10px;
    }
    .name {
      // margin-right: 15px;
      font-size: 12px;
      font-weight: bold;
    }
    .input {
      width: 55%;
      margin-left: 25px;
    }
  }
  .error {
    float: right;
  }
  .text-input {
    width: 100%;
    height: 25px;
  }
  .text-input-double {
    // width: 50%;
    height: 25px;
  }
  .input-comments-content {
    margin-left: 0px;
    margin-top: 2px;
    textarea {
      line-height: 1rem;
      font-size: 0.75rem;
      margin-left: -3px;
      margin-top: 3px;
      width: 158%;
      max-width: 260px;
      resize: vertical;
      border: 1px solid #e5e5e5;
      outline: unset;
      margin-bottom: 5px;
      height: 55px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  // .input-double-1 {
  //   margin-left: -2px;
  // }
  // .input-double-2 {
  //   margin-left: 5px;
  // }
  .tooltip-input {
    border: 1px solid #e5e5e5;
    width: 100%;
    height: 25px;
    margin-left: 6px;
  }
  .text-input-popUp {
    border: 1px solid #e5e5e5;
    // margin-left: -1px;
    width: 100%;
    text-align: center;
    height: 25px;
  }
  .text-input-popUp-icon {
    border: 1px solid #e5e5e5;
    margin-right: 40px;
    width: 100%;
    text-align: center;
    height: 25px;
  }
  .text-input-total-ht {
    width: 140%;
    height: 25px;
    margin-left: -10px;
  }

  textarea {
    width: 166px;
    max-width: 166px;
    resize: vertical;
    border: 1px solid #e5e5e5;
    outline: unset;
  }
  input {
    border: 1px solid #e5e5e5;
    width: 112%;
  }
  .subventions {
    .selectSubventions {
      width: 100%;
      height: 27px;
      border: 1px solid #e5e5e5;
      outline: unset;
    }
  }
}
</style>
