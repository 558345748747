var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.getCategorieLoading &&
        (!_vm.computedCategoriesType || !_vm.computedCategoriesType.length)
    )?_c('div',{staticClass:"init-loading three-dots-loading"},[_vm._v(" Chargement en cours ")]):(_vm.computedCategoriesType && _vm.computedCategoriesType.length)?_c('b-tabs',{staticClass:"files-tabs",attrs:{"lazy":""}},_vm._l((_vm.computedCategoriesType),function(categorie,index){return _c('b-tab',{key:'categorie' + index,attrs:{"no-body":"","title-link-class":_vm.linkClass(categorie)},on:{"click":function($event){return _vm.clickCateg(categorie)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(
            ("Sous catégorie : " + (categorie.subCategorie && categorie.subCategorie.length
                ? categorie.subCategorie.length
                : 0) + " / Fichiers : " + (_vm.getCounterFilesSubCategoriesPieceJointe &&
              _vm.getCounterFilesSubCategoriesPieceJointe.length &&
              _vm.getCounterFilesSubCategoriesPieceJointe[index].id ==
                categorie.id
                ? _vm.getCounterFilesSubCategoriesPieceJointe[index].count_files
                : 0))
          ),expression:"\n            `Sous catégorie : ${\n              categorie.subCategorie && categorie.subCategorie.length\n                ? categorie.subCategorie.length\n                : 0\n            } / Fichiers : ${\n              getCounterFilesSubCategoriesPieceJointe &&\n              getCounterFilesSubCategoriesPieceJointe.length &&\n              getCounterFilesSubCategoriesPieceJointe[index].id ==\n                categorie.id\n                ? getCounterFilesSubCategoriesPieceJointe[index].count_files\n                : 0\n            }`\n          ",modifiers:{"top-center":true}}]},[(categorie.name != 'Voir plus...')?_c('span',[_vm._v(" "+_vm._s(categorie.name))]):_vm._e(),(
              categorie.name != 'Tous' && categorie.name != 'Voir plus...'
            )?_c('span',{staticClass:"counter"},[_vm._v(" "+_vm._s(categorie.subCategorie && categorie.subCategorie.length ? categorie.subCategorie.length : 0)+" ")]):_vm._e()]),(categorie.name == 'Voir plus...')?_c('div',{on:{"click":_vm.addCategorie}},[_vm._v(" Voir plus... ")]):_vm._e()]},proxy:true}],null,true)})}),1):_vm._e(),(
      (!_vm.computedCategoriesType || !_vm.computedCategoriesType.length) &&
        !_vm.getCategorieLoading
    )?_c('div',{staticClass:"aucun-subCategory"},[_vm._v(" Aucun catégorie pièce jointe ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }