<template>
  <div class="activity-form">
    <form @submit.prevent="handleActivityUpdateForm">
      <div class="activity-title">
        <b-form-input v-model.trim="activity.name"></b-form-input>
      </div>
      <div class="activity-type">
        <!-- <b-form-group>
          <div class="activity-types-tabs flex justify-between bold">
            <div
              class="activity-types-tab"
              :class="{ active: currentActivityType == 1 }"
            >
              <font-awesome-icon class="mr-1" icon="phone-alt" />Appel
            </div>

            <div
              class="activity-types-tab"
              :class="{ active: currentActivityType == 2 }"
            >
              <font-awesome-icon class="mr-1" icon="briefcase" />Pré-visite
            </div>
            <div
              class="activity-types-tab"
              :class="{ active: currentActivityType == 3 }"
            >
              <font-awesome-icon class="mr-1" icon="truck" />Planification
            </div>
            <div
              class="activity-types-tab"
              :class="{ active: currentActivityType == 4 }"
            >
              <font-awesome-icon class="mr-1" icon="hourglass-start" />Autre
            </div>
          </div>
        </b-form-group> -->
      </div>
      <div class="activity-date mt-3">
        <label>
          {{
            currentActivityType === 3
              ? 'Suggestions des rendez-vous :'
              : 'Dates :'
          }}
        </label>
        <div v-if="currentActivityType === 3" class="diponibility">
          <div
            class="three-dots-loading"
            v-if="getProjectsSuggestionsProcessing"
          >
            Chargement en cours
          </div>
          <template v-else>
            <template v-if="planificationSuggestions">
              <div class="planning-description italic">
                <span class="planning-description--label bold">
                  Estimation de durée de pose :
                </span>
                <span class="planning-description--value">
                  {{ planificationSuggestions.pose_time_minutes | duration }}
                </span>
              </div>
              <div class="suggestions-list mt-2 ml-2">
                <label
                  class="suggestion"
                  v-if="activity.type == 'Planning'"
                  :class="{
                    active: selectedSuggestion == activity.date_start
                  }"
                  for="radio-suggest-update-selected"
                >
                  <input
                    type="radio"
                    name="radio-suggest"
                    id="radio-suggest-update-selected"
                    :value="selectedSuggestion | date('YYYY-MM-DD HH:mm:ss')"
                    v-model="activity.date_start"
                  />
                  <div class="suggestion-container">
                    <div class="date-flag">
                      <font-awesome-icon icon="calendar-day" />
                    </div>
                    <div class="date-details">
                      <span class="capitalize">
                        {{ selectedSuggestion | date('ddd DD MMM') }}
                      </span>
                      à
                      {{ selectedSuggestion | time('HH:mm') }}
                    </div>
                  </div>
                </label>
              </div>
              <Collapse
                v-for="(week, week_index) in planificationSuggestions.weeks"
                :key="'planification-suggestions-update-' + week.week_id"
                :init="week_index == 0 ? true : false"
                :selected="collapseSuggestions"
                @toggle="collapseSuggestions = $event"
              >
                <template #title>
                  {{
                    week_index == 0
                      ? `Semaine en cours (N°${week.week_nbr})`
                      : week_index == 1
                      ? `Semaine prochaine (N°${week.week_nbr})`
                      : `Semaine (N°${week.week_nbr})`
                  }}
                </template>
                <template #body>
                  <div class="suggestions-list">
                    <label
                      class="suggestion"
                      v-for="(date, index) in week.available_dates_hours_techs"
                      :key="'available-dates-hours-techs-' + index"
                      :for="`radio-suggest-update-${week_index}-${index}`"
                      :class="{
                        active:
                          activity.date_start == `${date.day} ${date.hour}:00`
                      }"
                    >
                      <input
                        :id="`radio-suggest-update-${week_index}-${index}`"
                        type="radio"
                        name="radio-suggest"
                        :value="`${date.day} ${date.hour}:00`"
                        v-model="activity.date_start"
                      />
                      <div class="suggestion-container">
                        <div class="date-flag">
                          <font-awesome-icon icon="calendar-day" />
                        </div>
                        <div class="date-details">
                          <span class="capitalize">
                            {{ date.day | date('ddd DD MMM') }}
                          </span>
                          à
                          {{ date.hour }}
                        </div>
                      </div>
                    </label>
                  </div>
                </template>
              </Collapse>
            </template>
          </template>
        </div>
        <div v-else class="activity-date-interval">
          <date-picker
            v-model="activity.date_start"
            :value="activity.date_start"
            value-type="format"
            format="YYYY-MM-DD HH:mm"
            type="datetime"
            placeholder="Choisir date de début"
            required
          ></date-picker>
          <b-form-checkbox
            v-if="activity.type == 'Appel' || activity.type == 'Autre'"
            class="checkbox-terminer"
            v-model="activity.terminer"
            :value="true"
            :unchecked-value="false"
          >
            Activité terminée
          </b-form-checkbox>
        </div>
      </div>
      <div
        class="activity-me-pevenir mt-3"
        v-if="
          !activity.terminer &&
            (activity.type == 'Appel' || activity.type == 'Autre')
        "
      >
        <label> Me prévenir avant : </label><br />
        <date-picker
          format="HH:mm"
          v-model="activity.me_prevenir"
          :value="activity.me_prevenir"
          value-type="format"
          type="time"
          placeholder="HH:mm"
        ></date-picker>
      </div>
      <!--"Stade" -->
      <b-form-group class="mt-2" label="Stade" label-for="stage-input">
        <Multiselect
          v-model="activity.cstage"
          @input="handleStageChange"
          id="stage-input"
          label="name"
          track-by="id"
          :showLabels="false"
          :closeOnSelect="true"
          :allow-empty="true"
          :options="
            [{ id: -1, name: 'auccun' }, ...currentStages] || [
              { id: -1, name: 'auccun' }
            ]
          "
          :searchable="true"
          :internal-search="true"
          placeholder="-- aucun --"
          required
        >
          <span slot="noResult">Aucun stade trouvée.</span>
          <span slot="noOptions">Aucun stade trouvée.</span>
        </Multiselect>
      </b-form-group>
      <!--"Sous-stade" -->

      <b-form-group class="mt-2" label="Sous-stade" label-for="sub-stage-input">
        <Multiselect
          v-model="activity.csubstage"
          id="sub-stage-input"
          label="name"
          track-by="id"
          :showLabels="false"
          :closeOnSelect="true"
          :allow-empty="true"
          :options="
            [{ id: -1, name: 'auccun' }, ...currentActivitySubStages] || [
              { id: -1, name: 'auccun' }
            ]
          "
          :searchable="true"
          :internal-search="true"
          placeholder="-- aucun --"
        >
          <span slot="noResult">Aucun sous-stade trouvée.</span>
          <span slot="noOptions">Aucun sous-stade trouvée.</span>
        </Multiselect>
      </b-form-group>
      <div class="activity-description mt-3">
        <label>
          Description :
        </label>
        <e-editor v-model="activity.description" />
      </div>
      <div class="activity-user mt-3">
        <label>
          Affectée à :
        </label>
        <b-form-group>
          <Multiselect
            v-model="activity.user"
            :value="activity.user"
            label="text"
            track-by="value"
            :showLabels="false"
            :closeOnSelect="true"
            open-direction="bottom"
            :allow-empty="false"
            :options="activityUsersList"
            :searchable="true"
            :internal-search="true"
            placeholder="Rechercher"
            :loading="getProjectsUsersLoading"
            required
          >
            <span slot="noResult">Aucun utilisateur trouvé.</span>
            <span slot="noOptions">Aucun utilisateur trouvé.</span>
          </Multiselect>
        </b-form-group>
      </div>
      <div class="error" v-if="activityToAddError">
        {{ activityToAddError }}
      </div>
    </form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Collapse from '@/views/Projects/Collapse'
import { mapGetters, mapActions } from 'vuex'
import EEditor from '@/views/Projects/EEditor'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
moment.locale('fr')
export default {
  components: {
    Multiselect,
    Collapse,
    EEditor
  },
  props: {
    data: {
      default: null
    },
    activity: { required: true },
    project: { required: false },
    type: {
      required: true
    }
  },
  data() {
    return {
      currentActivityType: null,
      activityToAddError: null,
      planificationSuggestions: null,
      preVisitSuggestions: null,
      activityTypes: ['Appel', 'Pré visite', 'Planning', 'Autre'],
      selectedSuggestion: this.activity.date_start,
      collapseSuggestions: null
    }
  },
  methods: {
    ...mapActions([
      'fetchPreVisitDatesSuggestions',
      'fetchPlanningDatesSuggestions',
      'createActivity'
    ]),
    handleActivityUpdateForm() {},
    handleStageChange() {
      this.activity.csubstage = { id: -1, name: 'auccun' }
      // this.activity.cstage = {id : this.activity.id , name :this.activity.name}
      // this.resetMessages()
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsSuggestionsProcessing',
      'getProjectsUsers',
      'getProjectsUsersLoading',
      'getProjectsUsersTechniciens',
      'getProjectsUsersCommercialsIte',
      'getPipelinesPipelines'
    ]),
    currentActivitySubStages: function() {
      if (!this.activity.cstage) {
        return []
      }
      if (
        this.activity.cstage.subStages &&
        this.activity.cstage.subStages.length
      ) {
        return this.activity.cstage.subStages
      }
      return []
    },
    currentStages: function() {
      if (this.getPipelinesPipelines.length) {
        for (let x = 0; x < this.getPipelinesPipelines.length; x++) {
          if (
            this.type &&
            this.type.id &&
            this.getPipelinesPipelines[x] &&
            this.getPipelinesPipelines[x].type &&
            this.getPipelinesPipelines[x].type.id &&
            this.getPipelinesPipelines[x].type.id == this.type.id &&
            this.getPipelinesPipelines[x].is_principal &&
            this.getPipelinesPipelines[x].is_principal == 1
          ) {
            return this.getPipelinesPipelines[x].stages
          }
        }
      }
      return null
    },
    computedPoseurs: function() {
      return this.getProjectsUsersTechniciens.map(u => {
        return {
          text: u.full_name,
          value: u.id
        }
      })
    },
    computedCommercialsItinerants: function() {
      return this.getProjectsUsersCommercialsIte.map(u => {
        return {
          text: u.full_name,
          value: u.id
        }
      })
    },
    activityUsersList: function() {
      if (this.activity.type == 'Pré visite') {
        return this.computedCommercialsItinerants
      } else if (this.activity.type == 'Planning') {
        return this.computedPoseurs
      } else {
        return this.getProjectsUsers.map(u => {
          return {
            text: u.full_name,
            value: u.id
          }
        })
      }
    }
  },
  filters: {
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return '--:--'
      return moment(value).format(format)
    },
    duration: function(value) {
      if (value === null || value === undefined) return ''
      if (!value) return '00h00'
      if (!Number.isInteger(value)) {
        value = parseInt(value)
      }
      if (value < 60) return '00h' + value
      value = moment.duration(value, 'minutes').format('HH[h]mm')
      return value
    },
    datetime: function(value, format = 'YYYY-DD-MM HH:mm:ss') {
      if (!value) return ''
      return moment(value, 'YYYY-MM-DD H').format(format)
    },
    key: value => {
      switch (value) {
        case 'status':
          return 'Statut'
        case 'cstage_id':
          return 'Stade'
        case 'csubstage_id':
          return 'Sous-stade'
        default:
          return ''
      }
    }
  },
  async mounted() {
    if (this.activity.terminer) {
      this.activity.terminer = true
    } else {
      this.activity.terminer = false
    }
    this.activity.user = {
      ...this.activity.user,
      value: this.activity.user.id,
      text: this.activity.user.full_name
    }
    this.activity.cstage = {
      ...this.activity.cstage,
      id: this.activity.cstage ? this.activity.cstage.id : -1,
      name: this.activity.cstage ? this.activity.cstage.name : 'auccun'
    }
    this.activity.csubstage = {
      ...this.activity.csubstage,
      id: this.activity.csubstage ? this.activity.csubstage.id : -1,
      name: this.activity.csubstage ? this.activity.csubstage.name : 'auccun'
    }
    if (this.activity.type == 'Appel') {
      this.currentActivityType = 1
    } else if (this.activity.type == 'Pré visite') {
      this.currentActivityType = 2
    } else if (this.activity.type == 'Planning') {
      this.currentActivityType = 3
      this.planificationSuggestions = await this.fetchPlanningDatesSuggestions({
        zipCode: this.project.zipCode,
        s: this.project.surface_souffle || 0,
        d: this.project.surface_deroule || 0,
        r: this.project.surface_rampant || 0,
        m: this.project.surface_mur || 0,
        p: this.project.surface_plafond || 0,
        vs: this.project.surface_vide_sanitaire || 0
      })
    } else {
      this.currentActivityType = 4
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-form {
  padding: 10px;
  font-size: 13px;
  .activity-type {
    .activity-types-tabs {
      margin-top: 5px;
      .activity-types-tab {
        white-space: nowrap;
        padding: 4px 10px;
        width: 34%;
        text-align: center;
        user-select: none;
        cursor: pointer;
        background-color: #ebebeb;
        border: 2px solid #d5d5d5;
        color: #5e5e5e;
        &.active {
          background-color: #fff;
        }
      }
    }
  }
  .diponibility {
    display: block;
    .planning-description {
      margin-left: 10px;
      font-size: 12px;
    }
    .suggestions-list {
      display: flex;
      flex-wrap: wrap;
      .suggestion {
        display: block;
        background: #e5e5e5;
        border: 1px solid #d5d5d5;
        padding: 2px 5px;
        margin-right: 10px;
        border-radius: 3px;
        cursor: pointer;
        transition: background 0.5s;
        width: 150px;

        .suggestion-container {
          display: flex;
          align-items: center;

          .date-flag {
            position: relative;
            margin-right: 4px;
            font-size: 14px;
          }
          .date-details {
            font-size: 12px;
          }
        }

        &:hover {
          background: #d5d5d5;
        }

        input {
          display: none;
        }

        &.active {
          background: #b0f2b6;
          border: 1px solid #8fca95;
        }
      }
    }
  }
}

.close {
  position: absolute;
  font-size: 20px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.error {
  color: #ff6961;
}
.flex {
  display: flex;
}
.bold {
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.text-right {
  text-align: right;
}
.justify-between {
  justify-content: space-between;
}
.capitalize {
  text-transform: capitalize;
}
.activity-date-interval {
  display: flex;
  .checkbox-terminer {
    margin-left: 40px;
  }
}
label {
  margin-bottom: 6px;
}
</style>
