<template>
  <b-tabs class="sous-types-tabs" lazy v-model="tabIndex">
    <b-tab
      no-body
      title-link-class="sous-types-title"
      :title-item-class="clickTab(subtypes, index)"
      v-for="(subtypes, index) in subtypes"
      :key="index"
    >
      <template #title>
        <div class="sous-type">{{ subtypes.name }}</div>
        <div v-if="subtypes.is_tagged == 1" class="counter"></div>
      </template>
    </b-tab>
  </b-tabs>
</template>

<script>
export default {
  name: 'Files',
  props: {
    subtypes: {
      required: true
    },
    readonly: {
      default: false
    }
  },
  data() {
    return {
      tabIndex: 0,
      subtypeName: null
    }
  },
  methods: {
    clickTab(subtype, index) {
      if (index == this.tabIndex) {
        this.subtypeName = subtype.name
        this.$emit('sousType', { name: this.subtypeName, id: subtype.id })
      }
    }
  }
}
</script>

<style lang="scss">
.sous-types-tabs {
  .nav-tabs {
    justify-content: center;
    border-radius: 5px;
    padding: 22px 0px;
    margin: 0px;
    border: none;
    .sous-types-title {
      position: relative;
      font-size: 12px;
      color: #404346;
      background-color: #e5e5e5;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      padding: 3px 4px;
      margin: 2px 4px;
      font-weight: bold;
      user-select: none;
      transition: all 0.4s ease;
      justify-content: space-between;
      display: flex;
      align-items: flex-end;
      .counter {
        color: #404346;
        background-color: #26292c;
        width: 5px;
        height: 5px;
        border: 1px solid;
        margin-right: 0px;
        margin-left: 3px;
      }
      &.active {
        color: #5e5e5e;
        background-color: #d9cfeb;
        // .sous-type {
        //   // margin-right: 6px;
        //   // margin-left: 6px;
        // }
      }
    }
  }
}
</style>
