<template>
  <div class="project-modal">
    <div class="project-modal-wrapper">
      <div
        class="loader"
        :class="{
          on: getProjectsProcessing || getContactsProcessing,
          'left-loader': openFormLeadsRapide
        }"
      ></div>
      <!-- MODAL LEFT DE PROJET  -->
      <div class="left-page" v-if="!openFormLeadsRapide">
        <div
          class="page-content"
          :class="{
            'height-left-page': currentTabLeft == 2,
            'height-left-page-category': currentTabLeft == 2
          }"
          v-if="data"
        >
          <Card
            :notBody="true"
            :titleTypeProject="data.type_name"
            :greyColorTitle="true"
          >
            <template #title>
              Projet
            </template></Card
          >
          <div class="left-page-header justify-between">
            <Card :cardPrincipal="true">
              <template #body>
                <div class="confirmation-items flex-end">
                  <b-dropdown
                    v-if="!readonly && !readonlyEquipe"
                    block
                    class="custom-dropdown"
                    toggle-class="custom-dropdown-button"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <div
                        :class="{
                          active: data.status_document,
                          loading: loading['status_document'],
                          readonly: readonly || readonlyEquipe,
                          [data.status_document]: true
                        }"
                        class="item"
                        title="Statut du document"
                      >
                        <font-awesome-icon
                          class="icon"
                          :icon="['fas', 'file-alt']"
                        />
                      </div>
                    </template>
                    <b-dropdown-item
                      @click="toggleConfirmation('status_document', 'red')"
                      :active="data.status_document == 'red' ? true : false"
                    >
                      Rouge
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="toggleConfirmation('status_document', 'green')"
                      :active="data.status_document == 'green' ? true : false"
                    >
                      Vert
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="toggleConfirmation('status_document', 'yellow')"
                      :active="data.status_document == 'yellow' ? true : false"
                    >
                      Jaune
                    </b-dropdown-item>
                  </b-dropdown>
                  <div
                    v-else
                    :class="{
                      active: data.status_document,
                      loading: loading['status_document'],
                      readonly: readonly || readonlyEquipe,
                      [data.status_document]: true
                    }"
                    class="item"
                    title="Statut du document"
                  >
                    <font-awesome-icon
                      class="icon"
                      :icon="['fas', 'file-alt']"
                    />
                  </div>
                  <div
                    :class="{
                      active: data.dossier,
                      loading: loading['dossier'],
                      readonly: readonly || readonlyEquipe
                    }"
                    @click="toggleConfirmation('dossier')"
                    class="item"
                    title="Dossier"
                  >
                    <font-awesome-icon
                      class="icon"
                      :icon="['fas', 'folder-open']"
                    />
                  </div>
                  <div
                    :class="{
                      active: data.photo,
                      loading: loading['photo'],
                      readonly: readonly || readonlyEquipe
                    }"
                    @click="toggleConfirmation('photo')"
                    class="item"
                    title="Photos"
                  >
                    <font-awesome-icon
                      class="icon"
                      :icon="['fab', 'instagram']"
                    />
                  </div>
                  <!-- <div
                    :class="{
                      active: data.confirmation_previsite,
                      loading: loading['confirmation_previsite'],
                      readonly: readonly
                    }"
                    @click="toggleConfirmation('confirmation_previsite')"
                    class="item"
                    title="Confirmation commercial"
                  >
                    <font-awesome-icon
                      class="icon"
                      :icon="['fas', 'user-tie']"
                    />
                  </div> -->
                  <div
                    :class="{
                      active: data.confirmation,
                      loading: loading['confirmation'],
                      readonly: readonly || readonlyEquipe
                    }"
                    @click="toggleConfirmation('confirmation')"
                    class="item"
                    title="Confirmation"
                  >
                    <WorkerIcon
                      class="icon"
                      :height="'19px'"
                      :width="'20px'"
                      :color="data.confirmation ? '#5e5e5e' : ' #bfbfbf'"
                    />
                  </div>
                </div>
                <div class="info-contact flex">
                  <div
                    class="project-image"
                    :class="{ fake: !data.logo }"
                    :style="computedProjectImage"
                  ></div>
                  <div class="mt-3 ml-2">
                    <div class="flex justify-between pt-1">
                      <div class="bold title">Contact</div>
                      <div class="value">
                        {{ data.contact_full_name }}
                      </div>
                    </div>
                    <div class="flex justify-between pt-1">
                      <div class="bold title">Téléphone</div>
                      <div
                        class="value"
                        :class="{ 'color-phone': data.phones }"
                      >
                        {{
                          data && data.phones
                            ? $options.filters.formatTel(data.phones)
                            : '-'
                        }}
                      </div>
                    </div>
                    <div class="flex justify-between pt-1">
                      <div class="bold title">Cumac</div>
                      <div class="value">
                        {{ data && data.cumac ? data.cumac : '-' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="status">
                  <div class="pl-2 pr-2 flex justify-content-end pt-1">
                    <div class="bold title">Statut</div>
                    <div class="value">
                      {{ data.status | status }}
                    </div>
                  </div>
                  <div v-if="getPipelinesLoading" class="loader-container">
                    <LoadingComponent />
                  </div>
                  <div
                    v-else-if="
                      currentPipeline.stages &&
                        currentSubStages &&
                        currentSubStages.length
                    "
                    class="stages mt-2"
                    :class="{ readonly: readonly || readonlyEquipe }"
                  >
                    <div class="options">
                      <label
                        v-for="stage in currentPipeline.stages"
                        :for="'stage-' + stage.id"
                        class="stage"
                        :class="{
                          active: data.stage && stage.id == data.stage.id,
                          [data.subStage.color]: true
                        }"
                        v-tooltip.top-center="stage.name"
                        :key="'current-pipeline-stage-' + stage.id"
                      >
                        <input
                          :id="'stage-' + stage.id"
                          type="radio"
                          name="stage_id"
                          :value="stage.id"
                          @click="
                            updateStage(
                              stage,
                              data.subStage ? data.subStage.id : null
                            )
                          "
                        />
                      </label>
                    </div>
                  </div>
                  <!-- BLOCK IAUDITOR -->
                  <div
                    class="box-button-show"
                    v-if="computedAccesAuditIauditor(data)"
                  >
                    <div
                      class="show-button-header"
                      v-if="!readonly && !readonlyEquipe"
                    >
                      <a
                        :href="
                          `https://app.safetyculture.com/inspection/${
                            data.iauditor_id ? data.iauditor_id : null
                          }?page=1`
                        "
                        target="_blank"
                        title="Voir iAuditor"
                        class="iauditor-lien"
                      >
                        {{
                          data.iauditor_id
                            ? 'Accès à l’audit sur iAuditor '
                            : ''
                        }}
                        <font-awesome-icon
                          class="icon ml-1"
                          icon="external-link-alt"
                        />
                      </a>
                    </div>
                    <div class="show-button-header" v-else>
                      <a
                        title="Voir iAuditor"
                        class="iauditor-lien-not-readonly"
                      >
                        {{ 'Accès à l’audit sur iAuditor ' }}
                        <font-awesome-icon
                          class="icon ml-1"
                          icon="external-link-alt"
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    class="box-button-show mb-1"
                    v-if="computedAccesVTIauditor(data)"
                  >
                    <div
                      class="show-button-header-2"
                      v-if="!readonly && !readonlyEquipe"
                    >
                      <a
                        :href="
                          `https://app.safetyculture.com/inspection/${
                            data.iauditor_technique_id
                              ? data.iauditor_technique_id
                              : null
                          }?page=1`
                        "
                        target="_blank"
                        title="Voir iAuditor"
                        class="iauditor-lien"
                      >
                        {{
                          data.iauditor_technique_id
                            ? 'Accès à la visite technique sur iAuditor'
                            : ''
                        }}
                        <font-awesome-icon
                          class="icon ml-1"
                          icon="external-link-alt"
                        />
                      </a>
                    </div>
                    <div class="show-button-header-2" v-else>
                      <a
                        title="Voir iAuditor"
                        class="iauditor-lien-not-readonly"
                      >
                        {{ 'Accès à la visite technique sur iAuditor' }}
                        <font-awesome-icon
                          class="icon ml-1"
                          icon="external-link-alt"
                        />
                      </a>
                    </div>
                  </div>
                  <!-- BLOCK STADE AND SOUS STADE  -->
                  <div class="block-stade">
                    <div>
                      <div class="stage-container">
                        <span class="bold">Stade </span>
                        <span class="value-stade">{{
                          data.stage ? data.stage.name : '-'
                        }}</span>
                      </div>
                      <div
                        class="flex sub-stage-value pl-2 pr-2"
                        v-if="currentSubStages && currentSubStages.length"
                      >
                        <div class="bold">Sous-stade</div>
                        <div class="ml-1">
                          <EditableSelect
                            :readonly="readonly || readonlyEquipe"
                            field="csubstage_id"
                            :options="currentSubStages"
                            :entity="data"
                            :value="data.subStage ? data.subStage.id : null"
                            :updateFunction="updateProjectFields"
                            :autoWidth="true"
                          />
                        </div>
                      </div>
                      <div class="flex sub-stage-value pl-2 pr-2">
                        <div class="bold">
                          Télécharger Récap Travaux
                        </div>
                        <div class="icon-upload-pdf" @click="uploadFilePdf">
                          <font-awesome-icon class="" icon="download" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-modal
                    no-close-on-backdrop
                    size="sm"
                    ref="sub-stage-modal"
                    :hide-footer="true"
                    title="Choisir un sous-stade :"
                  >
                    <div>
                      <span style="font-weight: 600;">Stade :</span>
                      {{ nextStage ? nextStage.name : '' }}
                    </div>
                    <b-form-group
                      label="Sous-stades :"
                      style="font-weight: 600;"
                      label-for="sub-stage-input"
                      class="mt-2"
                    >
                      <b-form-select
                        v-if="nextSubStages"
                        class="mt-2"
                        id="sub-stage-input"
                        :options="nextSubStages"
                        value-field="id"
                        text-field="name"
                        v-model="nextSubStage"
                        :required="true"
                      ></b-form-select>
                    </b-form-group>
                    <div
                      v-if="nextSubStageError"
                      class="mt-2"
                      style="color:#ee220c;"
                    >
                      {{ nextSubStageError }}
                    </div>
                    <div class="form-actions mt-4">
                      <b-button
                        variant="outline-secondary"
                        @click="hideModal('sub-stage-modal')"
                      >
                        Annuler
                      </b-button>
                      <b-button
                        variant="success"
                        type="submit"
                        @click="setNewStageWithSub"
                      >
                        Valider
                      </b-button>
                    </div>
                  </b-modal>
                </div>
              </template>
            </Card>
            <!-- Menu -->
            <div class="tabs flex justify-between bold mt-2">
              <div
                class="tab-button"
                @click="toggleLeftTabs(5)"
                :class="{ active: currentTabLeft == 5 }"
              >
                Lead
              </div>
              <div
                class="tab-button"
                @click="toggleLeftTabs(1)"
                :class="{ active: currentTabLeft == 1 }"
              >
                Contact
              </div>
              <div
                class="tab-button"
                @click="toggleLeftTabs(2)"
                :class="{ active: currentTabLeft == 2 }"
              >
                Projet
              </div>
              <div
                class="tab-button"
                @click="toggleLeftTabs(3)"
                :class="{ active: currentTabLeft == 3 }"
              >
                Activités
              </div>
              <div
                class="tab-button-file width-pj"
                @click="toggleLeftTabs(4)"
                :class="{ active: currentTabLeft == 4 }"
              >
                <span>
                  Catégories PJ
                </span>
                <b-spinner
                  v-if="getCategorieLoading"
                  small
                  class="spinner-file"
                ></b-spinner>
                <div
                  v-if="
                    getCategoriesPieceJointeFile &&
                      getCategoriesPieceJointeFile.length != 0 &&
                      !getCategorieLoading
                  "
                  class="counter-file"
                >
                  {{ getCategoriesPieceJointeFile.length }}
                </div>
                <font-awesome-icon
                  v-if="
                    !getCategorieLoading &&
                      getCategoriesPieceJointeFile &&
                      getCategoriesPieceJointeFile.length == 0
                  "
                  class="icon-warnning-file"
                  icon="exclamation-circle"
                />
              </div>
            </div>
            <hr class="line-center-tabs" />
            <!--  categories Contact -->
            <div class="tab-content mt-2" v-if="currentTabLeft == 1">
              <Card :greyColorTitle="true">
                <template #title>Contact </template>
                <template #body>
                  <template v-for="(item, index) in data.categoriesContacts">
                    <div :key="'contact' + index" class="contact-category">
                      <div class="contact-ctaegory-icon">
                        <img
                          v-if="item.icon_name"
                          :class="
                            'img-categorie-' +
                              item.icon_name.replace(/\.[^.$]+$/, '')
                          "
                          :src="
                            require(`@/assets/img/imgModalProject/${item.icon_name}`)
                          "
                          alt=""
                        />
                      </div>
                      <div
                        class="contact-ctaegory-title"
                        @click="toggleLeftTabsCategory(index, item)"
                        :class="{
                          active: currentTabLeftCategrie == index
                        }"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </template>
              </Card>
            </div>
            <!--  categories projet -->
            <div class="tab-content mt-2" v-if="currentTabLeft == 2">
              <Card
                v-if="data.categoriesProjets"
                :deleteProjet="true"
                :project="data"
                :greyColorTitle="true"
              >
                <template #title>Projet </template>
                <template #body>
                  <template v-for="(item, index) in data.categoriesProjets">
                    <div :key="'projet' + index" class="contact-category">
                      <div class="contact-ctaegory-icon">
                        <img
                          v-if="item.icon_name"
                          :class="
                            'img-categorie-' +
                              item.icon_name.replace(/\.[^.$]+$/, '')
                          "
                          :src="
                            require(`@/assets/img/imgModalProject/${item.icon_name}`)
                          "
                          alt=""
                        />
                      </div>
                      <div
                        class="contact-ctaegory-title"
                        @click="toggleLeftTabsCategory(index, item)"
                        :class="{
                          active: currentTabLeftCategrie == index
                        }"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </template>
              </Card>
            </div>
            <!--  categories fichier -->
            <div class="tab-content mt-2" v-if="currentTabLeft == 4">
              <Card :notBody="true">
                <template #body>
                  <!-- <WAFiles
                    :gestionFile="currentTabLeft == 4 ? true : false"
                    :project="data"
                    :readonly="readonly || readonlyEquipe"
                    @selectedNewCategorie="selectedNewCategoriePJ"
                  /> -->
                  <div class="label-catg-pj">catégories des pièces jointes</div>
                  <WAFilesCategoriePJ
                    :gestionFile="currentTabLeft == 4 ? true : false"
                    :project="data"
                    :readonly="readonly || readonlyEquipe"
                    @selectedNewCategorie="selectedNewCategoriePJ"
                  />
                </template>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <!-- FORMULAIRE LEAD RAPIDE  -->
      <div
        v-if="openFormLeadsRapide"
        class="right-page-lead"
        :class="{ 'right-page-none': openFormLeadsRapide }"
      >
        <!-- BUTTON CLOSE FORM-->
        <div class="header-right-page">
          <div class="close-form-lead" @click="handleCloseFormLead">
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <div v-if="openFormLeadsRapide">
          <!--  FORM LEADS RAPIDE -->
          <LeadsForm
            v-if="openFormLeadsRapide"
            @actionFormLeads="actionFormLeads"
            :entity="data"
            :categoryContact="
              data.categoriesContacts[currentTabLeftCategrie].subcategories
            "
            :categoryProjet="data.categoriesProjets"
            :readonly="readonly || readonlyEquipe"
            :typeProjet="type"
          ></LeadsForm>
        </div>
      </div>
      <!-- MODAL RIGHT DE PROJET  -->
      <div class="right-page" v-if="!openFormLeadsRapide">
        <!-- BUTTON CLOSE FORM-->
        <div class="header-right-page">
          <div class="close" @click="handleClose">
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <div>
          <!-- Sous categories projet-->
          <div class="header-right-page">
            <div class="title-projet">
              <span v-if="currentTabLeft == 1">
                <!-- Contact / -->
                {{
                  data.categoriesContacts &&
                  data.categoriesContacts[currentTabLeftCategrie] &&
                  data.categoriesContacts[currentTabLeftCategrie].name
                    ? data.categoriesContacts[currentTabLeftCategrie].name
                    : ''
                }}</span
              >
              <span v-if="currentTabLeft == 3"> Activités</span>
              <span v-if="currentTabLeft == 4"> Fichiers</span>
            </div>
          </div>
          <!-- Sous categories Contact-->
          <div
            class="tab-content mt-2"
            v-if="
              currentTabLeft == 1 &&
                data.categoriesContacts &&
                data.categoriesContacts.length
            "
          >
            <template
              v-for="(sous_category, index) in data.categoriesContacts[
                currentTabLeftCategrie
              ].subcategories"
            >
              <Card
                :key="'sous_category_subtypes' + index"
                v-if="
                  sous_category.define_subtype &&
                    currentSousTypes ==
                      sous_category.define_subtype.subtype_name
                "
                :IconSwitch="sous_category"
                :titleMontant="sous_category.fields"
                :project="data"
                @switchActive="etatSwitch"
                :categorie="data.categoriesContacts[currentTabLeftCategrie]"
                :subcategorie="true"
                :subCategoryColor="sous_category.sub_category_color"
                :ongletMenu="currentTabLeft"
              >
                <template #title> {{ sous_category.name }} </template>
                <template #body>
                  <div v-for="field in sous_category.fields" :key="field.id">
                    <div
                      class="flex justify-between pt-1 mb-2"
                      v-if="field.is_header == 0"
                    >
                      <div class="bold title">{{ field.name }}</div>
                      <div class="value"></div>
                    </div>
                    <template v-if="field.separator === true">
                      <hr class="line-hr" />
                    </template>
                  </div>
                </template>
              </Card>
              <Card
                :key="'sous_category' + index"
                v-if="!sous_category.define_subtype"
                :IconSwitch="sous_category"
                :titleMontant="sous_category.fields"
                :project="data"
                @switchActive="etatSwitch"
                :categorie="data.categoriesContacts[currentTabLeftCategrie]"
                :subcategorie="true"
                :subCategoryColor="sous_category.sub_category_color"
                :ongletMenu="currentTabLeft"
              >
                <template #title>
                  {{ sous_category.name }}
                </template>
                <template #body>
                  <!-- categories Contact : Aides & Subventions -->
                  <!-- sous-categories : CEE -->
                  <div class="tab-content mt-2" v-if="sous_category.id == 5">
                    <div class="prime-header-th">
                      <div class="prime-header-td-1">Prime.</div>
                      <div class="prime-header-td-2">Accepté le</div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">BAR TH164</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_th_164_prime"
                          :entity="data"
                          type="number"
                          :value="data.bar_th_164_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_th_164_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.bar_th_164_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">BAR TH104</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_th_104_prime"
                          :entity="data"
                          type="number"
                          :value="data.bar_th_104_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_th_104_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.bar_th_104_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">BAR TH148</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_th_148_prime"
                          :entity="data"
                          type="number"
                          :value="data.bar_th_148_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_th_148_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.bar_th_148_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">BAR TH127</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_th_127_prime"
                          :entity="data"
                          type="number"
                          :value="data.bar_th_127_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_th_127_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.bar_th_127_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">BAR EN101</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_en_101_prime"
                          :entity="data"
                          type="number"
                          :value="data.bar_en_101_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_en_101_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.bar_en_101_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">BAR EN102</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_en_102_prime"
                          :entity="data"
                          type="number"
                          :value="data.bar_en_102_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_en_102_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.bar_en_102_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">BAR EN103</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_en_103_prime"
                          :entity="data"
                          type="number"
                          :value="data.bar_en_103_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="bar_en_103_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.bar_en_103_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- sous-categories : Maprimerenov’-->
                  <div class="tab-content mt-2" v-if="sous_category.id == 6">
                    <div class="prime-header-th">
                      <div class="prime-header-td-1">Prime.</div>
                      <div class="prime-header-td-2">Accepté le</div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">Audit Énergétique</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="audit_ener_prime"
                          :entity="data"
                          type="number"
                          :value="data.audit_ener_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="audit_ener_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.audit_ener_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">Air/Eau</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="air_eau_prime"
                          :entity="data"
                          type="number"
                          :value="data.air_eau_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="air_eau_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.air_eau_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">Ballon Thermo</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="ballon_thermo_prime"
                          :entity="data"
                          type="number"
                          :value="data.ballon_thermo_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="ballon_thermo_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.ballon_thermo_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">VMC Double ﬂux</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="vmc_double_flux_prime"
                          :entity="data"
                          type="number"
                          :value="data.vmc_double_flux_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="vmc_simple_flux_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.vmc_simple_flux_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">VMC simple ﬂux</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="vmc_double_flux_prime"
                          :entity="data"
                          type="number"
                          :value="data.vmc_double_flux_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="vmc_double_flux_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.vmc_double_flux_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">ITE</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="ite_prime"
                          :entity="data"
                          type="number"
                          :value="data.ite_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="ite_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.ite_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <div class="bold title">ITI+</div>
                      <div class="flex">
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="iti_prime"
                          :entity="data"
                          type="number"
                          :value="data.iti_prime"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                        <EditableInput
                          :readonly="readonly || readonlyEquipe"
                          field="iti_date_accept"
                          :entity="data"
                          type="date"
                          :value="data.iti_date_accept"
                          :updateFunction="updateContact"
                          :small="true"
                        />
                      </div>
                    </div>
                  </div>
                  <hr
                    v-if="
                      (sous_category.id == 5 || sous_category.id == 6) &&
                        sous_category.fields &&
                        sous_category.fields.length &&
                        sous_category.fields[0].is_header == 0
                    "
                  />
                  <!-- les champs dynamique -->
                  <div v-for="field in sous_category.fields" :key="field.id">
                    <div
                      class="flex justify-between pt-1 mb-2"
                      v-if="field.is_header == 0"
                    >
                      <div class="bold title">{{ field.name }}</div>
                      <div class="value" v-if="field.object == 'contacts'">
                        <EditableCustomFieldCategorie
                          :readonly="readonly || readonlyEquipe"
                          :field="field"
                          :entity="data"
                          :value="field.value"
                          :updateFunction="updateContact"
                          :sousCategory="sous_category"
                          :contentInfoFiscal="
                            notContentFiscal && notContentFiscal.length
                              ? true
                              : false
                          "
                          :fieldKeyEdit="
                            field.key === 'depot_tech_id' ||
                            field.key === 'co_technician' ||
                            field.key === 'precar'
                              ? true
                              : false
                          "
                        />
                      </div>
                      <div class="value" v-else>
                        <EditableCustomFieldCategorie
                          :readonly="readonly || readonlyEquipe"
                          :field="field"
                          :entity="data"
                          :value="field.value"
                          :updateFunction="updateProjectFields"
                          :sousCategory="sous_category"
                          :fieldKeyEdit="
                            field.key === 'depot_tech_id' ||
                            field.key === 'co_technician' ||
                            field.key === 'precar'
                              ? true
                              : false
                          "
                        />
                      </div>
                    </div>
                    <template v-if="field.separator === true">
                      <hr class="line-hr" />
                    </template>
                  </div>
                </template>
              </Card>
            </template>
            <!-- Sous categories Contact : Informations ﬁscales -->
            <Card
              v-if="
                data.categoriesContacts &&
                  data.categoriesContacts[currentTabLeftCategrie].id == 2
              "
              :subcategorie="true"
              :subCategoryColor="null"
            >
              <template #title>Informations fiscales </template>
              <template
                #body
                v-if="
                  computedCpReferenceFiscal && computedCpReferenceFiscal.length
                "
              >
                <div
                  v-for="(item, index) in computedCpReferenceFiscal"
                  :key="index"
                  class="fiscal-flex"
                  :class="{ 'mt-2': index == 0 }"
                >
                  <div class="fiscal-flex mr-1">
                    <label class="label-fiscal ">Avis {{ index + 1 }}</label>
                    <b-form-input
                      class="input-fiscal mb-2"
                      v-model="selectedTaxNumber[index]"
                      :placeholder="'Numéro fiscal ' + `${index + 1}`"
                    ></b-form-input>
                  </div>
                  <div>
                    <b-form-input
                      class="input-fiscal mb-2"
                      v-model="selectedRefernceFiscal[index]"
                      :placeholder="'Référence avis ' + `${index + 1}`"
                    ></b-form-input>
                  </div>
                </div>

                <div v-if="messageErrorFiscal" class="error">
                  <ul v-if="Array.isArray(messageErrorFiscal)">
                    <li v-for="(e, index) in messageErrorFiscal" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <span v-else>{{ messageErrorFiscal }}</span>
                </div>
                <div v-if="getProjectsError" class="error">
                  <ul v-if="Array.isArray(getProjectsError)">
                    <li v-for="(e, index) in getProjectsError" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <span v-else>{{ getProjectsError }}</span>
                </div>
                <div
                  class="btn-bloc-fiscal"
                  :class="{ 'flex-block': getProjectsProcessing }"
                >
                  <div v-if="getProjectsProcessing" class="three-dots-loading">
                    Chargement en cours
                  </div>
                  <b-button
                    variant="success"
                    class="btn-fiscal"
                    @click="enregistreRefFiscal"
                  >
                    Enregistrer
                  </b-button>
                </div>
                <!-- <div class="fiscal-header-th editable">
                  <div class="fiscal-header-td">RE.FISCAL DE L'AVIS</div>
                  <div class="fiscal-header-td">REVENU FISCAL</div>
                  <div class="fiscal-header-td">ANNÉE</div>
                </div>
                <EditableInputListFiscal
                  :readonly="readonly || readonlyEquipe"
                  field="reference_fiscal_avis"
                  :entity="data"
                  :values="data.reference_fiscal_avis"
                  :updateFunction="updateContact"
                />
                <hr />
                <div class="flex justify-between pt-1 mb-2">
                  <div class="bold title">Numero fiscal</div>
                  <div class="value">
                    <EditableInput
                      :readonly="readonly || readonlyEquipe"
                      field="tax_number"
                      :entity="data"
                      :value="data.tax_number"
                      :updateFunction="updateContact"
                    />
                  </div>
                </div>
                <div class="flex justify-between pt-1 mb-2">
                  <div class="bold title">Numero fiscal 2</div>
                  <div class="value">
                    <EditableInput
                      :readonly="readonly || readonlyEquipe"
                      field="tax_number_2"
                      :entity="data"
                      :value="data.tax_number_2"
                      :updateFunction="updateContact"
                    />
                  </div>
                </div> -->
              </template>
            </Card>
          </div>
          <!-- Sous categories projet : Pipeline administratif -->
          <div class="title-projet">
            <span
              v-if="
                currentTabLeft == 2 &&
                  data.categoriesProjets &&
                  data.categoriesProjets.length &&
                  data.categoriesProjets[currentTabLeftCategrie] &&
                  data.categoriesProjets[currentTabLeftCategrie].has_pipeline ==
                    1
              "
            >
              {{
                data.categoriesProjets[currentTabLeftCategrie] &&
                data.categoriesProjets[currentTabLeftCategrie].pipeline_cat_name
                  ? data.categoriesProjets[currentTabLeftCategrie]
                      .pipeline_cat_name
                  : ''
              }}</span
            >
          </div>
          <Card
            :notBody="true"
            v-if="
              data.categoriesProjets &&
                data.categoriesProjets.length &&
                data.categoriesProjets[currentTabLeftCategrie] &&
                data.categoriesProjets[currentTabLeftCategrie].has_pipeline ==
                  1 &&
                currentTabLeft == 2
            "
          >
            <template #title></template>
            <template #body>
              <div class="page-content margin-top">
                <div class="status">
                  <div
                    v-if="getPipelinesCategoryLoading"
                    class="loader-container"
                  >
                    <LoadingComponent />
                  </div>
                  <div
                    v-else-if="
                      currentPipelineCategory.stages &&
                        currentSubStagesCategory &&
                        currentSubStagesCategory.length
                    "
                    class="stages mt-2"
                    :class="{ readonly: readonly || readonlyEquipe }"
                  >
                    <div class="options">
                      <label
                        v-for="stage in currentPipelineCategory.stages"
                        :for="'stage-category-' + stage.id"
                        class="stageadministratif"
                        :class="{
                          active:
                            data.categoriesProjets[currentTabLeftCategrie] &&
                            stage.id ==
                              data.categoriesProjets[currentTabLeftCategrie]
                                .pipeline_cat_stage_id,
                          [data.status]: true
                        }"
                        v-tooltip.top-center="stage.name"
                        :key="'current-pipeline-stage-category-' + stage.id"
                      >
                        <input
                          :id="'stage-category-' + stage.id"
                          type="radio"
                          name="stage_category_id"
                          :value="stage.id"
                          @click="
                            updateStagecategory(
                              stage,
                              data.categoriesProjets[currentTabLeftCategrie] &&
                                data.categoriesProjets[currentTabLeftCategrie]
                                  .pipeline_cat_substage_id
                                ? data.categoriesProjets[currentTabLeftCategrie]
                                    .pipeline_cat_substage_id
                                : null
                            )
                          "
                        />
                      </label>
                    </div>
                  </div>
                  <div class="stage-container">
                    <span class="bold">Stade admin. </span>
                    <span class="value-stade">{{
                      data.categoriesProjets[currentTabLeftCategrie] &&
                      data.categoriesProjets[currentTabLeftCategrie]
                        .pipeline_cat_stage_name
                        ? data.categoriesProjets[currentTabLeftCategrie]
                            .pipeline_cat_stage_name
                        : '-'
                    }}</span>
                  </div>
                  <div
                    class="flex sub-stage-value pl-2 pr-2"
                    v-if="
                      currentSubStagesCategory &&
                        currentSubStagesCategory.length
                    "
                  >
                    <div class="bold">Sous-stade admin.</div>
                    <div class="ml-1">
                      <EditableSelect
                        :readonly="readonly || readonlyEquipe"
                        field="pipeline_cat_substage_id"
                        :options="currentSubStagesCategory"
                        :entity="data"
                        :value="
                          data.categoriesProjets[currentTabLeftCategrie] &&
                          data.categoriesProjets[currentTabLeftCategrie]
                            .pipeline_cat_substage_id
                            ? data.categoriesProjets[currentTabLeftCategrie]
                                .pipeline_cat_substage_id
                            : null
                        "
                        :updateFunction="updateProjectFields"
                        :autoWidth="true"
                      />
                    </div>
                  </div>
                  <div class="flex sub-stage-value pl-2 pr-2">
                    <div class="bold">Statut Dossier admin.</div>
                    <div class="ml-1">
                      <EditableSelect
                        :readonly="readonly || readonlyEquipe"
                        field="statut_dossier_administratif"
                        :options="
                          [
                            { value: 'Complet', text: 'Complet' },
                            { value: 'Incomplet', text: 'Incomplet' }
                          ] || []
                        "
                        :entity="data"
                        :value="data.statut_dossier_administratif"
                        :updateFunction="updateProjectFields"
                        :autoWidth="true"
                      />
                    </div>
                  </div>
                  <b-modal
                    no-close-on-backdrop
                    size="sm"
                    ref="sub-stage-cate-modal"
                    :hide-footer="true"
                    title="Choisir un sous-stade :"
                  >
                    <div>
                      <span style="font-weight: 600;">Stade admin. :</span>
                      {{ nextStageCategory ? nextStageCategory.name : '' }}
                    </div>
                    <b-form-group
                      label="Sous-stades admin. :"
                      style="font-weight: 600;"
                      label-for="sub-stage-input"
                      class="mt-2"
                    >
                      <b-form-select
                        v-if="nextSubStagesCategory"
                        class="mt-2"
                        id="sub-stage-input"
                        :options="nextSubStagesCategory"
                        value-field="id"
                        text-field="name"
                        v-model="nextSubStageCategory"
                        :required="true"
                      ></b-form-select>
                    </b-form-group>
                    <div
                      v-if="nextSubStageCategoryError"
                      class="mt-2"
                      style="color:#ee220c;"
                    >
                      {{ nextSubStageCategoryError }}
                    </div>
                    <div class="form-actions mt-4">
                      <b-button
                        variant="outline-secondary"
                        @click="hideModal('sub-stage-cate-modal')"
                      >
                        Annuler
                      </b-button>
                      <b-button
                        variant="success"
                        type="submit"
                        @click="setNewStageWithSubCategory"
                      >
                        Valider
                      </b-button>
                    </div>
                  </b-modal>
                </div>
              </div>
            </template>
          </Card>
          <div class="title-projet">
            <span v-if="currentTabLeft == 2">
              {{
                data.categoriesProjets &&
                data.categoriesProjets[currentTabLeftCategrie] &&
                data.categoriesProjets[currentTabLeftCategrie].name
                  ? data.categoriesProjets[currentTabLeftCategrie].name
                  : ''
              }}</span
            >
          </div>
          <!-- sous-types des projet -->
          <div
            v-if="
              currentTabLeft == 2 &&
                data.categoriesProjets &&
                data.categoriesProjets.length &&
                data.categoriesProjets[currentTabLeftCategrie].subtypes &&
                data.categoriesProjets[currentTabLeftCategrie].subtypes.length
            "
          >
            <SousTypeProjet
              :subtypes="
                data.categoriesProjets[currentTabLeftCategrie].subtypes
              "
              :readonly="readonly || readonlyEquipe"
              @sousType="changeSousType"
            ></SousTypeProjet>
          </div>
          <!-- Sous categories projet -->
          <div
            class="tab-content mt-2"
            v-if="
              currentTabLeft == 2 &&
                data.categoriesProjets &&
                data.categoriesProjets.length
            "
          >
            <template
              v-for="(sous_category, index) in data.categoriesProjets[
                currentTabLeftCategrie
              ].subcategories"
            >
              <Card
                :key="'sous_category_subtypes' + index"
                v-if="
                  sous_category.define_subtype &&
                    currentSousTypes ==
                      sous_category.define_subtype.subtype_name
                "
                :IconSwitch="sous_category"
                :titleMontant="sous_category.fields"
                :project="data"
                @switchActive="etatSwitch"
                :subtypes="
                  data.categoriesProjets[currentTabLeftCategrie].subtypes
                "
                :categorie="data.categoriesProjets[currentTabLeftCategrie]"
                :subcategorie="true"
                :subCategoryColor="sous_category.sub_category_color"
                :ongletMenu="currentTabLeft"
              >
                <template #title>
                  {{ sous_category.name }}
                </template>
                <template #body>
                  <!-- les champs dynamiques -->
                  <div v-for="field in sous_category.fields" :key="field.id">
                    <div
                      class="flex justify-between pt-1 mb-2"
                      v-if="field.is_header == 0"
                    >
                      <div class="bold title">{{ field.name }}</div>
                      <div class="value-dynamique">
                        <EditableCustomFieldCategorie
                          :readonly="readonly || readonlyEquipe"
                          :field="field"
                          :entity="data"
                          :value="field.value"
                          :updateFunction="updateProjectFields"
                          :sousCategory="sous_category"
                          :fieldKeyEdit="
                            field.key === 'depot_tech_id' ||
                            field.key === 'co_technician' ||
                            field.key === 'precar'
                              ? true
                              : false
                          "
                        />
                      </div>
                    </div>
                    <template v-if="field.separator === true">
                      <hr class="line-hr" />
                    </template>
                  </div>
                </template>
              </Card>
              <Card
                :key="'sous_category' + index"
                v-if="!sous_category.define_subtype"
                :IconSwitch="sous_category"
                :titleMontant="sous_category.fields"
                :project="data"
                @switchActive="etatSwitch"
                :showButton="data && data.precarity ? data.precarity : '-'"
                :categorie="data.categoriesProjets[currentTabLeftCategrie]"
                :subcategorie="true"
                :subCategoryColor="sous_category.sub_category_color"
                :ongletMenu="currentTabLeft"
              >
                <template #title>
                  {{ sous_category.name }}
                </template>
                <template #body>
                  <!-- Sous categories projet : Cordonnées & Adresse -->
                  <div v-if="sous_category.id == 9 || sous_category.id == 41">
                    <div
                      v-if="(!readonly && !readonlyEquipe) || readonly"
                      class="copy-info"
                    >
                      <a
                        href=""
                        @click.prevent="handleCopyInfoClick"
                        class="copy-info-button"
                      >
                        <font-awesome-icon icon="copy" />
                      </a>
                      Copier les informations de contact
                    </div>
                  </div>
                  <!-- les champs dynamiques -->
                  <div v-for="field in sous_category.fields" :key="field.id">
                    <template v-if="field.is_first_field == true">
                      <div class="title-logistique-travaux">
                        <span>{{
                          field.type_travaux ? field.type_travaux + ' ' : ''
                        }}</span>
                        <!-- <span>{{
                          field.type_isolation
                            ? '   ' + field.type_isolation
                            : ''
                        }}</span> -->
                      </div>
                      <hr class="line-hr" />
                    </template>
                    <div v-if="field.is_header == 0">
                      <div>
                        <!-- CONTACT -->
                        <div
                          v-if="field.object == 'contacts'"
                          class="flex justify-between pt-1 mb-2"
                        >
                          <div class="bold title">{{ field.name }}</div>
                          <div class="value-dynamique">
                            <EditableCustomFieldCategorie
                              :readonly="readonly || readonlyEquipe"
                              :field="field"
                              :entity="data"
                              :value="field.value"
                              :updateFunction="updateContact"
                              :sousCategory="sous_category"
                              :contentInfoFiscal="
                                notContentFiscal && notContentFiscal.length
                                  ? true
                                  : false
                              "
                              :fieldKeyEdit="
                                field.key === 'depot_tech_id' ||
                                field.key === 'co_technician' ||
                                field.key === 'precar'
                                  ? true
                                  : false
                              "
                            />
                          </div>
                        </div>
                        <!-- PROJECT -->
                        <div v-else class="flex justify-between pt-1 mb-2">
                          <div
                            class="bold title"
                            v-if="field.logistique_travaux"
                          >
                            {{
                              field.name
                                ? field.name.split(/[()]+/)[0]
                                : field.name
                            }}
                          </div>
                          <div class="bold title" v-else>{{ field.name }}</div>
                          <!-- HAS REGROUPEMMENT SELECT  -->
                          <div
                            class="value-dynamique"
                            v-if="field.has_regroupement == 1"
                          >
                            <EditableCustomFieldCategorieHasRegroupement
                              @openHasRegroupemnt="openHasRegroupemnt"
                              :readonly="readonly || readonlyEquipe"
                              :field="field"
                              :entity="data"
                              :value="field.value"
                              :updateFunction="updateProjectFields"
                              :sousCategory="sous_category"
                              :categorie="
                                data.categoriesProjets[currentTabLeftCategrie]
                              "
                              :fieldKeyEdit="
                                field.key === 'depot_tech_id' ||
                                field.key === 'co_technician' ||
                                field.key === 'precar'
                                  ? true
                                  : false
                              "
                            />
                          </div>
                          <!-- HAS NOT REGROUPEMMENT SELECT  -->
                          <div class="value-dynamique" v-else>
                            <EditableCustomFieldCategorie
                              :readonly="readonly || readonlyEquipe"
                              :field="field"
                              :entity="data"
                              :value="field.value"
                              :updateFunction="updateProjectFields"
                              :sousCategory="sous_category"
                              :fieldKeyEdit="
                                field.key === 'depot_tech_id' ||
                                field.key === 'co_technician' ||
                                field.key === 'precar'
                                  ? true
                                  : false
                              "
                              :openInputAuto="
                                computedOpenInput(openInputAuto) ? false : true
                              "
                              @openInput="openInput"
                            />
                          </div>
                          <div
                            v-if="
                              visibilityPlanningAmo(field) &&
                                computePlannings &&
                                !visibilityTechTravaux
                            "
                            class="injected-planning"
                          >
                            <!-- field.depot_id_planning si null on va ajouter par defaut id de amo -->
                            <router-link
                              :to="
                                computedLinkPlanningAmo(
                                  '/planning-amo/agence/' +
                                    field.depot_id_planning +
                                    '/mois/' +
                                    field.month_id_planning +
                                    '/jour/' +
                                    field.day_nbr_planning,
                                  field.com_ite_id_planning
                                    ? field.com_ite_id_planning
                                    : null,
                                  field,
                                  data
                                )
                              "
                              target="_blank"
                              class="disabled"
                              :class="{
                                active: field
                              }"
                            >
                              <font-awesome-icon
                                @click="
                                  enterLink(
                                    field.com_ite_id_planning
                                      ? field.com_ite_id_planning
                                      : null,
                                    field,
                                    data,
                                    'amo'
                                  )
                                "
                                class="icon"
                                icon="external-link-alt"
                                title="Voir planning AMO"
                              />
                            </router-link>
                          </div>
                          <div
                            v-if="
                              visibilityPlanningVisiteurTech(field) &&
                                computePlannings &&
                                !visibilityTechTravaux
                            "
                            class="injected-planning"
                          >
                            <router-link
                              :to="
                                computedLinkPlanningAmo(
                                  '/planning-visiteur-tech/agence/' +
                                    field.depot_id_planning +
                                    '/mois/' +
                                    field.month_id_planning +
                                    '/jour/' +
                                    field.day_nbr_planning,
                                  field.vt_id_planning
                                    ? field.vt_id_planning
                                    : null,
                                  field,
                                  data
                                )
                              "
                              target="_blank"
                              class="disabled"
                              :class="{
                                active: field
                              }"
                            >
                              <font-awesome-icon
                                @click="
                                  enterLink(
                                    field.vt_id_planning
                                      ? field.vt_id_planning
                                      : null,
                                    field,
                                    data,
                                    'vt'
                                  )
                                "
                                class="icon"
                                icon="external-link-alt"
                                title="Voir planning visiteur technique"
                              />
                            </router-link>
                          </div>
                          <div
                            v-if="
                              visibilityPlanningPoseur(field) &&
                                computePlannings
                            "
                            class="injected-planning"
                          >
                            <router-link
                              :to="
                                computedLinkPlanningAmo(
                                  '/planning-pose/agence/' +
                                    field.depot_id_planning +
                                    '/mois/' +
                                    field.month_id_planning +
                                    '/jour/' +
                                    field.day_nbr_planning,
                                  field.tech_id_planning
                                    ? field.tech_id_planning
                                    : null,
                                  field,
                                  data
                                )
                              "
                              target="_blank"
                              class="disabled"
                              :class="{
                                active: field
                              }"
                            >
                              <font-awesome-icon
                                class="icon"
                                icon="external-link-alt"
                                @click="
                                  enterLink(
                                    field.tech_id_planning
                                      ? field.tech_id_planning
                                      : null,
                                    field,
                                    data,
                                    'pose'
                                  )
                                "
                                title="Voir planning pose"
                              />
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-if="field.separator === true">
                      <hr class="line-hr" />
                    </template>
                  </div>
                </template>
              </Card>
            </template>
            <Card
              :notBody="true"
              v-if="
                data.comments && data.comments.length && !getProjectsProcessing
              "
            >
              <template #title></template>
              <template #body>
                <ProjectComment
                  v-for="comment in data.comments"
                  :key="comment.id"
                  :entity="comment"
                  :project="data"
                  :sticky="false"
                  :categoryProjet="true"
                  :readonly="readonly || readonlyEquipe"
                />
              </template>
            </Card>
          </div>
          <!-- Sous categories Activites -->
          <div class="tab-content-activity mt-2" v-if="currentTabLeft == 3">
            <Card :notBody="true" :epinglerNote="true">
              <template #title>
                <!-- epingler une commentaire -->
                <ProjectComment
                  v-for="comment in computedNoteDesepingle"
                  :key="comment.id"
                  :entity="comment"
                  :project="data"
                  :sticky="true"
                  :readonly="readonly || readonlyEquipe"
                />
                <div class="tabs-activity flex justify-between bold">
                  <div
                    v-if="!readonly && !readonlyEquipe"
                    class="tab-button"
                    @click="toggleRightTabs(1)"
                    :class="{
                      active: currentTabRight == 1,
                      visibilityPixel: !visibilityInjectePixel,
                      visible: visibilityTypeTeamInstallateurWithOngleActivity
                    }"
                  >
                    Activités
                  </div>
                  <div
                    class="tab-button"
                    @click="toggleRightTabs(2)"
                    :class="{
                      active: currentTabRight == 2,
                      'full-width': readonly || readonlyEquipe,
                      visibilityPixel: !visibilityInjectePixel,
                      'width-cadre-note': visibilityTypeTeamInstallateurWithOngleActivity
                    }"
                  >
                    Notes
                  </div>
                  <div
                    v-if="
                      (!readonly && !readonlyEquipe) ||
                        visibilityTypeTeamInstallateurWithOngleActivity
                    "
                    class="tab-button"
                    @click="toggleRightTabs(3)"
                    :class="{
                      active: currentTabRight == 3,
                      visibilityPixel: !visibilityInjectePixel,
                      'width-cadre-file': visibilityTypeTeamInstallateurWithOngleActivity
                    }"
                  >
                    Fichiers
                  </div>
                  <div
                    v-if="
                      !readonly && !readonlyEquipe && visibilityInjectePixel
                    "
                    class="tab-button"
                    @click="toggleRightTabs(4)"
                    :class="{
                      active: currentTabRight == 4,
                      visible: visibilityTypeTeamInstallateurWithOngleActivity
                    }"
                  >
                    Injection
                  </div>
                </div>
              </template>
              <template #body>
                <!-- Ajouter Activites -->
                <div
                  class="tab-content-activity bordred"
                  v-if="currentTabRight == 1"
                >
                  <div class="activity-form">
                    <form @submit.prevent="handleActivityForm">
                      <div class="activity-title">
                        <b-form-input
                          v-model.trim="activityToAdd.name"
                        ></b-form-input>
                      </div>
                      <div class="activity-date mt-3">
                        <label>
                          {{
                            currentActivityType === 3
                              ? 'Suggestions des rendez-vous :'
                              : 'Dates :'
                          }}
                          <div class="message" v-if="currentActivityType === 3">
                            <div v-if="error" class="error">
                              <div v-if="Array.isArray(error)">
                                <span v-for="(e, index) in error" :key="index">
                                  {{ e }}
                                </span>
                              </div>
                              <span v-else>{{ error }}</span>
                            </div>
                          </div>
                        </label>
                        <div
                          v-if="currentActivityType === 3"
                          class="diponibility"
                        >
                          <div
                            class="three-dots-loading"
                            v-if="getProjectsSuggestionsProcessing"
                          >
                            Chargement en cours
                          </div>
                          <template v-else>
                            <template v-if="planificationSuggestions">
                              <div class="planning-description italic">
                                <span class="planning-description--label bold">
                                  Estimation de durée de pose :
                                </span>
                                <span class="planning-description--value">
                                  {{
                                    planificationSuggestions.pose_time_minutes
                                      | duration
                                  }}
                                </span>
                              </div>
                              <Collapse
                                v-for="(week,
                                week_index) in planificationSuggestions.weeks"
                                :key="
                                  'planification-suggestions-' + week.week_id
                                "
                                :init="week_index == 0 ? true : false"
                                :selected="collapseSuggestions"
                                @toggle="collapseSuggestions = $event"
                              >
                                <template #title>
                                  {{
                                    week_index == 0
                                      ? `Semaine en cours (N°${week.week_nbr})`
                                      : week_index == 1
                                      ? `Semaine prochaine (N°${week.week_nbr})`
                                      : `Semaine (N°${week.week_nbr})`
                                  }}
                                </template>
                                <template #body>
                                  <div class="suggestions-list">
                                    <label
                                      class="suggestion"
                                      v-for="(date,
                                      index) in week.available_dates_hours_techs"
                                      :key="
                                        'available-dates-hours-techs-' + index
                                      "
                                      :for="
                                        `radio-suggest-${week_index}-${index}`
                                      "
                                      :class="{
                                        active:
                                          activityToAdd.date_start ==
                                          `${date.day} ${date.hour}`
                                      }"
                                    >
                                      <input
                                        :id="
                                          `radio-suggest-${week_index}-${index}`
                                        "
                                        type="radio"
                                        name="radio-suggest"
                                        :value="`${date.day} ${date.hour}`"
                                        v-model="activityToAdd.date_start"
                                      />
                                      <div class="suggestion-container">
                                        <div class="date-flag">
                                          <font-awesome-icon
                                            icon="calendar-day"
                                          />
                                        </div>
                                        <div class="date-details">
                                          <span class="capitalize">
                                            {{ date.day | date('ddd DD MMM') }}
                                          </span>
                                          à
                                          {{ date.hour }}
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </template>
                              </Collapse>
                            </template>
                          </template>
                        </div>
                        <div v-else class="activity-date-interval">
                          <date-picker
                            v-model="activityToAdd.date_start"
                            format="YYYY-MM-DD HH:mm"
                            type="datetime"
                            placeholder="Choisir date de début"
                            required
                          ></date-picker>
                          <b-form-checkbox
                            v-if="
                              activityToAdd.type == 'Appel' ||
                                activityToAdd.type == 'Autre'
                            "
                            class="checkbox-terminer"
                            v-model="activityToAdd.terminer"
                            :value="1"
                            :unchecked-value="0"
                          >
                            Activité terminée
                          </b-form-checkbox>
                        </div>
                      </div>
                      <div
                        class="activity-me-pevenir mt-3"
                        v-if="
                          !activityToAdd.terminer &&
                            (activityToAdd.type == 'Appel' ||
                              activityToAdd.type == 'Autre')
                        "
                      >
                        <label> Me prévenir : </label><br />
                        <date-picker
                          format="HH:mm"
                          v-model="activityToAdd.me_prevenir"
                          type="time"
                          placeholder="HH:mm"
                        ></date-picker>
                      </div>
                      <!--"Stade" -->
                      <b-form-group
                        class="mt-2"
                        label="Stade"
                        label-for="stage-input"
                      >
                        <Multiselect
                          v-model="activityToAdd.cstage"
                          @input="handleStageChange"
                          id="stage-input"
                          label="name"
                          track-by="id"
                          :showLabels="false"
                          :closeOnSelect="true"
                          :allow-empty="true"
                          :options="currentStages || []"
                          :searchable="true"
                          :internal-search="true"
                          placeholder="-- aucun --"
                          required
                        >
                          <span slot="noResult">Aucun stade trouvée.</span>
                          <span slot="noOptions">Aucun stade trouvée.</span>
                        </Multiselect>
                      </b-form-group>
                      <!--"Sous-stade" -->
                      <b-form-group
                        v-if="activityToAdd.cstage && currentActivitySubStages"
                        class="mt-2"
                        label="Sous-stade"
                        label-for="sub-stage-input"
                      >
                        <Multiselect
                          v-model="activityToAdd.csubstage"
                          id="sub-stage-input"
                          label="name"
                          track-by="id"
                          :showLabels="false"
                          :closeOnSelect="true"
                          :allow-empty="true"
                          :options="currentActivitySubStages || []"
                          :searchable="true"
                          :internal-search="true"
                          placeholder="-- aucun --"
                        >
                          <span slot="noResult">Aucun sous-stade trouvée.</span>
                          <span slot="noOptions"
                            >Aucun sous-stade trouvée.</span
                          >
                        </Multiselect>
                      </b-form-group>
                      <div class="activity-description mt-3">
                        <label>
                          Description :
                        </label>
                        <e-editor
                          ref="activityEditor"
                          v-model="activityToAdd.description"
                        />
                      </div>
                      <div class="activity-user mt-3">
                        <label>
                          Affectée à :
                        </label>
                        <b-form-group>
                          <Multiselect
                            v-model="activityToAdd.user"
                            label="text"
                            track-by="value"
                            :showLabels="false"
                            :closeOnSelect="true"
                            open-direction="bottom"
                            :allow-empty="false"
                            :options="activityUsersList"
                            :searchable="true"
                            :internal-search="true"
                            placeholder="Rechercher"
                            :loading="getProjectsUsersLoading"
                            required
                          >
                            <span slot="noResult"
                              >Aucun utilisateur trouvé.</span
                            >
                            <span slot="noOptions"
                              >Aucun utilisateur trouvé.</span
                            >
                          </Multiselect>
                        </b-form-group>
                      </div>
                      <div class="error" v-if="activityToAddError">
                        {{ activityToAddError }}
                      </div>
                      <div class="action-activity text-right mt-2">
                        <b-button
                          class="btn-annuler"
                          size="sm"
                          @click="cancelActivity"
                        >
                          Annuler
                        </b-button>
                        <b-button
                          class="button-success-activity"
                          variant="success"
                          size="sm"
                          type="submit"
                        >
                          Créer
                        </b-button>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- Ajouter Commentaire -->
                <div
                  class="tab-content-activity bordred"
                  v-if="currentTabRight == 2"
                >
                  <label class="note-label">
                    Vous pouvez ajouter un commentaire ici :
                  </label>
                  <NoteMention
                    ref="eeditor"
                    v-model="commentToAdd"
                    :resetNote="resetNote"
                  />
                  <!-- <e-editor ref="eeditor" v-model="commentToAdd" /> -->
                  <b-form-group
                    label="Catégories de projet"
                    class="label-category-note"
                  >
                    <multiselect
                      class="select-note-category"
                      v-model="selectedCategory"
                      :options="getListCategorieProjet || []"
                      placeholder="Rechercher Catégories"
                      @input="handlSelectedCategory"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="true"
                      label="name"
                      track-by="id"
                      :preselect-first="false"
                      :max-height="100"
                      :allow-empty="false"
                    >
                      <span slot="noResult">Aucun Catégorie trouvé.</span>
                      <span slot="noOptions">Aucun Catégorie trouvé.</span>
                    </multiselect>
                  </b-form-group>
                  <div class="message">
                    <div v-if="getProjectsProcessing" class="loading m-2">
                      Chargement en cours...
                    </div>
                    <div v-if="getProjectsCreateError" class="error">
                      <ul v-if="Array.isArray(getProjectsCreateError)">
                        <li
                          v-for="(e, index) in getProjectsCreateError"
                          :key="index"
                        >
                          {{ e }}
                        </li>
                      </ul>
                      <span v-else>{{ getProjectsCreateError }}</span>
                    </div>
                  </div>
                  <div class="actionNote">
                    <b-button class="btn-annuler" @click="deleteComment">
                      Annuler
                    </b-button>
                    <b-button
                      variant="success"
                      class="btn-valider"
                      @click="addNote"
                    >
                      Enregistrer
                    </b-button>
                  </div>
                </div>
                <!-- Ajouter Fichiers -->
                <div
                  class="tab-content-activity bordred"
                  v-if="currentTabRight == 3"
                >
                  <form @submit.prevent="ajouterFichier">
                    <div class="form-group-file">
                      <div class="content-files">
                        <b-form-file
                          v-model="files"
                          browse-text="Parcourir..."
                          placeholder="Aucun fichier sélectionné"
                          multiple
                          required
                        ></b-form-file>
                      </div>
                      <div
                        v-if="files && files.length"
                        class="file-header-titles"
                      >
                        <div class="file-name-title">Fichiers</div>
                        <div class="file-category-title">Catégories</div>
                        <div class="file-category-title ml-2">
                          sous Catégories
                        </div>
                      </div>
                      <template v-for="(item, index) in files">
                        <div class="files-to-upload" :key="'files-' + index">
                          <p class="file-name" :title="item.name">
                            <font-awesome-icon
                              class="file-upload-icon mr-1"
                              icon="paperclip"
                            />
                            {{ item.name }}
                          </p>
                          <b-form-select
                            v-model="selectCategories[index]"
                            class="file-category"
                            required
                          >
                            <option
                              v-for="(category,
                              index_1) in computedVisibleCategory"
                              :key="'categories-' + index_1"
                              :value="category"
                            >
                              {{ category.name }}
                            </option>
                          </b-form-select>
                          <b-form-select
                            v-if="selectCategories[index]"
                            v-model="selectSubCategories[index]"
                            class="file-category ml-2"
                            required
                          >
                            <option
                              v-for="(subCategory, index_2) in selectCategories[
                                index
                              ].subCategorie"
                              :key="'sous-categories-' + index_2"
                              :value="subCategory"
                            >
                              {{ subCategory.name }}
                            </option>
                          </b-form-select>
                          <b-form-select v-else class="file-category ml-2">
                            <option> </option>
                          </b-form-select>
                        </div>
                      </template>
                    </div>
                    <div class="form-group-button">
                      <div class="actionNote">
                        <b-button class="btn-annuler" @click="handleCancelFile">
                          Annuler
                        </b-button>
                        <b-button
                          variant="success"
                          class="btn-valider"
                          type="submit"
                        >
                          Enregistrer
                        </b-button>
                      </div>
                    </div>
                  </form>
                </div>
                <!-- Ajouter Injection -->
                <div
                  class="tab-content-activity bordred"
                  v-if="currentTabRight == 4"
                >
                  <div
                    v-if="getProjectsSousTypes && getProjectsSousTypes.length"
                  >
                    <div
                      class="content-injection"
                      v-for="(item, index) in getProjectsSousTypes"
                      :key="index"
                    >
                      <div class="inject-type-pixel">
                        <div class="soustype-pixel" title="Pixel">
                          {{ item.name }}
                        </div>
                        <div class="button-injection">
                          <b-button
                            v-if="
                              item && item.pixel_projet_id && item.colorButton
                            "
                            variant="success"
                          >
                            <a
                              v-if="item.pixel_projet_id"
                              :href="
                                `https://crm.pixel-crm.com/Dossiers/Isolation/Fiche/EditMain?id=${item.pixel_projet_id}`
                              "
                              target="_blank"
                              title="Voir Pixel CRM"
                              class="pixel-lien"
                            >
                              Injecté
                              <font-awesome-icon
                                class="icon ml-1"
                                icon="external-link-alt"
                              />
                            </a>
                          </b-button>
                          <b-button
                            v-else
                            variant="danger"
                            @click="handleInjection(item)"
                          >
                            À injecter
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      !getProjectsProcessing &&
                        (!getProjectsSousTypes || !getProjectsSousTypes.length)
                    "
                    class="error-message"
                  >
                    Aucun type de pixel
                  </div>
                  <div class="message">
                    <div v-if="getProjectsProcessing" class="loading m-2">
                      Chargement en cours...
                    </div>
                    <div v-if="getProjectsError" class="error">
                      <ul v-if="Array.isArray(getProjectsError)">
                        <li v-for="(e, index) in getProjectsError" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <span v-else>{{ getProjectsError }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
            <Card :notBody="true">
              <template #title></template>
              <template #body>
                <div class="historique-injection" v-if="currentTabRight == 4">
                  <div class="title-historique">Historique des injections</div>
                  <div
                    v-if="getProjectshistoryInjectionPixelLoading"
                    class="three-dots-loading m-2"
                  >
                    Chargement en cours
                  </div>
                  <WAHistoryInjection
                    :historyPixel="getProjectshistoryInjectionPixel"
                    :readonly="readonly || readonlyEquipe"
                  />
                  <div class="time-line">
                    <div class="time-line-container">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                <div v-else class="historique-activities">
                  <div class="title-historique">
                    Programmée
                  </div>
                  <div class="incoming mt-3">
                    <div
                      v-if="getProjectsProcessingHistory"
                      class="three-dots-loading m-2"
                    >
                      Chargement en cours
                    </div>
                    <ProjectActivity
                      v-for="activity in computedNonTerminer"
                      :entity="activity"
                      :key="'computed-non-terminer-' + activity.id"
                      :project="data"
                      :readonly="readonly || readonlyEquipe"
                      :page="currentPage"
                      :type="type"
                    />
                  </div>
                  <div class="title-historique">
                    Fait
                  </div>
                  <WAHistory
                    :project="data"
                    :readonly="readonly || readonlyEquipe"
                    :type="type"
                  />
                  <div class="time-line">
                    <div class="time-line-container">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
          <!-- sous categories piece jointe -->
          <div
            class="tab-content"
            :class="{ 'not-margin': currentTabLeft == 4 }"
            v-if="currentTabLeft == 4"
          >
            <Card :notBody="true">
              <template #title></template>
              <template #body>
                <div class="label-catg-pj">
                  sous catégories des pièces jointes
                </div>
                <WAFilesSubCategoriePJ
                  :gestionFile="currentTabLeft == 4 ? true : false"
                  :project="data"
                  :readonly="readonly || readonlyEquipe"
                  :selectedNewCategory="
                    selectedNewCategory
                      ? selectedNewCategory
                      : getCategoriesPieceJointeFile &&
                        getCategoriesPieceJointeFile.length
                      ? getCategoriesPieceJointeFile[0]
                      : []
                  "
                />
              </template>
            </Card>
          </div>
          <b-modal
            no-close-on-backdrop
            ref="copy-info-address"
            :hide-footer="true"
            title="Copier les informations de contact "
          >
            <div>
              Êtes-vous sûr de vouloir copier les informations de l'adresse de
              contact ?
            </div>
            <div class="form-actions">
              <b-button
                variant="outline-secondary"
                @click="hideModal('copy-info-address')"
              >
                Annuler
              </b-button>
              <b-button
                variant="success"
                type="submit"
                @click="copyInfoAddress"
              >
                Copier
              </b-button>
            </div>
          </b-modal>

          <b-modal
            no-close-on-backdrop
            ref="copy-info-fiscal"
            id="copy-info-fiscal"
            :hide-footer="true"
            title="Informations fiscals"
          >
            <template
              v-if="
                data.reference_fiscal_avis && data.reference_fiscal_avis.length
              "
            >
              <div>
                Selectionner les informations fiscals qui correspondent à ce
                projet :
              </div>
              <table>
                <tr>
                  <th></th>
                  <th>RE.FISCAL DE L'AVIS</th>
                  <th>REVENU FISCAL</th>
                  <th>ANNÉE</th>
                </tr>
                <tr
                  v-for="(item, index) in data.reference_fiscal_avis"
                  :key="'fiscal-' + index"
                >
                  <td>
                    <b-form-checkbox
                      @input="changeCopyFiscal($event)"
                      v-model="selectedFiscalCopy[index]"
                      :disabled="item.disable"
                      name="fiscal-copy"
                      :value="item"
                    ></b-form-checkbox>
                  </td>
                  <td>{{ item.reference_fiscal }}</td>
                  <td>{{ item.revenu_fiscal }}</td>
                  <td>{{ item.year }}</td>
                </tr>
              </table>
            </template>
            <div v-else>
              Le contact n'a pas des informations sur le revenu fiscal.
            </div>
            <div class="error" v-if="errorCopyFiscal">
              Aucun revenu fiscal selectionné!
            </div>

            <div class="form-actions mt-3">
              <b-button
                variant="outline-secondary"
                @click="hideModal('copy-info-fiscal')"
              >
                Annuler
              </b-button>
              <b-button
                v-if="
                  data.reference_fiscal_avis &&
                    data.reference_fiscal_avis.length
                "
                variant="success"
                @click="copyInfoFiscal"
              >
                Copier
              </b-button>
            </div>
          </b-modal>

          <b-modal
            no-close-on-backdrop
            ref="info-fiscal"
            id="info-fiscal"
            :hide-footer="true"
            title="Informations fiscales"
          >
            <template slot="modal-header-close">
              <b-button size="sm" @click="closeModalInfoFiscal" title="Fermer">
                X
              </b-button>
            </template>
            <div class="msg">
              Voulez vous mettre à jour vos informations client par les
              informations fiscaux .
            </div>
            <div v-if="getProjectsProcessing" class="three-dots-loading mt-1">
              Chargement en cours
            </div>
            <div v-if="getProjectsError" class="error mt-3">
              <ul v-if="Array.isArray(getProjectsError)">
                <li v-for="(e, index) in getProjectsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsError }}</span>
            </div>
            <div class="form-actions mt-2">
              <b-button
                class="btn-fiscal"
                variant="outline-secondary"
                @click="annulerInfoFiscal"
              >
                Non
              </b-button>
              <b-button
                class="btn-fiscal"
                variant="success"
                @click="RemplirInfoFiscal"
              >
                Oui
              </b-button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Card from '../Card'
import SousTypeProjet from '../SousTypeProjet'
import WorkerIcon from '@/components/ui/WorkerIcon'
// import PixelBadge from '../../../components/ui/PixelBadge.vue'
import LoadingComponent from '@/components/ui/LoadingComponent'
import EditableSelect from '@/components/ui/EditableSelect'
import EditableInput from '@/components/ui/EditableInput'
// import EditableInputList from '@/components/ui/EditableInputList'
// import EditableInputListFiscal from '@/components/ui/EditableInputListFiscal'
import EditableCustomFieldCategorie from '@/components/ui/EditableCustomFieldCategorie'
import EditableCustomFieldCategorieHasRegroupement from '@/components/ui/EditableCustomFieldCategorieHasRegroupement'

// import EditableSelectAvecSearch from '@/components/ui/EditableSelectAvecSearch'
import EEditor from '../EEditor'
import Collapse from '../Collapse'
import Multiselect from 'vue-multiselect'
import ProjectActivity from '@/components/ui/ProjectActivity'
import WAHistory from '../WAHistory'
import WAHistoryInjection from '../WAHistoryInjection'
// import WAFiles from '../WAFiles'
import WAFilesSubCategoriePJ from '../WAFilesSubCategoriePJ'
import WAFilesCategoriePJ from '../WAFilesCategoriePJ'
import ProjectComment from '@/components/ui/ProjectComment'
// import InputValue from '@/components/ui/InputValue'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import LeadsForm from './LeadsForm'
// import NoteMention from '../noteMention/NoteMention'
momentDurationFormatSetup(moment)
moment.locale('fr')
export default {
  name: 'project-modal',
  components: {
    NoteMention: () => import('../noteMention/NoteMention'),

    Card,
    SousTypeProjet,
    WorkerIcon,
    // PixelBadge,
    LoadingComponent,
    EditableSelect,
    EditableInput,
    // EditableInputList,
    // EditableInputListFiscal,
    EditableCustomFieldCategorie,
    EditableCustomFieldCategorieHasRegroupement,
    // EditableSelectAvecSearch,
    EEditor,
    Collapse,
    Multiselect,
    ProjectActivity,
    WAHistory,
    WAHistoryInjection,
    // WAFiles,
    WAFilesSubCategoriePJ,
    WAFilesCategoriePJ,
    ProjectComment,
    // InputValue,
    LeadsForm
    // NoteMention
  },
  props: {
    data: {
      default: null
    },
    readonly: {
      default: false
    },
    processing: {
      required: false
    },
    type: {
      required: true
    }
  },
  data() {
    return {
      // update project data
      currentTabLeft: 2,
      currentTabLeftCategrie: 0,
      currentTabRight: 0,
      currentSousTypes: 0,
      currentActivityType: 1,
      activityToAdd: {
        name: '',
        type: 'Autre',
        terminer: null,
        me_prevenir: null,
        description: '',
        date_start: null,
        user: { value: null, text: '' },
        cstage: { value: null, text: '' },
        csubstage: { value: null, text: '' }
      },
      activityToAddError: null,
      planificationSuggestions: null,
      preVisitSuggestions: null,
      commentToAdd: '',
      activityTypes: ['Appel', 'Pré visite', 'Planning', 'Autre'],
      files: [],
      deleteToActivity: null,
      activityHistorique: [],
      uploadPercentage: 0,
      selectCategories: [],
      selectSubCategories: [],
      collapseProject: null,
      collapseContact: null,
      collapseSuggestions: null,
      loading: [],
      nextStage: null,
      nextStageCategory: null,
      nextSubStages: [],
      nextSubStagesCategory: [],
      nextSubStage: null,
      nextSubStageCategory: null,
      nextSubStageError: null,
      nextSubStageCategoryError: null,
      selectedFiscalCopy: [],
      errorCopyFiscal: false,
      error: null,
      currentSousTypesId: null,
      selectedTypeInjecte: [],
      colorButton: [],
      currentPage: 1,
      reset: 0,
      openFormLeadsRapide: false,
      openInputAuto: false,
      selectedCategory: null,
      messageErrorFiscal: [],
      payloadInfoFiscal: null,
      resetNote: false,
      selectedTaxNumber: [],
      selectedRefernceFiscal: [],
      objectInputInfoFiscal: [
        {
          reference_fiscal: null,
          revenu_fiscal: null,
          tax_number: null,
          year: `${Number(new Date().getFullYear()) - 1}`
        },
        {
          reference_fiscal: null,
          revenu_fiscal: null,
          tax_number: null,
          year: `${Number(new Date().getFullYear()) - 1}`
        },
        {
          reference_fiscal: null,
          revenu_fiscal: null,
          tax_number: null,
          year: `${Number(new Date().getFullYear()) - 1}`
        },
        {
          reference_fiscal: null,
          revenu_fiscal: null,
          tax_number: null,
          year: `${Number(new Date().getFullYear()) - 1}`
        },
        {
          reference_fiscal: null,
          revenu_fiscal: null,
          tax_number: null,
          year: `${Number(new Date().getFullYear()) - 1}`
        }
      ],
      selectedNewCategory: null,
      indexCategoryPJ: 0,
      subcategoriePJ: []
    }
  },
  methods: {
    ...mapActions([
      'updateProject',
      'updateContact',
      'fetchPlanningDatesSuggestions',
      'fetchPreVisitDatesSuggestions',
      'createActivity',
      'addComment',
      'addFichier',
      'fetchProjectHistoriques',
      'fetchAllActivities',
      'fetchJournalDesModifications',
      'fetchComments',
      'fetchFiles',
      'updateNote',
      'downloadFile',
      'UpdateFile',
      'updateProjectBash',
      'refrechProject',
      'deleteProject',
      'fetchProjectsByContact',
      'fetchCategorieFiles',
      'fetchCategorieFilesParLength',
      'fetchUsersCommercialsIte',
      'fetchUsersTechniciens',
      'fetchUsersCommercialSedentaire',
      'fetchUsersRespPlan',
      'activeDesactiveSwitchSousCategory',
      'fetchSingleProject',
      'fetchProjectTypesPixel',
      'injectionPixel',
      'fetchProjectsHistoryPixel',
      'fetchProjectsTypePixel',
      'resetErrorProject',
      'resetErrorNoteProject',
      'resetPageValue',
      'fetchPipelinesPipelinesCategory',
      'fetchPipelinesPipelinesPrincipal',
      'resetErrorContact',
      'fetchListCategorieProjets',
      'downloadPdfRecapTravaux',
      'fetchCategoriesPieceJointeFile',
      'fetchNumbersFilesSubCategoriePJ'
    ]),
    selectedNewCategoriePJ(category) {
      this.selectedNewCategory = category
    },
    refreshInputsFiscal() {
      this.selectedRefernceFiscal = []
      this.selectedTaxNumber = []
      if (this.data.cp_reference_fiscal_avis.length) {
        for (
          let index = 0;
          index < this.data.cp_reference_fiscal_avis.length;
          index++
        ) {
          this.selectedRefernceFiscal.push(
            this.data.cp_reference_fiscal_avis[index].reference_fiscal
          )
          this.selectedTaxNumber.push(
            this.data.cp_reference_fiscal_avis[index].tax_number
          )
        }
      }
    },
    async annulerInfoFiscal() {
      if (this.payloadInfoFiscal && this.payloadInfoFiscal.value) {
        this.payloadInfoFiscal.value.remplir_info_client = 0
      }
      const response = await this.updateProject(this.payloadInfoFiscal)
      if (response) {
        this.hideModal('info-fiscal')
      }
    },
    async RemplirInfoFiscal() {
      if (this.payloadInfoFiscal && this.payloadInfoFiscal.value) {
        this.payloadInfoFiscal.value.remplir_info_client = 1
      }
      const response = await this.updateProject(this.payloadInfoFiscal)
      if (response) {
        this.hideModal('info-fiscal')
      }
    },
    enregistreRefFiscal() {
      this.messageErrorFiscal = []
      this.resetErrorProject()
      // INPUTS REFERENCE FISCAL
      if (this.selectedRefernceFiscal && this.selectedRefernceFiscal.length) {
        for (
          let index = 0;
          index < this.selectedRefernceFiscal.length;
          index++
        ) {
          const refrenceFiscal = this.selectedRefernceFiscal[index]
          this.objectInputInfoFiscal[index].reference_fiscal = refrenceFiscal
            ? refrenceFiscal
            : null
        }
      }
      // INPUTS TAX NUMBER
      if (this.selectedTaxNumber && this.selectedTaxNumber.length) {
        for (let index = 0; index < this.selectedTaxNumber.length; index++) {
          const taxNumber = this.selectedTaxNumber[index]
          this.objectInputInfoFiscal[index].tax_number = taxNumber
            ? taxNumber
            : null
        }
      }
      // TESTER LES INPUTS DANS COMPUTEDREFERENCEFISCAL
      if (this.objectInputInfoFiscal && this.objectInputInfoFiscal.length) {
        // if (
        //   !this.objectInputInfoFiscal[0].tax_number &&
        //   !this.objectInputInfoFiscal[0].reference_fiscal
        // ) {
        //   this.messageErrorFiscal.push(` les Champs sont obligatoire`)
        //   return
        // }
        for (
          let index = 0;
          index < this.objectInputInfoFiscal.length;
          index++
        ) {
          const element = this.objectInputInfoFiscal[index]
          if (element.tax_number && !element.reference_fiscal) {
            this.messageErrorFiscal.push(
              ` Champs référence avis ${index + 1} est obligatoire`
            )
            return
          }
          if (!element.tax_number && element.reference_fiscal) {
            this.messageErrorFiscal.push(
              ` Champs numéro fiscal ${index + 1} est obligatoire`
            )
            return
          }
        }
      }
      this.$nextTick(() => {
        if (!this.messageErrorFiscal || !this.messageErrorFiscal.length) {
          const payload = {
            entity: this.data,
            value: {
              cp_reference_fiscal_avis: this.objectInputInfoFiscal.filter(
                (fiscal, key) => {
                  if (fiscal.tax_number && fiscal.reference_fiscal) {
                    fiscal.keyIndex = key + 1
                    return fiscal
                  }
                }
              )
            }
          }
          const validate = this.validateInputs(
            payload.value.cp_reference_fiscal_avis
          )
          if (validate.status === 'error') {
            this.messageErrorFiscal = validate.errors
            return
          }
          if (
            this.objectInputInfoFiscal &&
            this.objectInputInfoFiscal.length &&
            (!this.messageErrorFiscal || !this.messageErrorFiscal.length)
          ) {
            this.$refs['info-fiscal'].show()
            this.payloadInfoFiscal = payload
          }
        }
      })
    },
    validateInputs(data) {
      const validate = {
        status: 'success',
        errors: []
      }
      let tax_number = null
      let reference_avis = null
      if (data && data.length) {
        for (let index = 0; index < data.length; index++) {
          tax_number = this.searchTaxNumber(
            data[index].tax_number,
            data.filter(item => {
              return item.keyIndex != data[index].keyIndex
            })
          )
          reference_avis = this.searchReferenceAvis(
            data[index].reference_fiscal,
            data.filter(item => {
              return item.keyIndex != data[index].keyIndex
            })
          )
          if (tax_number) {
            break
          }
          if (reference_avis) {
            break
          }
        }
      }
      if (tax_number) {
        validate.status = 'error'
        validate.errors.push(
          // ` Ce "Numéro fiscal" existe déjà dans la ligne avis ${tax_number.keyIndex}`
          ` Ce "Numéro fiscal ${tax_number.keyIndex}" existe déjà`
        )
      }
      if (reference_avis) {
        validate.status = 'error'
        validate.errors.push(
          // `Ce "Référence avis" existe déjà dans la ligne avis ${reference_avis.keyIndex}`
          ` Ce "Référence avis ${reference_avis.keyIndex}" existe déjà`
        )
      }
      return validate
    },
    searchReferenceAvis(nameKey, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].reference_fiscal === nameKey) {
          return myArray[i]
        }
      }
    },
    searchTaxNumber(nameKey, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].tax_number === nameKey) {
          return myArray[i]
        }
      }
    },
    async updateProjectFields(payload) {
      const response = await this.updateProject(payload)
      // 32bac4aa228aa42c73ce3024bf40d11f813c783fd255d8a3c3 : champ type Renovation
      if (
        response &&
        payload &&
        payload.field == '32bac4aa228aa42c73ce3024bf40d11f813c783fd255d8a3c3'
      ) {
        // this.fetchCategorieFiles({
        //   type: this.data.type_id,
        //   projet_id: this.data.id
        // })
        if (
          this.getCategoriesPieceJointeFile &&
          this.getCategoriesPieceJointeFile.length
        ) {
          this.fetchCategorieFilesParLength({
            projet_id: this.data.id,
            parent_id: this.getCategoriesPieceJointeFile[0].id
          })
        }
      }
      if (this.getProjectsWarning && this.getProjectsWarning.length) {
        const h = this.$createElement
        // Create the message
        const vNodesMsg = h(
          'div',
          { class: ['d-flex', 'align-items-center'] },
          [
            h('div', { class: ['h1', 'mb-0'] }, [
              h('font-awesome-icon', {
                props: { icon: 'exclamation-circle' }
              })
            ]),
            h('div', { class: ['mb-0', 'ml-2'] }, [
              h(
                'strong',
                this.getProjectsWarning &&
                  Array.isArray(this.getProjectsWarning)
                  ? this.getProjectsWarning.map(item => {
                      return h(
                        'p',
                        { class: ['mb-0'] },
                        item.replace('Avertissement:', '*')
                      )
                    })
                  : this.getProjectsWarning.replace('Avertissement:', ' ')
              )
            ])
          ]
        )
        const vNodesTitleWarning = h(
          'div',
          {
            class: [
              'd-flex',
              'flex-grow-1',
              'justify-content-between',
              'align-items-baseline',
              'mr-3'
            ]
          },
          [
            h('strong', { class: 'mr-2' }, 'Avertissement'),
            h(
              'b-button',
              {
                class: 'toast-color-button-copy-warning'
              },
              [
                h('font-awesome-icon', {
                  props: { icon: 'copy' },
                  on: {
                    click: () => this.copyMessageError(this.getProjectsWarning)
                  }
                })
              ]
            )
          ]
        )
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitleWarning],
          headerClass: 'warning',
          bodyClass: 'warning',
          toastClass: 'warning',
          toaster: 'b-toaster-bottom-right projet',
          variant: 'warning',
          autoHideDelay: 6000
        })
      }
    },
    copyMessageError(text) {
      const el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    openInput(e) {
      this.openInputAuto = e
    },
    async uploadFilePdf() {
      const response = await this.downloadPdfRecapTravaux(this.data)
      if (response) {
        this.$bvToast.toast('Téléchargement effectué avec succès', {
          title: 'Succès',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          solid: true
        })
      } else {
        this.$bvToast.toast(
          "Une erreur s'est produite lors de téléchargement de document",
          {
            title: 'Erreur',
            autoHideDelay: 2000,
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            solid: true
          }
        )
      }
    },
    enterLink(amo, field_date, data, planning) {
      localStorage.setItem(
        planning,
        JSON.stringify({
          agence: field_date.depot_id_planning,
          amo_id: amo,
          date_rdv: field_date.value,
          logistique_travaux: field_date.logistique_travaux
            ? field_date.logistique_travaux
            : null,
          type_travaux: field_date.type_travaux
            ? field_date.type_travaux
            : null,
          type_isolation: field_date.type_isolation
            ? field_date.type_isolation
            : null,
          date_fin: field_date.date_fin_tech_planning
            ? field_date.date_fin_tech_planning
            : null,
          entity: data
        })
      )
    },
    actionFormLeads(e) {
      if (
        (!this.getContactsError || !this.getContactsError.length) &&
        (!this.getProjectsError || !this.getProjectsError.length)
      ) {
        if (e == true) {
          this.openFormLeadsRapide = false
          this.currentTabLeft = 2
        }
      }
    },
    changeTypePixel(type_pixel, object) {
      if (
        type_pixel.id == object.pixel_type_id &&
        type_pixel.name == object.pixel_type
      ) {
        object.colorButton = true
      } else {
        object.colorButton = false
      }
    },
    uploadFile(event) {
      this.files = event.target.files
    },
    handleCancelFile() {
      this.currentTabRight = null
      this.resetInputFile()
    },
    resetInputFile() {
      this.files = []
      this.selectCategories = []
      this.selectSubCategories = []
    },
    async ajouterFichier() {
      const formData = new FormData()
      for (const i of Object.keys(this.files)) {
        formData.append('fichier' + '[' + i + ']' + '[file]', this.files[i])
        formData.append(
          'fichier' + '[' + i + ']' + '[categorie]',
          this.selectSubCategories[i].name
        )
        formData.append(
          'fichier' + '[' + i + ']' + '[category_id]',
          this.selectSubCategories[i].id
        )
      }
      formData.append('projet_id', this.data.id)
      await this.addFichier({
        data: formData,
        project: this.data
      })
      this.resetPageValue({ object: 'tous' })
      this.fetchProjectHistoriques({
        project: this.data,
        page: this.currentPage,
        reset: 1
      })
      if (
        this.getCategoriesPieceJointeFile &&
        this.getCategoriesPieceJointeFile.length
      ) {
        this.fetchCategorieFilesParLength({
          projet_id: this.data.id,
          parent_id: this.getCategoriesPieceJointeFile[0].id
        })
      }
      this.resetInputFile()
    },
    closeModalInfoFiscal() {
      this.$refs['info-fiscal'].hide()
    },
    handleClose() {
      this.data.checked = false
      this.$emit('closeModal', this.data.checked)
    },
    handleCloseFormLead() {
      this.openFormLeadsRapide = false
      this.currentTabLeft = 2
      this.resetErrorContact()
    },
    updateStage(stage, substage) {
      if (this.readonly) {
        return
      }

      if (stage.subStages && stage.subStages.length) {
        this.nextSubStage = null
        this.nextSubStageError = null
        this.nextStage = stage
        this.nextSubStages = stage.subStages
        if (this.nextSubStages.length > 0) {
          // this.nextSubStages[0].id
          this.nextSubStage = substage
        }
        this.$refs['sub-stage-modal'].show()
      } else {
        this.updateProject({
          entity: this.data,
          field: 'cstage_id',
          value: stage.id
        })
      }
    },
    updateStagecategory(stage, substageCategory) {
      if (this.readonly || this.readonlyEquipe) {
        return
      }
      if (stage.subStages && stage.subStages.length) {
        this.nextSubStageCategory = null
        this.nextSubStageCategoryError = null
        this.nextStageCategory = stage
        this.nextSubStagesCategory = stage.subStages
        if (this.nextSubStagesCategory.length > 0) {
          // this.nextSubStagesCategory[0].id
          this.nextSubStageCategory = substageCategory
        }
        this.$refs['sub-stage-cate-modal'].show()
      } else {
        this.updateProject({
          entity: this.data,
          field: 'pipeline_cat_stage_id',
          value: stage.id
        })
      }
    },
    setNewStageWithSubCategory() {
      if (!this.nextSubStageCategory) {
        this.nextSubStageCategoryError = 'Le sous-stade est obligatoire'
        return
      }
      this.resetPageValue({ object: 'tousWithjournal' })
      this.updateProject({
        entity: this.data,
        field: 'pipeline_cat_stage_id',
        value: this.nextStageCategory.id,
        substage: {
          field: 'pipeline_cat_substage_id',
          value: this.nextSubStageCategory
        }
      })
      this.$emit('refreshPipeline')
      this.hideModal('sub-stage-cate-modal')
    },
    async toggleConfirmation(field, color) {
      if (this.readonly || this.readonlyEquipe) {
        return
      }
      let valueField
      if (this.data[field] == 1) {
        valueField = 0
      } else {
        valueField = 1
      }
      this.loading[field] = true
      const payload = {
        entity: this.data,
        field: field,
        value: field != 'status_document' ? valueField : color
      }
      await this.updateProject(payload)
      this.resetPageValue({ object: 'journal' })
      this.loading[field] = false
    },
    async toggleRightTabs(tab) {
      if (this.currentTabRight === tab) {
        this.currentTabRight = 0
        return
      }
      this.currentTabRight = tab
      if (this.currentTabRight == '1') {
        if (
          (!this.getProjectsUsers || !this.getProjectsUsers.length) &&
          !this.getProjectsUsersLoading
        ) {
          if (
            (!this.getProjectsUsersCommercialsIte ||
              !this.getProjectsUsersCommercialsIte.length) &&
            !this.getProjectsUsersCommercialsIteLoading
          ) {
            this.fetchUsersCommercialsIte()
          }
          if (
            (!this.getProjectsUsersTechniciens ||
              !this.getProjectsUsersTechniciens.length) &&
            !this.getProjectsUsersTechniciensLoading
          ) {
            this.fetchUsersTechniciens()
          }
          if (
            (!this.getProjectsUsersCommercialsSedentaires ||
              !this.getProjectsUsersCommercialsSedentaires.length) &&
            !this.getProjectsUsersCommercialSedentaireLoading
          ) {
            this.fetchUsersCommercialSedentaire()
          }
          if (
            (!this.getProjectsUsersRespPlan ||
              !this.getProjectsUsersRespPlan.length) &&
            !this.getProjectsUsersRespPlanLoading
          ) {
            this.fetchUsersRespPlan()
          }
        }
      }
      if (this.currentTabRight == '4') {
        if (this.getProjectsSousTypes && this.getProjectsSousTypes.length) {
          for (
            let index = 0;
            index < this.getProjectsSousTypes.length;
            index++
          ) {
            const type = this.getProjectsSousTypes[index]
            // this.selectedTypeInjecte[index] = {
            //             id: type.pixel_type_id,
            //             name: type.pixel_type
            //           }
            if (type.pixel_projet_id) {
              type.colorButton = true
            } else {
              type.colorButton = false
            }
          }
        }
        // if (
        //   (!this.getProjectsTypesPixels ||
        //     !this.getProjectsTypesPixels.length) &&
        //   !this.getProjectsTypesPixelsLoading
        // ) {
        //   this.fetchProjectTypesPixel()
        // }
        if (this.getProjectsError && this.getProjectsError.length) {
          this.resetErrorProject()
        }
        this.resetActivities()
      }
      if (this.currentTabRight == '2') {
        this.fetchListCategorieProjets()
      }
    },
    handleStageChange() {
      this.activityToAdd.csubstage = { id: -1, name: 'auccun' }
      // this.resetMessages()
    },
    async toggleLeftTabs(tab) {
      this.currentTabLeft = tab
      this.currentTabLeftCategrie = 0
      this.currentTabRight = 0
      if (
        tab == 2 &&
        this.data.categoriesProjets &&
        this.data.categoriesProjets.length &&
        this.data.categoriesProjets[0].id
      ) {
        await this.fetchComments({
          project: this.data,
          page: this.currentPage,
          reset: 1,
          category_id: this.data.categoriesProjets[0].id
        })
      }
      if (tab == 3) {
        this.handleCancelFile()
        this.fetchProjectsTypePixel(this.data.id)
        this.fetchCategoriesPieceJointeFile({
          loading: false,
          projet_id: this.data.id
        })
        this.resetActivities()
      }
      if (tab == 4) {
        if (
          this.getCategoriesPieceJointeFile &&
          this.getCategoriesPieceJointeFile.length
        ) {
          await this.fetchCategoriesPieceJointeFile({
            loading: true,
            projet_id: this.data.id
          })
          this.fetchNumbersFilesSubCategoriePJ(this.data.id)
          this.fetchFiles({
            project: this.data,
            categorie: this.getCategoriesPieceJointeFile[0]
          })
          this.fetchCategorieFilesParLength({
            projet_id: this.data.id,
            parent_id: this.getCategoriesPieceJointeFile[0].id
          })
        }
      }
      if (tab == 5) {
        this.$nextTick(() => {
          this.openFormLeadsRapide = true
        })
      }
    },
    resetActivities() {
      this.fetchProjectHistoriques({
        project: this.data,
        page: this.currentPage,
        reset: 1
      })
      this.$nextTick(() => {
        this.fetchAllActivities({
          project: this.data,
          page: this.currentPage,
          reset: 1
        })
        this.fetchComments({
          project: this.data,
          page: this.currentPage,
          reset: 1
        })
        this.fetchJournalDesModifications({
          project: this.data,
          page: this.currentPage,
          reset: 1
        })
      })
    },
    async toggleLeftTabsCategory(category, item) {
      this.currentTabLeftCategrie = category
      await this.fetchComments({
        project: this.data,
        page: this.currentPage,
        reset: 1,
        category_id: item.id
      })
      if (item.pipeline_cat_id && item.has_pipeline) {
        await this.fetchPipelinesPipelinesCategory(item.pipeline_cat_id)
      }
      // REFRESH INPUT FISCALE
      if (item && item.id == 2) {
        this.refreshInputsFiscal()
      }
    },
    async toggleActivityType(type) {
      this.activityToAdd = {
        terminer: null,
        me_prevenir: null,
        description: '',
        date_start: null,
        user: { value: null, text: '' },
        cstage: { value: null, text: '' },
        csubstage: { value: null, text: '' }
      }
      this.currentActivityType = type
      this.planificationSuggestions = null
      this.preVisitSuggestions = null
      if (type) {
        this.activityToAdd.type = this.activityTypes[type - 1]
      }
      switch (type) {
        case 1:
          this.activityToAdd.name = 'Appel'
          break
        case 2:
          this.activityToAdd.name = 'Pré-visite'
          // this.preVisitSuggestions = await this.fetchPreVisitDatesSuggestions()
          break
        case 3:
          this.activityToAdd.name = 'Planification'
          if (this.data && !this.data.zipCode) {
            this.error = ['Le code postal est obligatoire']
            return
          } else {
            this.error = null
          }
          this.planificationSuggestions = await this.fetchPlanningDatesSuggestions(
            {
              zipCode: this.data.zipCode,
              s: this.data.surface_souffle || 0,
              d: this.data.surface_deroule || 0,
              r: this.data.surface_rampant || 0,
              m: this.data.surface_mur || 0,
              p: this.data.surface_plafond || 0,
              // add this.data.surface_pignon
              vs: this.data.surface_vide_sanitaire || 0
            }
          )
          if (
            this.planificationSuggestions &&
            this.planificationSuggestions.weeks &&
            this.planificationSuggestions.weeks[0]
          ) {
            this.planificationSuggestions.weeks[0].available_dates_hours_techs = this.planificationSuggestions.weeks[0].available_dates_hours_techs.filter(
              day => {
                return moment(day.day).isAfter()
              }
            )
          }
          break
        default:
          this.activityToAdd.name = ''
          break
      }
    },
    cancelActivity() {
      this.currentTabRight = null
      this.resetActivity()
    },
    async handleActivityForm() {
      this.activityToAddError = null

      if (!this.activityToAdd.name) {
        this.activityToAddError = "Le titre de l'activité est obligatoire"
        return
      }
      if (!this.activityToAdd.date_start) {
        this.activityToAddError =
          "La date de début de l'activité est obligatoire"
        return
      }
      if (!this.activityToAdd.user || !this.activityToAdd.user.value) {
        this.activityToAddError =
          "L'activité doit etre affectée à un utilisateur"
        return
      }
      let startDate = null
      let mePrevenirTime = null
      if (this.activityToAdd.me_prevenir) {
        mePrevenirTime =
          this.currentActivityType != 3
            ? moment(this.activityToAdd.me_prevenir, 'YYYY-MM-DD HH:mm').format(
                'HH:mm'
              )
            : moment(this.activityToAdd.me_prevenir).format('HH:mm')
      }

      if (this.activityToAdd.date_start) {
        startDate =
          this.currentActivityType != 3
            ? moment(this.activityToAdd.date_start, 'YYYY-MM-DD HH:mm').format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : moment(this.activityToAdd.date_start).format(
                'YYYY-MM-DD HH:mm:ss'
              )
      }
      const payload = {
        name: this.activityToAdd.name,
        type: this.activityToAdd.type,
        terminer: this.activityToAdd.terminer ? 1 : 0,
        me_prevenir: mePrevenirTime,
        description: this.activityToAdd.description,
        date_start: startDate,
        user_id: this.activityToAdd.user.value,
        projet_id: this.data.id,
        cstage_id: this.activityToAdd.cstage.id,
        csubstage_id: this.activityToAdd.csubstage.id
      }

      await this.createActivity({
        payload: payload,
        project: this.data
      })
      this.resetPageValue({ object: 'activity' })
      this.fetchAllActivities({
        project: this.data,
        page: this.currentPage,
        reset: 1
      })
      this.resetActivity()
      this.fetchProjectHistoriques({
        project: this.data,
        page: this.currentPage,
        reset: 1
      })
      this.refrechProject({
        project: this.data,
        reset: 1
      })
    },
    resetActivity() {
      this.activityToAdd = {
        name: '',
        type: 'Autre',
        terminer: null,
        me_prevenir: null,
        description: '',
        date_start: null,
        user: { value: null, text: '' },
        cstage: { value: null, text: '' },
        csubstage: { value: null, text: '' }
      }
      this.currentActivityType = 1
      this.activityToAddError = null
      this.$refs.activityEditor.reset()
    },
    handlSelectedCategory(category) {
      this.selectedCategory = category
    },
    async addNote() {
      this.resetNote = false
      const payload = {
        comment: this.commentToAdd,
        project: this.data,
        category_id:
          this.selectedCategory && this.selectedCategory.id
            ? this.selectedCategory.id
            : null
      }
      const response = await this.addComment(payload)
      if (response) {
        this.resetPageValue({ object: 'comment' })
        this.fetchProjectHistoriques({
          project: this.data,
          page: this.currentPage,
          reset: 1
        })
        this.fetchComments({
          project: this.data,
          page: this.currentPage,
          reset: 1
        })
        this.commentToAdd = ''
        this.selectedCategory = null
        // this.$refs.eeditor.reset()
        this.resetNote = true
        this.resetErrorNoteProject()
      }
    },
    deleteComment() {
      this.currentTabRight = null
      this.selectedCategory = null
      this.commentToAdd = ''
      this.resetErrorNoteProject()
      // this.$refs.eeditor.reset()
    },
    handleCopyInfoClick() {
      this.$refs['copy-info-address'].show()
    },
    handleCopyFiscalInfoClick() {
      this.selectedFiscalCopy = []
      this.errorCopyFiscal = false
      if (
        this.data.reference_fiscal_avis &&
        this.data.reference_fiscal_avis.length
      ) {
        for (
          let index = 0;
          index < this.data.reference_fiscal_avis.length;
          index++
        ) {
          const copy = this.data.reference_fiscal_avis[index]
          copy.disable = false
        }
      }
      this.$refs['copy-info-fiscal'].show()
    },
    handleFiscalCopyChange() {
      this.errorCopyFiscal = false
    },
    hideModal(ref) {
      if (ref && this.$refs[ref]) {
        this.$refs[ref].hide()
      }
    },
    async copyInfoAddress() {
      this.openInputAuto = false
      const payload = {
        entity: this.data,
        data: {
          zipCode: this.data.zipCode,
          adresse: this.data.adresse,
          city: this.data.city
        }
      }
      const response = await this.updateProjectBash(payload)
      if (response) {
        this.openInputAuto = true
        this.hideModal('copy-info-address')
        this.computedOpenInput(this.openInputAuto)
      }
    },
    changeCopyFiscal(fiscalObject) {
      let checkCopy = fiscalObject.year
      if (fiscalObject) {
        for (
          let index = 0;
          index < this.data.reference_fiscal_avis.length;
          index++
        ) {
          const copy = this.data.reference_fiscal_avis[index]
          if (copy.year == checkCopy) {
            copy.disable = false
          } else {
            copy.disable = true
          }
        }
      } else {
        if (this.selectedFiscalCopy && this.selectedFiscalCopy.length) {
          let checkDisable = this.selectedFiscalCopy.filter(copy => {
            if (!copy.disable) {
              return copy
            }
          })
          if (checkDisable && checkDisable.length) {
            checkCopy = checkDisable[0].year
          } else {
            for (
              let index = 0;
              index < this.data.reference_fiscal_avis.length;
              index++
            ) {
              const copy = this.data.reference_fiscal_avis[index]
              copy.disable = false
            }
          }
        }
      }
    },
    copyInfoFiscal() {
      if (!this.selectedFiscalCopy || !this.selectedFiscalCopy.length) {
        this.errorCopyFiscal = true
        return
      }
      let cp_reference_fiscal_avis = []
      this.selectedFiscalCopy.forEach((fiscalCopy, index) => {
        if (fiscalCopy) {
          cp_reference_fiscal_avis[index] = {
            reference_fiscal: fiscalCopy.reference_fiscal,
            revenu_fiscal: fiscalCopy.revenu_fiscal,
            year: fiscalCopy.year
          }
        } else {
          this.errorCopyFiscal = true
        }
      })
      const payload = {
        entity: this.data,
        value: {
          cp_reference_fiscal_avis: cp_reference_fiscal_avis.filter(fiscal => {
            return fiscal != null
          })
        }
      }
      if (cp_reference_fiscal_avis && cp_reference_fiscal_avis.length) {
        this.updateProject(payload)
        this.hideModal('copy-info-fiscal')
      }
    },
    setNewStageWithSub() {
      if (!this.nextSubStage) {
        this.nextSubStageError = 'Le sous-stade est obligatoire'
        return
      }
      this.resetPageValue({ object: 'tousWithjournal' })
      this.updateProject({
        entity: this.data,
        field: 'cstage_id',
        value: this.nextStage.id,
        substage: {
          field: 'csubstage_id',
          value: this.nextSubStage
        }
      })
      this.$emit('refreshPipeline')
      this.hideModal('sub-stage-modal')
    },
    handleDeleteProject() {
      this.$refs['delete-project'].show()
    },
    async doDeleteProject() {
      const response = await this.deleteProject(this.data)
      if (response) {
        this.hideModal('delete-project')
      }
    },
    changeSousType(sousType) {
      this.currentSousTypes = sousType.name
      this.currentSousTypesId = sousType.id
    },
    async etatSwitch(etat) {
      this.activeDesactiveSwitchSousCategory(etat)
    },
    async handleInjection(objectSousTypes) {
      if (objectSousTypes && objectSousTypes.id && objectSousTypes.pixel_id) {
        const response = await this.injectionPixel({
          // typePixel: this.selectedTypeInjecte[index],
          // subtypes: objectSousTypes.id_subtype,
          project: this.data,
          pixel: objectSousTypes
        })
        if (response) {
          objectSousTypes.colorButton = true
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsProcessing',
      'getProjectsError',
      'getProjectsCreateError',
      'getContactsError',
      'getContactsProcessing',
      'getPipelinesPipelinesPrincipale',
      'getProjectsUsersTechniciens',
      'getProjectsUsersTechniciensLoading',
      'getProjectsUsersCommercialsIte',
      'getProjectsUsersCommercialsIteLoading',
      'getProjectsUsersCommercialsSedentaires',
      'getProjectsUsersCommercialSedentaireLoading',
      'getProjectsUsersRespPlan',
      'getProjectsUsersRespPlanLoading',
      'getProjectsUsers',
      'getProjectsUsersLoading',
      'getProjectsSuggestionsProcessing',
      'getProjectsProcessingHistory',
      'getProjectsCategoriesFichiers',
      'getProjectsFilesProcessing',
      'getPipelinesLoading',
      'getPipelinesCategoryLoading',
      'getProjectsExtraProject',
      'getProjectsTypesPixels',
      'getProjectsTypesPixelsLoading',
      'getProjectshistoryInjectionPixel',
      'getProjectshistoryInjectionPixelLoading',
      'getProjectsSousTypes',
      'getPipelinesPipelinesCateg',
      'getPipelinesPipelines',
      'currentUser',
      'getListCategorieProjet',
      'getProjectsWarning',
      'getCategoriesPieceJointeFile',
      'getCategorieLoading'
    ]),
    computedVisibleCategory: function() {
      if (
        this.getCategoriesPieceJointeFile &&
        this.getCategoriesPieceJointeFile.length
      ) {
        return this.getCategoriesPieceJointeFile.map(item => {
          item.subCategorie = item.subCategorie.filter(ss => {
            return ss.visible == 1
          })
          return item
        })
      }
      return []
    },
    notContentFiscal: function() {
      return this.computedCpReferenceFiscal.filter(
        item => item.reference_fiscal != null && item.tax_number != null
      )
    },
    computedCpReferenceFiscal: function() {
      let currentYear = new Date().getFullYear()
      if (
        this.data &&
        this.data.cp_reference_fiscal_avis &&
        this.data.cp_reference_fiscal_avis.length
      ) {
        let table = []
        if (this.data.cp_reference_fiscal_avis.length < 5) {
          for (
            let index = 0;
            index < 5 - this.data.cp_reference_fiscal_avis.length;
            index++
          ) {
            table[index] = {
              reference_fiscal: null,
              revenu_fiscal: null,
              tax_number: null,
              year: `${Number(currentYear) - 1}`
            }
          }
        }

        let test = [...this.data.cp_reference_fiscal_avis, ...table]
        return test
      } else {
        return this.objectInputInfoFiscal
      }
    },
    computedOpenInput() {
      return function(data) {
        if (data) {
          return true
        }
      }
    },
    currentStages: function() {
      if (this.getPipelinesPipelines.length) {
        for (let x = 0; x < this.getPipelinesPipelines.length; x++) {
          if (
            this.type &&
            this.type.id &&
            this.getPipelinesPipelines[x] &&
            this.getPipelinesPipelines[x].type &&
            this.getPipelinesPipelines[x].type.id &&
            this.getPipelinesPipelines[x].type.id == this.type.id &&
            this.getPipelinesPipelines[x].is_principal &&
            this.getPipelinesPipelines[x].is_principal == 1
          ) {
            return this.getPipelinesPipelines[x].stages
          }
        }
      }
      return null
    },
    currentPipeline: function() {
      if (!this.data) {
        return {}
      }
      let pipeline = {}
      for (const pipe of this.getPipelinesPipelines) {
        if (
          this.data.type_id &&
          pipe.type &&
          pipe.type.id == this.data.type_id &&
          pipe.is_principal == 1
        ) {
          pipeline = pipe
          break
        }
      }
      return pipeline
    },
    currentPipelineCategory: function() {
      if (!this.data) {
        return {}
      }
      let pipeline = {}
      for (let index = 0; index < this.data.categoriesProjets.length; index++) {
        let category = this.data.categoriesProjets[index]
        for (const pipe of this.getPipelinesPipelinesCateg) {
          if (
            category.pipeline_cat_id &&
            pipe &&
            pipe.id == category.pipeline_cat_id
          ) {
            pipeline = pipe
            break
          }
        }
      }
      return pipeline
    },
    currentActivitySubStages: function() {
      if (!this.activityToAdd.cstage) {
        return null
      }
      if (
        this.activityToAdd.cstage.subStages &&
        this.activityToAdd.cstage.subStages.length
      ) {
        return this.activityToAdd.cstage.subStages
      }
      return null
    },
    currentSubStages: function() {
      if (!this.data || !this.data.pipeline) {
        return []
      }
      let subStages = []
      this.getPipelinesPipelines.forEach(pipeline => {
        if (
          this.data.pipeline &&
          pipeline.type &&
          pipeline.id == this.data.pipeline.id
        ) {
          pipeline.stages.forEach(stage => {
            if (this.data.stage && stage.id == this.data.stage.id) {
              subStages = stage.subStages.map(ss => {
                return {
                  text: ss.name,
                  value: ss.id
                }
              })
            }
          })
        }
      })
      return subStages
    },
    currentSubStagesCategory: function() {
      if (!this.data || !this.data.pipeline) {
        return []
      }
      let subStages = []
      for (let index = 0; index < this.data.categoriesProjets.length; index++) {
        let category = this.data.categoriesProjets[index]
        this.getPipelinesPipelinesCateg.forEach(pipeline => {
          if (pipeline.id == category.pipeline_cat_id) {
            pipeline.stages.forEach(stage => {
              if (stage.id == category.pipeline_cat_stage_id) {
                subStages = stage.subStages.map(ss => {
                  return {
                    text: ss.name,
                    value: ss.id
                  }
                })
              }
            })
          }
        })
      }
      return subStages
    },
    computedPoseurs: function() {
      return this.getProjectsUsersTechniciens.map(u => {
        return {
          text: u.full_name,
          value: u.id
        }
      })
    },
    computedCommercialsItinerants: function() {
      return this.getProjectsUsersCommercialsIte.map(u => {
        return {
          text: u.full_name,
          value: u.id
        }
      })
    },
    activityUsersList: function() {
      if (this.activityToAdd.name == 'Pré-visite') {
        return this.computedCommercialsItinerants
      } else if (this.activityToAdd.name == 'Planification') {
        return this.computedPoseurs
      } else {
        return this.getProjectsUsers.map(u => {
          return {
            text: u.full_name,
            value: u.id
          }
        })
      }
    },
    currentPreVisitDates: function() {
      let dates = []
      if (this.preVisitSuggestions) {
        dates = this.preVisitSuggestions
      }
      return dates
    },
    nextPreVisitDates: function() {
      let dates = []
      if (this.preVisitSuggestions) {
        dates = this.preVisitSuggestions
      }
      return dates
    },
    currentPlanningDates: function() {
      let dates = []
      if (this.planificationSuggestions) {
        dates = this.planificationSuggestions
      }
      return dates
    },
    nextPlanningDates: function() {
      let dates = []
      if (this.planificationSuggestions) {
        dates = this.planificationSuggestions
      }
      return dates
    },
    computedNonTerminer: function() {
      if (this.data && this.data.activities) {
        return this.data.activities.filter(t => !t.terminer)
      }
      return []
    },
    computedNoteDesepingle: function() {
      if (this.data && this.data.comments) {
        return this.data.comments.filter(t => t.epingler == 1)
      }
      return []
    },
    computedProjectImage: function() {
      if (this.data.logo) {
        return { 'background-image': `url(${this.data.logo})` }
      }
      return {}
    },
    computedQuestionnerTechnique: function() {
      let tab = []
      // for (let index = 0; index < this.data.categoriesProjets.length; index++) {
      //   if (this.data.categoriesProjets[index].id == 22) {
      //     for (
      //       let index_1 = 0;
      //       index_1 < this.data.categoriesProjets[index].subcategories.length;
      //       index_1++
      //     ) {
      //       if (
      //         this.data.categoriesProjets[index].subcategories[index_1].id == 85
      //       ) {
      //         this.data.categoriesProjets[index].subcategories[
      //           index_1
      //         ].fields.map(f => {
      //           if (f.sub_types && f.sub_types.length) {
      //             for (
      //               let index_2 = 0;
      //               index_2 < f.sub_types.length;
      //               index_2++
      //             ) {
      //               if (
      //                 f.sub_types[index_2].subtype_id ===
      //                 this.currentSousTypesId
      //               ) {
      //                 tab.push(f)
      //               }
      //             }
      //           } else {
      //             tab.push(f)
      //           }
      //         })
      //       }
      //     }
      //   }
      // }
      return tab
    },
    visibilityTypeTeamInstallateurWithOngleActivity: function() {
      if (
        this.currentUser &&
        this.currentUser.details &&
        this.currentUser.details.team &&
        this.currentUser.details.team.data &&
        // installateur
        this.currentUser.details.team.data.type == 'installateur'
      ) {
        return true
      }
      return false
    },
    visibilityInjectePixel: function() {
      // if (
      //   this.data.type_id == 12 ||
      //   this.data.type_id == 46 ||
      //   this.data.type_id == 103
      // ) {
      //   return true
      // }
      // return false
      return true
    },
    visibilityPlanningPoseur() {
      return function(data) {
        if (data && data.icon_planning && data.icon_planning == 'poseur') {
          return true
        } else {
          return false
        }
      }
    },
    visibilityPlanningAmo() {
      return function(data) {
        if (data && data.icon_planning && data.icon_planning == 'commercial') {
          return true
        } else {
          return false
        }
      }
    },
    visibilityPlanningVisiteurTech() {
      return function(data) {
        if (data && data.icon_planning && data.icon_planning == 'vt') {
          return true
        } else {
          return false
        }
      }
    },
    computePlannings: function() {
      if (
        this.currentUser &&
        ((this.currentUser.type == 'user.admin' &&
          this.currentUser.details &&
          !this.currentUser.details.team) ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type === 'regie'))
      ) {
        return false
      } else {
        return true
      }
    },
    visibilityTechTravaux: function() {
      return this.currentUser && this.currentUser.type == 'user.tech.travaux'
    },
    computedAccesAuditIauditor() {
      return function(data) {
        // stade AMO TERRAIN and stade INGÉNIERIE voir Accès à l’audit sur iAuditor
        if (
          data.iauditor_id &&
          [43, 44, 45, 46, 47, 48, 49, 50].includes(data.stage.id)
        ) {
          return true
        } else {
          return false
        }
      }
    },
    computedAccesVTIauditor() {
      return function(data) {
        // a partir de stade VISITE TECHNIQUE jusqu'a derniere stade pou voir Accès à la visite technique sur iAuditorAccès à la visite technique sur iAuditor
        if (
          data.iauditor_technique_id &&
          [45, 46, 47, 48, 49, 50].includes(data.stage.id)
        ) {
          return true
        } else {
          return false
        }
      }
    },
    openHasRegroupemnt() {
      return function(test) {
        if (
          test.data &&
          test.data.categoriesProjets &&
          test.data.categoriesProjets.length
        ) {
          for (
            let index = 0;
            index < test.data.categoriesProjets.length;
            index++
          ) {
            const element = test.data.categoriesProjets[index]
            if (element.id == 6) {
              if (element.subcategories && element.subcategories.length) {
                for (let j = 0; j < element.subcategories.length; j++) {
                  const qqqq = element.subcategories[j]
                  if (qqqq.id == test.sousCategory.id) {
                    for (let x = 0; x < qqqq.fields.length; x++) {
                      const field = qqqq.fields[x]
                      if (
                        field.has_regroupement == 1 &&
                        field.logistique_travaux == null
                      ) {
                        field.editing = test.value
                        if (
                          field.key == 'team_visiteur_tech_id' ||
                          field.key == 'depot_visiteur_tech_id' ||
                          field.key == 'visiteur_tech'
                        ) {
                          field.vt = true
                        }
                      } else if (
                        field.has_regroupement == 1 &&
                        field.logistique_travaux &&
                        test.field.logistique_travaux ==
                          field.logistique_travaux
                      ) {
                        if (field.logistique_travaux == 'Isolation') {
                          if (
                            field.type_isolation == test.field.type_isolation
                          ) {
                            field.editing = test.value
                            field.classlogistique = true
                          }
                        } else {
                          field.editing = test.value
                          field.classlogistique = true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    readonlyEquipe: function() {
      if (
        this.currentUser &&
        this.currentUser.details &&
        this.currentUser.details.team &&
        this.currentUser.details.team.data &&
        // COMMERCE
        (this.currentUser.details.team.data.id == 26 ||
          // AMO
          this.currentUser.details.team.data.id == 25 ||
          // installateur
          this.currentUser.details.team.data.type == 'installateur' ||
          // INGÉNIERIE
          this.currentUser.details.team.data.id == 28 ||
          // FlammeBleue
          this.currentUser.details.team.data.id == 29) &&
        !this.blocageFonctionnaliteProjet
      ) {
        return true
      }
      return false
    },
    blocageFonctionnaliteProjet: function() {
      if (this.data) {
        // NOM D'EQUIPE / COMMERCE + Installateur
        // STADE : LEAD + PROSPECT + AMO TERRAIN
        if (
          this.currentUser &&
          this.currentUser.details &&
          this.currentUser.details.team &&
          this.currentUser.details.team.data &&
          (this.currentUser.details.team.data.id == 26 ||
            this.currentUser.details.team.data.type == 'installateur') &&
          this.data.stage_id &&
          [41, 42, 43].includes(this.data.stage_id)
        ) {
          // STADE : LEAD + PROSPECT
          if ([41, 42].includes(this.data.stage_id)) {
            return true
          } // COMMERCE + INSTALLATEUR
          // STADE : AMO TERRAIN AND SOUS STADE : RDV AMO PLANIFIÉ
          else if (
            this.currentUser &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            (this.currentUser.details.team.data.id == 26 ||
              this.currentUser.details.team.data.type == 'installateur') &&
            this.data.sub_stage_id == 160 &&
            [43].includes(this.data.stage_id)
          ) {
            return true
          } // INSTALLATEUR
          // STADE : AMO TERRAIN AND SOUS STADE : RDV AMO CONFIRMÉ
          else if (
            this.currentUser &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type == 'installateur' &&
            this.data.sub_stage_id == 161 &&
            [43].includes(this.data.stage_id)
          ) {
            return true
          }
        }
        // NOM D'EQUIPE / AMO
        // STADE : PROSPECT + AMO TERRAIN + INGÉNIERIE
        else if (
          this.currentUser &&
          this.currentUser.details &&
          this.currentUser.details.team &&
          this.currentUser.details.team.data &&
          this.currentUser.details.team.data.id == 25 &&
          this.data.stage_id &&
          [42, 43, 44].includes(this.data.stage_id)
        ) {
          // STADE : PROSPECT + AMO TERRAIN
          if ([42, 43].includes(this.data.stage_id)) {
            return true
          } else if (
            // STADE : INGÉNIERIE AND SOUS STADE : AUDIT À FAIRE
            [44].includes(this.data.stage_id) &&
            this.data.sub_stage_id == 166
          ) {
            return true
          }
        }
        // NOM D'EQUIPE / Ingénieur
        // STADE : INGÉNIERIE
        else if (
          this.currentUser &&
          this.currentUser.details &&
          this.currentUser.details.team &&
          this.currentUser.details.team.data &&
          this.currentUser.details.team.data.id == 28 &&
          this.data.stage_id &&
          [44].includes(this.data.stage_id)
        ) {
          return true
        }
        // NOM D'EQUIPE / FlammeBleue
        // STADE : VISITE TECHNIQUE + LOGISTIQUE & TRAVAUX
        else if (
          this.currentUser &&
          this.currentUser.details &&
          this.currentUser.details.team &&
          this.currentUser.details.team.data &&
          this.currentUser.details.team.data.id == 29 &&
          this.data.stage_id &&
          [45, 46].includes(this.data.stage_id)
        ) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    computedLinkPlanningAmo() {
      return function(link) {
        // , amo, field_date, data

        // localStorage.removeItem('selectedAMO')
        // localStorage.setItem(
        //   'amo',
        //   JSON.stringify({
        //     agence: field_date.depot_id_planning,
        //     amo_id: amo,
        //     date_rdv: field_date.value,
        //     entity: data
        //   })
        // )

        return link
      }
    }
  },
  filters: {
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return '--:--'
      return moment(value).format(format)
    },
    duration: function(value) {
      if (value === null || value === undefined) return ''
      if (!value) return '00h00'
      if (!Number.isInteger(value)) {
        value = parseInt(value)
      }
      if (value < 60) return '00h' + value
      value = moment.duration(value, 'minutes').format('HH[h]mm')
      return value
    },
    datetime: function(value, format = 'YYYY-DD-MM HH:mm:ss') {
      if (!value) return ''
      return moment(value, 'YYYY-MM-DD H').format(format)
    },
    filterField: function(value, section) {
      if (!value || !section) return []
      return value.filter(v => {
        return v.category == section
      })
    },
    status: function(value) {
      switch (value) {
        case 'Open':
        case 'open':
          return 'Ouvert'
        case 'Lost':
        case 'lost':
          return 'Perdu'
        case 'Won':
        case 'won':
          return 'Conclu'
      }
      return ''
    },
    formatTel: function(value) {
      if (!value) {
        return 0
      }
      return value.replace(/(\d\d(?!$))/g, '$1 ')
    }
  },
  watch: {
    async $route(route) {
      if (route.name === 'project_activity' && route.params.id) {
        this.data.id = route.params.id
        await this.fetchAllActivities({
          project: this.data,
          page: this.currentPage
        })
        this.$nextTick(async () => {
          await this.fetchProjectHistoriques({
            project: this.data,
            page: this.currentPage
          })
          if (
            this.currentTabLeftCategrie == 0 &&
            this.data &&
            this.data.timeline &&
            this.data.timeline.length &&
            this.data.categoriesProjets &&
            this.data.categoriesProjets.length &&
            this.data.categoriesProjets[0].id
          ) {
            for (let index = 0; index < this.data.timeline.length; index++) {
              const element = this.data.timeline[index]
              if (
                element.object == 'Comment' &&
                element.category_id == this.data.categoriesProjets[0].id
              ) {
                this.fetchComments({
                  project: this.data,
                  page: this.currentPage,
                  reset: 1,
                  category_id: this.data.categoriesProjets[0].id
                })
                break
              }
            }
          }
          if (
            this.getCategoriesPieceJointeFile &&
            this.getCategoriesPieceJointeFile.length &&
            this.getCategoriesPieceJointeFile[0]
          ) {
            this.fetchFiles({
              project: this.data,
              categorie: this.getCategoriesPieceJointeFile[0]
            })
          }
          this.toggleLeftTabs(3)
        })
      }
    }
  },
  async mounted() {
    if (
      this.currentTabLeftCategrie == 0 &&
      this.data.categoriesProjets &&
      this.data.categoriesProjets.length &&
      this.data.categoriesProjets[0].pipeline_cat_id &&
      this.data.categoriesProjets[0].has_pipeline
    ) {
      await this.fetchPipelinesPipelinesCategory(
        this.data.categoriesProjets[0].pipeline_cat_id
      )
    }
    if (
      this.processing === undefined &&
      this.data &&
      this.$route.name == 'projects'
    ) {
      this.data.checked = false
    } else {
      if (this.data && this.data.id && !this.$route.params.id) {
        this.$router.push('/projects/' + this.data.id)
      }
      await this.fetchSingleProject({
        id: this.data.id,
        checked: true
      })
      this.data.checked = true
    }
    if (this.$route.name == 'project' && this.$route.params.id) {
      document.title =
        // NOM_PRENOM
        this.data.contact_full_name +
        '/' +
        // TYPE PROJET
        this.data.type_name +
        '/' +
        //PHONES
        this.$options.filters.formatTel(this.data.phones) +
        ' - CRM Abondance'
    }
    await this.fetchAllActivities({
      project: this.data,
      page: this.currentPage
    })
    this.$nextTick(async () => {
      await this.fetchProjectHistoriques({
        project: this.data,
        page: this.currentPage
      })
      if (
        this.currentTabLeftCategrie == 0 &&
        this.data &&
        this.data.timeline &&
        this.data.timeline.length &&
        this.data.categoriesProjets &&
        this.data.categoriesProjets.length &&
        this.data.categoriesProjets[0].id
      ) {
        for (let index = 0; index < this.data.timeline.length; index++) {
          const element = this.data.timeline[index]
          if (
            element.object == 'Comment' &&
            element.category_id == this.data.categoriesProjets[0].id
          ) {
            this.fetchComments({
              project: this.data,
              page: this.currentPage,
              reset: 1,
              category_id: this.data.categoriesProjets[0].id
            })
            break
          }
        }
      }
      this.fetchProjectsHistoryPixel(this.data.id)
      this.fetchJournalDesModifications({
        project: this.data,
        page: this.currentPage
      })
      await this.fetchCategoriesPieceJointeFile({
        loading: true,
        projet_id: this.data.id
      })
      if (
        this.getCategoriesPieceJointeFile &&
        this.getCategoriesPieceJointeFile.length
      ) {
        this.fetchNumbersFilesSubCategoriePJ(this.data.id)
        this.fetchFiles({
          project: this.data,
          categorie: this.getCategoriesPieceJointeFile[0]
        })
        this.fetchCategorieFilesParLength({
          projet_id: this.data.id,
          parent_id: this.getCategoriesPieceJointeFile[0].id
        })
      }
      // if (this.data && this.data.type_id) {
      //   this.fetchCategorieFiles({
      //     type: this.data.type_id,
      //     projet_id: this.data.id
      //   })
      // }
      if (this.data && this.data.principal_pipeline_id) {
        this.fetchPipelinesPipelinesPrincipal(this.data.principal_pipeline_id)
      }
    })
    if (this.$route.name === 'project_activity') {
      this.toggleLeftTabs(3)
    }
  }
}
</script>

<style lang="scss">
.project-modal {
  position: fixed;
  height: calc(100% - 48px);
  width: 998px;
  top: 48px;
  right: 0;
  z-index: 101;

  .project-modal-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .left-page,
    .right-page {
      position: absolute;
      width: 440px;
      height: 100%;
      top: 0;
      // background-color: #fff;
      background-color: #f7f7f7;
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
      padding: 25px;
      color: #5e5e5e;
      overflow: overlay;
      font-size: 11px;
      .page-content {
        .right-page-header {
          display: flex;
          margin-bottom: 10px;
          .right-page-header-left {
            flex: 3;
            .disabled {
              opacity: 3.5;
              pointer-events: none;
              color: #5e5e5e;
              .icon {
                visibility: hidden;
              }
              &.active {
                color: #2dabe2;
                pointer-events: initial;
                .icon {
                  visibility: visible;
                }
              }
            }
          }
          .right-page-header-right {
            flex: 1;
          }
        }
        .info-contact {
          .project-image {
            width: 85px;
            height: 85px;
            overflow: hidden;
            background-size: contain;
            background-position: 50%;
            background-repeat: no-repeat;
            &.fake {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 30px;
              font-size: 21px;
              font-weight: 700;
              background-color: #eeeeee;
              color: #d5d5d5;
              border-radius: 50px;
            }
          }
          .title {
            width: 70px;
            text-align: right;
          }
          .value {
            width: 170px;
            margin-left: 11px;
            font-size: 10px;
            text-align: left;
          }
          .color-phone {
            color: #2dabe2;
            font-weight: bold;
          }
          .name {
            font-size: 15px;
          }
          .header-type-projet {
            padding-left: 26px;
          }
          .type-projet {
            font-size: 13px;
            padding-top: 5px;
            display: flex;
            .phone {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              margin-left: 6px;
            }
          }
          .pixel {
            color: white;
            background-color: #28a745;
            position: absolute;
            right: 16px;
            left: 319px;
            bottom: 460px;
            border-radius: 13px;
            font-weight: bold;
            cursor: pointer;
            text-align: center;
            a {
              color: #fff;
              text-decoration: none;
            }
            &.isDisabled {
              pointer-events: none;
              a[aria-disabled='true'] {
                cursor: not-allowed;
              }
            }
            &.error {
              background-color: #e73e01;
            }
          }
        }
        &.height-left-page {
          margin-top: 45px;
        }
        &.height-left-page-category {
          margin-top: 15px;
        }
        .status {
          .title {
            text-align: right;
          }
          .value {
            margin-left: 11px;
            text-align: left;
            font-size: 10px;
          }

          .stages {
            margin: 0 0 10px;
            .label {
              padding-bottom: calc(0.375rem + 1px);
              margin-bottom: 0;
              font-size: inherit;
              line-height: 1.5;
              color: #656565;
            }
            .options {
              display: table;
              width: 100%;
              overflow: hidden;
              margin-bottom: 4px;
              // border-radius: 4px;
              border-radius: 15px;
              table-layout: fixed;
              .stage {
                // height: 28px;
                height: 23px;
                cursor: pointer;
                display: table-cell;
                text-align: center;
                padding: 0;
                font-size: 12px;
                font-weight: 700;
                color: #fff;
                // background: #28a745;
                background: #8bc34a;
                position: relative;

                &:before {
                  width: 20px;
                  height: 24px;
                  position: absolute;
                  right: -11px;
                  top: 2px;
                  border-top: 3px solid #fff;
                  border-right: 3px solid #fff;
                  transform: scaleX(0.3) rotate(45deg);
                  content: ' ';
                  // background: #28a745;
                  background: #8bc34a;
                  cursor: pointer;
                  z-index: 1;
                }

                // &.lost,
                // &.red {
                //   background: #ff6961;
                //   &:before {
                //     background: #ff6961;
                //   }
                // }

                // &.orange {
                //   background: #feca1d;
                //   &:before {
                //     background: #feca1d;
                //   }
                // }
                &.red {
                  background: #ff6961;
                  &:before {
                    background: #ff6961;
                  }
                  &.active {
                    background: #ff6961;
                  }
                }
                &.green {
                  background: #8bc34a;
                  &:before {
                    background: #8bc34a;
                  }
                  &.active {
                    background: #8bc34a;
                  }
                }
                &.orange {
                  background: #ff7f00;
                  &:before {
                    background: #ff7f00;
                  }
                  &.active {
                    background: #ff7f00;
                  }
                }
                &.grey {
                  background: #9c9c9c;
                  &:before {
                    background: #9c9c9c;
                  }
                  &.active {
                    background: #9c9c9c;
                  }
                }
                &.yellow {
                  background: #ffe436;
                  &:before {
                    background: #ffe436;
                  }
                  &.active {
                    background: #ffe436;
                  }
                }
                &.black {
                  background: #676767;
                  &:before {
                    background: #676767;
                  }
                  &.active {
                    background: #676767;
                  }
                }
                &.purple {
                  background: #7448c3;
                  &:before {
                    background: #7448c3;
                  }
                  &.active {
                    background: #7448c3;
                  }
                }
                &.blue {
                  background: #2dabe2;
                  &:before {
                    background: #2dabe2;
                  }
                  &.active {
                    background: #2dabe2;
                  }
                }
                input {
                  width: auto;
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  border: none;
                  padding: 0;
                  box-shadow: none;
                  margin-right: 3px;
                }

                &.active {
                  // background: #28a745;
                  background: #8bc34a;
                }

                // &.lost,
                // &.red {
                //   &.active {
                //     background: #ff6961;
                //   }
                // }

                // &.orange {
                //   &.active {
                //     background: #feca1d;
                //   }
                // }

                &.active ~ label {
                  background: #e5e5e5;
                }

                &.active ~ label:before {
                  background: #e5e5e5;
                }
              }
              .stageadministratif {
                // height: 28px;
                height: 23px;
                cursor: pointer;
                display: table-cell;
                text-align: center;
                padding: 0;
                font-size: 12px;
                font-weight: 700;
                color: #fff;
                // background: #28a745;
                background: #2dabe2;
                position: relative;

                &:before {
                  width: 20px;
                  height: 24px;
                  position: absolute;
                  right: -11px;
                  top: 2px;
                  border-top: 3px solid #fff;
                  border-right: 3px solid #fff;
                  transform: scaleX(0.3) rotate(45deg);
                  content: ' ';
                  // background: #28a745;
                  background: #2dabe2;
                  cursor: pointer;
                  z-index: 1;
                }

                &.lost,
                &.red {
                  background: #ff6961;
                  &:before {
                    background: #ff6961;
                  }
                }

                &.orange {
                  background: #feca1d;
                  &:before {
                    background: #feca1d;
                  }
                }

                input {
                  width: auto;
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  border: none;
                  padding: 0;
                  box-shadow: none;
                  margin-right: 3px;
                }

                &.active {
                  // background: #28a745;
                  background: #2dabe2;
                }

                &.lost,
                &.red {
                  &.active {
                    background: #ff6961;
                  }
                }

                &.orange {
                  &.active {
                    background: #feca1d;
                  }
                }

                &.active ~ label {
                  background: #e5e5e5;
                }

                &.active ~ label:before {
                  background: #e5e5e5;
                }
              }
            }

            &.readonly {
              .options {
                .stage {
                  cursor: default;
                  &:before {
                    cursor: default;
                  }
                  input {
                    cursor: default;
                  }
                }
              }
            }
          }
          .stage-container {
            padding-left: 40px;
            .value-stade {
              margin-left: 10px;
              font-size: 10px;
            }
          }
          .sub-stage-value {
            margin-bottom: 2px;
          }
          .icon-upload-pdf {
            margin-left: 20px;
            font-size: 16px;
            margin-top: -3px;
            cursor: pointer;
            &:hover {
              color: #26292c;
            }
          }
          .loader-container {
            height: 28px;
            width: 100%;
            margin: 8px 0px 10px 0px;
          }
        }
        .contact-category {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          .contact-ctaegory-icon {
            width: 22%;
            margin-left: 25px;
            font-size: 40px;
            .img-categorie-localisation {
              margin-left: -14px;
              width: 48px;
            }
            .img-categorie-setting {
              margin-left: -11px;
              width: 45px;
            }
            .img-categorie-hand-dollar {
              margin-left: -17px;
              width: 53px;
              margin-bottom: 3px;
            }
            .img-categorie-calendar {
              margin-left: -11px;
              width: 44px;
              margin-top: 6px;
            }
            .img-categorie-marketing,
            .img-categorie-intervenants,
            .img-categorie-information {
              margin-left: -11px;
              width: 44px;
              margin-top: 6px;
            }
            .img-categorie-tax {
              margin-left: -11px;
              width: 45px;
            }
          }
          .contact-ctaegory-title {
            cursor: pointer;
            padding: 8px;
            width: 100%;
            &.active,
            &:hover,
            &:focus,
            &:active {
              background-color: #6f42c138;
              padding: 8px;
              width: 100%;
              border-start-end-radius: 23px;
              border-end-end-radius: 23px;
            }
          }
        }
        .tab-content {
          .label-catg-pj {
            text-align: center;
            border: 1px solid #d5d5d5;
            font-weight: bold;
            background-color: #f3f3f3;
            text-transform: uppercase;
            margin-bottom: 6px;
          }
          .readonly-input {
            width: 166px;
          }
          .surface-header-th {
            font-weight: bold;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 12px;
            .surface-header-td {
              width: 100px;
              margin-left: 6px;
              border-bottom: 1px solid #d5d5d5;
              text-align: center;
              color: #b0b0b0;
            }
          }
          .fiscal-body-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            .fiscal-body-td {
              width: 95px;
              margin-left: 6px;
              &:first-child {
                width: 120px;
              }
              &:last-child {
                width: 60px;
              }
            }
          }
        }
      }
    }

    .left-page {
      left: 118px;
      transition: opacity 0.3s linear, left 0.7s ease-out;
      padding-top: 0px;
      .left-page-header {
        margin-top: -8px;
        .tabs {
          .tab-button,
          .tab-button-file {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            padding: 2px 0;
            width: 34%;
            user-select: none;
            cursor: pointer;
            background-color: transparent;
            border: 2px solid transparent;
            color: #5e5e5e;
            border-radius: 19px;

            &.width-pj {
              width: 56%;
            }
            .spinner-file,
            .counter-file,
            .icon-warnning-file {
              margin-left: 5px;
            }

            .counter-file {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 18px;
              height: 18px;
              background-color: #e5e5e5;
              color: #5e5e5e;
              border-radius: 12px;
              font-weight: 500;
            }
            .icon-warnning-file {
              color: #feca1d;
              background: #5e5e5e;
              font-size: 16px;
              border-radius: 50%;
            }

            &.active {
              background-color: #6f42c138;
              color: #ffff;
              .counter-file {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;
                background-color: #ffffff;
                color: #5e5e5e;
                border-radius: 12px;
                font-weight: 500;
              }
            }
            &.visible {
              display: none;
            }
            &.full-width {
              width: 366px;
            }
            &.width-cadre-note {
              width: 184px;
            }
            &.width-cadre-file {
              width: 185px;
              border-left: 0px;
            }
          }
          .tab-button-contact {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px 0;
            width: 34%;
            background-color: #e5e5e5;
            border: 1px solid #d5d5d5;
            color: #5e5e5e;
          }
          .link {
            color: #5e5e5e;
            display: flex;
            align-items: center;
            padding: 4px 0;
            width: 100%;
            background-color: #ffffff;
            color: #5e5e5e;
            cursor: pointer;

            &:hover {
              color: #5e5e5e;
              background-color: transparent;
              text-decoration: none;
              font-weight: bold;
            }
            .margin {
              margin-left: 5px;
            }
          }
        }
        .confirmation-items {
          display: flex;
          .custom-dropdown-button {
            padding: 0px;
            color: #fff;
            background-color: transparent;
            border-color: transparent;
            &:focus {
              box-shadow: none;
            }
          }
          .dropdown-item.active,
          .dropdown-item:active {
            color: #212529;
            text-decoration: none;
            background-color: #e5e5e5;
          }
          .item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 27px;
            font-size: 15px;
            margin-left: 10px;
            color: #bfbfbf;
            background-color: #e5e5e5;
            border-radius: 4px;
            user-select: none;
            transition: all 0.4s ease;
            cursor: pointer;

            &.active {
              color: #5e5e5e;
              border: 1px solid #d5d5d5;
              transform: rotateY(180deg);
            }
            &.readonly {
              cursor: default;
            }
            &.red {
              background-color: #fd051d;
              color: #fff;
            }

            &.orange {
              background-color: #ff7f00;
              color: #fff;
            }

            &.green {
              background-color: #8bc34a;
              color: #fff;
            }

            &.yellow {
              background-color: #ffe436;
              color: #fff;
            }
            &.grey {
              background-color: #9c9c9c;
            }
          }
        }
        .line-center-tabs {
          width: 90%;
          margin-top: 6px;
          margin-bottom: 0px;
          border-top: 1px solid #e5e5e5;
        }
      }
      &.left-page-none {
        display: none;
      }
    }
    .right-page-lead {
      position: absolute;
      width: 440px;
      height: 100%;
      top: 0;
      // background-color: #fff;
      background-color: #f7f7f7;
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
      padding: 25px;
      color: #5e5e5e;
      overflow: overlay;
      font-size: 11px;
      .close-form-lead {
        position: absolute;
        font-size: 17px;
        top: 10px;
        right: 33px;
        cursor: pointer;
      }
      &.right-page-none {
        left: 350px;
        width: 650px;
      }
    }
    .right-page {
      left: 558px;
      z-index: 1;
      transition: opacity 0.3s linear, left 0.5s ease-out;
      .title-projet {
        font-weight: bold;
        color: #5e5e5e;
        text-align: center;
        font-size: 15px;
        margin-top: 10px;
      }
      .tabs {
        .tab-button-contact {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 0;
          width: 34%;
          background-color: #e5e5e5;
          border: 1px solid #d5d5d5;
          color: #5e5e5e;
          font-size: 9px;
        }
        .link {
          color: #5e5e5e;
          display: flex;
          align-items: center;
          padding: 4px 0;
          width: 100%;
          background-color: #ffffff;
          color: #5e5e5e;
          cursor: pointer;

          &:hover {
            color: #5e5e5e;
            background-color: transparent;
            text-decoration: none;
            font-weight: bold;
          }
          .margin {
            margin-left: 5px;
          }
        }
      }
      .injected-planning {
        position: absolute;
        right: 58px;
        .disabled {
          opacity: 3.5;
          pointer-events: none;
          color: #5e5e5e;
          .icon {
            visibility: hidden;
          }
          &.active {
            color: #2dabe2;
            pointer-events: initial;
            .icon {
              visibility: visible;
            }
          }
        }
      }
      .title {
        width: 25%;
        text-align: right;
      }
      .block-static {
        display: flex;
        width: 79%;
        text-align: center;
        .block-1,
        .block-2,
        .block-3 {
          .margin-block {
            margin: 0px;
            width: 100%;
          }
          // padding: 10px;
        }
        .block-1 {
          border-right: 2px solid #dfdfdf;
          // margin-left: 34px;
        }
        .block-3 {
          border-left: 2px solid #dfdfdf;
        }
      }
      .title-total-surface {
        width: 23%;
        text-align: right;
      }
      .value-dynamique {
        width: 70%;
        margin-left: 13px;
        text-align: left;
        font-size: 10px;
      }
      .value {
        width: 70%;
        margin-left: 13px;
        text-align: left;
        font-size: 10px;
        .value-total-planif-surface {
          width: 50%;
          margin-left: 34px;
          font-size: 10px;
          text-align: left;
        }
        .value-total-pose-surface {
          font-size: 10px;
        }
      }
      .title-readonly {
        width: 57%;
        text-align: right;
      }
      .value-readonly {
        width: 119%;
        margin-left: 46px;
        text-align: left;
      }
      .tab-content {
        // margin-top: -26px;
        // &.not-margin {
        //   margin-top: 0px;
        // }
        .label-catg-pj {
          text-align: center;
          border: 1px solid #d5d5d5;
          font-weight: bold;
          background-color: #f3f3f3;
          text-transform: uppercase;
          margin-bottom: 6px;
        }
        .title-logistique-travaux {
          font-weight: bolder;
          font-size: 13px;
          text-align: center;
        }
        .line-hr {
          margin-top: 5px;
          margin-bottom: 5px;
        }
        .surface-header-th,
        .puissance-header-th,
        .volume-header-th,
        .prime-header-th {
          font-weight: bold;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 8px;
          font-size: 12px;
          .surface-header-td-1,
          .surface-header-td-2,
          .volume-header-td-1,
          .volume-header-td-2,
          .puissance-header-td-1,
          .puissance-header-td-2,
          .prime-header-td-1,
          .prime-header-td-2 {
            width: 100px;
            color: #5e5e5e;
          }
          .surface-header-td-2 {
            margin-left: 32px;
            margin-right: -4px;
          }
          .puissance-header-td-2 {
            margin-left: 33px;
            margin-right: 2px;
          }
          .volume-header-td-2 {
            margin-left: 23px;
            margin-right: 2px;
          }
          .prime-header-td-2 {
            margin-left: 40px;
            margin-right: 7px;
          }
        }
        .fiscal-flex {
          display: flex;
          align-items: center;
          .label-fiscal {
            width: 28%;
          }
        }
        .btn-bloc-fiscal {
          float: right;
          margin-top: -13px;
          &.flex-block {
            justify-content: space-between;
            display: flex;
            align-items: center;
            float: none;
          }
          .btn-fiscal {
            padding: 4px 8px;
            font-size: 14px;
          }
        }

        .input-fiscal {
          height: 30px;
          font-size: 12px;
        }
        .fiscal-header-th {
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          .fiscal-header-td {
            width: 95px;
            margin-left: 6px;
            font-size: 10px;
            margin-top: 8px;
            // border-bottom: 1px solid #d5d5d5;
            color: #5e5e5e;
            &:first-child {
              width: 120px;
            }
            &:last-child {
              width: 60px;
            }
          }
          &.editable {
            margin-right: 35px;
          }
        }
      }
      .tab-content-activity {
        &.bordred {
          border: 2px solid #d5d5d5;
          border-top-width: 2px;
          margin-right: -3px;
          margin-top: 3px;
        }
        .line-hr {
          margin-top: 0px;
          margin-bottom: 0px;
        }
        .note-label {
          font-weight: 600;
          position: relative;
          margin-left: 23px;
          margin-bottom: 0px;
          margin-top: 2px;
          &:before {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #5e5e5e;
            display: block;
            position: absolute;
            left: -10px;
            top: 8px;
          }
        }
        .select-note-category {
          // margin-left: 6px;
          width: 98%;
          .multiselect__tags,
          .multiselect__single,
          .multiselect__content-wrapper {
            font-size: 12px;
          }
        }
        .label-category-note {
          margin-left: 7px;
          font-size: 13px;
          margin-bottom: 0px;
          legend {
            padding-top: 7px !important;
          }
        }
        .tabs-activity {
          .tab-button,
          .tab-button-file {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px 23.5px;
            width: 25%;
            user-select: none;
            cursor: pointer;
            background-color: #e5e5e5;
            border: 2px solid #d5d5d5;
            color: #5e5e5e;
            font-size: 11px;
            &.active {
              background-color: #40434652;
            }
            &.visibilityPixel {
              width: 100%;
              padding: 4px 39.5px;
            }
            &.visible {
              display: none;
            }
            &.full-width {
              width: 366px;
            }
            &.width-cadre-note {
              width: 184px;
            }
            &.width-cadre-file {
              width: 185px;
              border-left: 0px;
            }
          }
        }
        .activity-form {
          padding: 5px;
          .activity-type {
            .activity-types-tabs {
              margin-top: 5px;
              .activity-types-tab {
                white-space: nowrap;
                padding: 4px 6px;
                font-size: 12px;
                font-weight: bold;
                width: 50%;
                text-align: center;
                user-select: none;
                cursor: pointer;
                background-color: #ebebeb;
                border: 2px solid #d5d5d5;
                color: #5e5e5e;
                &.active {
                  background-color: #fff;
                }
              }
            }
          }
          .activity-date,
          .activity-me-pevenir,
          .activity-description,
          .activity-user {
            label {
              margin-left: 8px;
            }
          }
          .activity-title {
            .form-control {
              font-size: 13px;
            }
          }
          .diponibility {
            display: block;
            .planning-description {
              margin-left: 10px;
              font-size: 12px;
            }
            .suggestions-list {
              display: flex;
              flex-wrap: wrap;
              .suggestion {
                display: block;
                background: #e5e5e5;
                border: 1px solid #d5d5d5;
                padding: 2px 5px;
                margin-right: 10px;
                border-radius: 3px;
                cursor: pointer;
                transition: background 0.5s;
                width: 150px;

                .suggestion-container {
                  display: flex;
                  align-items: center;

                  .date-flag {
                    position: relative;
                    margin-right: 4px;
                    font-size: 14px;
                  }
                }

                &:hover {
                  background: #d5d5d5;
                }

                input {
                  display: none;
                }

                &.active {
                  background: #b0f2b6;
                  border: 1px solid #8fca95;
                }
              }
            }
          }
        }
        .activity-date-interval,
        .activity-me-pevenir {
          .mx-datepicker {
            width: 177px;
            margin-left: 8px;
            .mx-input {
              font-size: 12px;
            }
          }
        }
        .activity-date-interval {
          display: flex;
          align-items: center;
          .checkbox-terminer {
            margin-left: 23px;
            .custom-control-input:checked ~ .custom-control-label::before {
              color: #fff;
              border-color: #8bc34a;
              background-color: #8bc34a;
            }
          }
        }
        .content-injection {
          .inject-type-pixel {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 2px;
            .soustype-pixel {
              padding: 5px;
              background-color: #e5e5e5;
              border: 1px solid #e5e5e5;
              font-weight: bold;
              font-size: 12px;
              text-align: center;
              width: 100%;
            }
            .type-pixel {
              height: 32px;
              width: 100%;
              min-width: 50%;
              .form-group {
                margin-bottom: 0px;
                .multiselect__tags {
                  border-radius: 0px;
                  min-height: 32px;
                  padding: 2px 40px 0 8px;
                  border: 1px solid #e8e8e8;
                  background: #fff;
                  font-size: 12px;
                }
                .multiselect__placeholder {
                  margin-bottom: 0px;
                  padding-top: 0px;
                }
                .multiselect__select {
                  height: 28px;
                }
                .multiselect__single {
                  padding-left: 5px;
                  margin-bottom: 8px;
                  font-size: 13px;
                }
                .multiselect__option {
                  font-size: 13px;
                }
                .multiselect__spinner {
                  height: 25px;
                }
              }
            }
          }
          .button-injection {
            margin-left: 4px;
            width: 32%;
            .btn-danger {
              color: #fff;
              background-color: #ff6961;
              border-color: #ff6961;
              border-radius: 0px;
              width: 94%;
              font-size: 12px;
              &:focus {
                box-shadow: none;
              }
              &:hover {
                color: #fff;
                background-color: #dc3545;
                border-color: #dc3545;
                border-radius: 0px;
              }
            }
            .btn-success {
              color: #fff;
              background-color: #8bc34a;
              border-color: #8bc34a;
              border-radius: 0px;
              font-size: 12px;
              &:focus {
                box-shadow: none;
              }
              .pixel-lien {
                color: #ffffff;
                text-decoration: none;
                background-color: transparent;
                &:hover {
                  color: #ffffff;
                  text-decoration: underline;
                  background-color: transparent;
                }
              }
            }
          }
          .icon-pixel {
            .pixel_id {
              font-size: 15px;
              color: #5e5e5e;
            }
          }
        }
        .historique-injection {
          margin-left: 9%;
          .title-historique {
            text-align: center;
            border: 1px solid #e5e5e5;
            background-color: #e5e5e5;
            border-radius: 15px;
            font-weight: bold;
            padding: 2px;
            width: 90%;
            margin-bottom: 15px;
          }
          .time-line {
            top: 3px;
            left: 26px;
            position: absolute;
            height: 100%;
            width: 1px;
            padding-top: 50px;
            padding-bottom: 20px;
            .time-line-container {
              position: relative;
              height: 100%;
              width: 1px;
              .line {
                position: absolute;
                left: 1px;
                top: 0px;
                bottom: 0px;
                width: 2px;
                z-index: 1;
                background-color: #eff1f3;
              }
            }
          }
        }
        .historique-activities {
          position: relative;
          padding-left: 25px;
          .title-historique {
            text-align: center;
            border: 1px solid #e5e5e5;
            background-color: #e5e5e5;
            border-radius: 15px;
            font-weight: bold;
            padding: 2px;
            width: 90%;
            margin-left: 6%;
          }
          .create-activity {
            .action {
              cursor: pointer;
              margin-top: 10px;
              font-weight: 600;
              color: #2dabe2;
              user-select: none;
            }
            .inputs {
              margin-top: 5px;
              margin-top: 5px;
              background: #e5e5e5;
              padding: 7px;
              .appel {
                display: flex;
                .name {
                  flex-grow: 2;
                }
              }
              .fast-date {
                .btn-group-toggle {
                  display: flex;
                  .btn {
                    span {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 102px;
                      display: block;
                    }
                  }
                }
              }
            }
          }
          .time-line {
            top: 0;
            left: 7px;
            position: absolute;
            height: 100%;
            width: 1px;
            padding-top: 50px;
            padding-bottom: 20px;
            .time-line-container {
              position: relative;
              height: 100%;
              width: 1px;
              .line {
                position: absolute;
                left: 1px;
                top: 0px;
                bottom: 0px;
                width: 2px;
                z-index: 1;
                background-color: #eff1f3;
              }
            }
          }
        }
        .error-message {
          text-align: center;
          color: #ff5722;
        }
      }
      .header-right-page {
        .title-projet,
        .title-categorie {
          position: absolute;
          font-weight: bold;
          color: #5e5e5e;
          &.title-projet {
            width: 83%;
            text-align: center;
            font-size: 15px;
            top: 2px;
          }
          &.title-categorie {
            font-size: 15px;
            top: 20px;
            left: 41px;
          }
        }

        .close {
          position: absolute;
          font-size: 17px;
          top: 10px;
          right: 33px;
          cursor: pointer;
        }
      }
      .copy-info {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        font-weight: 600;
        margin-left: 12px;

        .copy-info-button {
          position: relative;
          display: inline-block;
          background-color: #f9f9f97a;
          border: 1px solid #d8d8d8;
          border-radius: 4px;
          color: #5e5e5e;
          font-size: 20px;
          padding: 4px 6px;
          margin-right: 10px;
          cursor: pointer;

          &:hover {
            background: linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
            background-color: #f6f6f6;
          }
        }
      }
      .hr-fiscal {
        margin-top: 0px;
        margin-bottom: 10px;
      }
      .surface-header-th {
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;
        .surface-header-td {
          width: 100px;
          margin-left: 6px;
          border-bottom: 1px solid #d5d5d5;
          text-align: center;
          color: #b0b0b0;
        }
      }
      .fiscal-body-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        .fiscal-body-td {
          width: 58px;
          margin-left: 6px;
          &:first-child {
            width: 120px;
          }
          &:last-child {
            width: 54px;
          }
        }
      }
    }
    .error {
      color: #ff6961;
    }
    .loader {
      display: block;
      position: absolute;
      opacity: 0;
      left: 118px;
      top: 0;
      width: 100%;
      height: 6px;
      z-index: 2;
      border-bottom: 1px solid #e5e5e5;
      background-color: #ffffff;
      box-sizing: border-box;
      background-image: linear-gradient(
        -45deg,
        #2dabe2 25%,
        transparent 25%,
        transparent 50%,
        #2dabe2 50%,
        #2dabe2 75%,
        transparent 75%,
        transparent
      );
      background-size: 50px 50px;
      animation: move 2s linear infinite, opacity 0.8s ease;
      overflow: hidden;
      box-shadow: inset 0 10px 0 rgba(255, 255, 255, 0.2);
      /*
        Animate the stripes
      */
      &.on {
        opacity: 1;
      }
      &.left-loader {
        left: 350px;
      }
    }
    @keyframes move {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: 50px 50px;
      }
    }
  }
  .uppercase {
    text-transform: uppercase;
  }
  .bold {
    font-weight: bold;
    font-size: 12px;
  }
  .border-top {
    border-top: 1px solid #dee2e6;
  }
  .margin-top {
    margin-top: 20px;
  }
  .italic {
    font-style: italic;
  }
  .text-right {
    text-align: right;
  }
  .justify-between {
    justify-content: space-between;
  }
  .flex {
    display: flex;
  }
  .flex-end {
    justify-content: flex-end;
  }
  .align-center {
    align-items: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .hover-effect-parent {
    &:hover {
      .hover-effect {
        background-color: #ececec;
      }
    }
  }
  .action-activity {
    .btn-annuler {
      color: #5e5e5e;
      margin-right: 5px;
      background-color: #e5e5e5;
      border-color: #f8f9fa;
      &:active {
        color: #5e5e5e;
        background-color: #e5e5e5;
        border-color: #f8f9fa;
      }
    }
    .button-success-activity {
      color: #fff;
      background-color: #8bc34a;
      border-color: #8bc34a;
      &:active {
        color: #fff;
        background-color: #8bc34a;
        border-color: #8bc34a;
      }
    }
  }
  .content-files {
    display: flex;
    padding: 8px;
  }
  .file-header-titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 8px;
    .file-name-title {
      width: 60%;
      margin-right: 10px;
    }
    .file-category-title {
      width: 55%;
    }

    .file-name-title,
    .file-category-title {
      text-align: center;
      color: #5e5e5e;
      font-weight: 600;
      background-color: #d5d5d5;
      border: 2px solid #d5d5d5;
    }
  }
  .files-to-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 8px;
    .file-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 50%;
      margin-right: 10px;
      .file-upload-icon {
        color: #2dabe2;
      }
    }
    .file-category {
      width: 40%;
      font-size: 12px;
    }
  }
}
.tooltip {
  opacity: 1;
}
#copy-info-fiscal {
  .error {
    color: #ff6961;
  }
  table {
    width: 100%;
    margin: 25px 0;
    td,
    th {
      padding: 5px;
      border-bottom: 1px solid #d5d5d5;
      text-align: center;
    }
  }
}
.actionNote {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  .btn-valider,
  .btn-annuler {
    font-size: 11px;
    font-weight: bold;
    &.btn-annuler {
      color: #5e5e5e;
      margin-right: 5px;
      background-color: #e5e5e5;
      border-color: #f8f9fa;
      &:active {
        color: #5e5e5e;
        background-color: #e5e5e5;
        border-color: #f8f9fa;
      }
    }
    &.btn-valider {
      color: #fff;
      background-color: #8bc34a;
      border-color: #8bc34a;
      &:active {
        color: #fff;
        background-color: #8bc34a;
        border-color: #8bc34a;
      }
    }
  }
}
#update-file {
  .icon-update-file {
    content: ' ';
    display: inline-block;
    position: absolute;
    width: 47px;
    height: 47px;
    z-index: 2;
    top: 12px;
    left: 22px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
  }
  .icon-file {
    font-size: 25px;
    position: absolute;
    background-color: #f7f7f7;
    color: #656565;

    &.icon-file {
      left: 14px;
      top: 8px;
    }
  }
  .bloc-file {
    margin-left: 60px;
    text-transform: capitalize;
    padding-bottom: 20px;
  }
  .name-file {
    font-weight: bold;
  }
  .text-file {
    padding-bottom: 21px;
  }
}
#info-fiscal {
  .modal-content {
    width: 114%;
  }
  .btn-fiscal {
    padding: 4px 8px;
    font-size: 14px;
  }
  .msg {
    padding: 5px 0px 10px 4px;
  }
  .btn-secondary {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff;
    font-weight: bold;
    &:hover,
    &:focus {
      box-shadow: unset;
      border-color: transparent;
    }
  }
  .error {
    color: #ff5722;
  }
}
.block-stade {
  display: flex;
  justify-content: space-between;
}
.box-button-show {
  align-self: center;
  .show-button-header {
    background-color: #fbf7a0;
    border: 1px solid #6c757d52;
    text-align: center;
    padding: 1px;
    border-radius: 10px;
    font-weight: bold;
    /* min-width: 78px; */
    max-width: 187px;
    margin-top: 3px;
    margin-left: 180px;
    .iauditor-lien {
      color: #5e5e5e;
      text-decoration: none;
      background-color: transparent;
      &:hover {
        color: #5e5e5e;
        text-decoration: underline;
        background-color: transparent;
      }
    }
    .iauditor-lien-not-readonly {
      color: #5e5e5e;
      text-decoration: none;
      background-color: transparent;
      &:hover {
        color: #5e5e5e;
        background-color: transparent;
      }
    }
    // &.margin {
    //   // margin-left: 150px;
    // }
  }
  .show-button-header-2 {
    background-color: #fbf7a0;
    border: 1px solid #6c757d52;
    text-align: center;
    padding: 1px;
    border-radius: 10px;
    font-weight: bold;
    /* min-width: 78px; */
    max-width: 276px;
    margin-top: 3px;
    margin-left: 128px;
    .iauditor-lien {
      color: #5e5e5e;
      text-decoration: none;
      background-color: transparent;
      &:hover {
        color: #5e5e5e;
        text-decoration: underline;
        background-color: transparent;
      }
    }
    .iauditor-lien-not-readonly {
      color: #5e5e5e;
      text-decoration: none;
      background-color: transparent;
      &:hover {
        color: #5e5e5e;
        background-color: transparent;
      }
    }
  }
}
</style>
