<template>
  <div class="activity-activity">
    <div class="icon-entity-activity">
      <font-awesome-icon
        v-if="entity.type == 'Appel'"
        class="icon-historique-activity"
        icon="phone-alt"
      />
      <font-awesome-icon
        v-if="entity.type == 'Pré visite'"
        class="icon-historique-activity-previsite"
        icon="briefcase"
      />
      <font-awesome-icon
        v-if="entity.type == 'Planning'"
        class="icon-historique-activity"
        icon="truck"
      />
      <font-awesome-icon
        v-if="entity.type == 'Autre'"
        class="icon-historique-activity-autre"
        icon="hourglass-start"
      />
    </div>
    <div class="page-content-activity">
      <div class="description-bloc-activity">
        <div class="activity-name">
          <div class="done-activity">
            <b-form-checkbox
              :value="1"
              :unchecked-value="0"
              v-model="done"
              @change="toggleActivityDone"
              :disabled="readonly"
            >
            </b-form-checkbox>
            <span>{{ entity.name }}</span>
          </div>
          <b-dropdown
            v-if="!readonly"
            size="lg"
            variant="transparent"
            toggle-class="activity-dropdown-button"
            no-caret
            text="..."
          >
            <b-dropdown-item @click="hundleClickUpdateActivity"
              >Modifier</b-dropdown-item
            >
            <b-dropdown-item @click="toggleActivityDone">
              Marquer comme
              {{ entity.terminer ? '« À faire »' : 'terminée' }}
            </b-dropdown-item>
            <b-dropdown-item @click="hundleClickDeleteActivity"
              >Supprimer</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div class="activity-description" v-html="entity.description"></div>
        <div class="informations capitalize">
          <span>
            {{ entity.date_start | date('DD MMM') }}
            {{ entity.date_start | time('HH:mm') }}
          </span>
          -
          <span v-if="entity.user">
            {{ entity.user.full_name }}
          </span>
        </div>
      </div>
    </div>

    <b-modal
      id="Modifier-activite"
      title="Modifier l'activité"
      no-close-on-backdrop
      ref="Modifier-activite"
      :hide-footer="true"
    >
      <FormActivityProject
        :activity="activity"
        :project="project"
        :type="type"
      />
      <div v-if="error" class="error">
        <ul v-if="Array.isArray(error)">
          <li v-for="(e, index) in error" :key="index">
            {{ e }}
          </li>
        </ul>
        <span v-else>{{ error }}</span>
      </div>
      <div class="form-actions">
        <b-button
          variant="outline-secondary"
          @click="hideModal('Modifier-activite')"
        >
          Annuler
        </b-button>
        <b-button variant="success" type="submit" @click="hundleClickUpdate">
          Enregistrer
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="Supprimer-activite"
      no-close-on-backdrop
      ref="Supprimer-activite"
      :hide-footer="true"
      title="Supprimer l'activité"
    >
      <div class="text">
        Êtes-vous sûr de vouloir supprimer cette activité ?
      </div>
      <div class="form-actions">
        <b-button
          variant="outline-secondary"
          @click="hideModal('Supprimer-activite')"
        >
          Annuler
        </b-button>
        <b-button variant="danger" type="submit" @click="SupprimerActivity">
          Supprimer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FormActivityProject from './FormActivityProject'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
moment.locale('fr')
import { mapGetters, mapActions } from 'vuex'
export default {
  components: { FormActivityProject },
  props: {
    entity: { required: true },
    project: { required: false },
    readonly: {
      default: false
    },
    page: { required: false },
    type: {
      required: false
    }
  },
  data() {
    return {
      done: this.entity.terminer,
      activity: { ...this.entity },
      reset: 1,
      error: null
    }
  },
  methods: {
    ...mapActions([
      'updateActivity',
      'deleteActivity',
      'toggleActivity',
      'refrechProject',
      'fetchProjectHistoriques',
      'fetchJournalDesModifications',
      'resetPageValue',
      'fetchAllActivities',
      'fetchUsersCommercialsIte',
      'fetchUsersTechniciens',
      'fetchUsersCommercialSedentaire',
      'fetchUsersRespPlan',
      'fetchListNotification'
    ]),
    hideModal(ref) {
      this.$refs[ref].hide()
      this.error = null
    },
    async toggleActivityDone() {
      if (this.readonly) {
        return
      }

      this.done = this.entity.terminer ? 0 : 1
      await this.toggleActivity({
        value: this.entity.terminer ? 0 : 1,
        activity: this.entity,
        project: this.project
      })
      this.resetPageValue({ object: 'activity' })
      this.fetchProjectHistoriques({
        project: this.project,
        page: 1,
        reset: 1
      })
      this.fetchAllActivities({
        project: this.project,
        page: this.page,
        reset: 1
      })
      this.refrechProject({
        project: this.project,
        reset: 1
      })
      this.fetchListNotification(this.currentUser.id)
    },
    async hundleClickUpdateActivity() {
      this.activity = { ...this.entity }
      this.$refs['Modifier-activite'].show()
      if (
        (!this.getProjectsUsers || !this.getProjectsUsers.length) &&
        !this.getProjectsUsersLoading
      ) {
        await this.fetchUsersCommercialsIte()
        await this.fetchUsersTechniciens()
        await this.fetchUsersCommercialSedentaire()
        await this.fetchUsersRespPlan()
      }
    },
    async hundleClickUpdate() {
      this.activity.date_start = moment(
        this.activity.date_start,
        'YYYY-MM-DD HH:mm'
      ).format('YYYY-MM-DD HH:mm:ss')
      if (this.activity.me_prevenir) {
        this.activity.me_prevenir = moment(
          this.activity.me_prevenir,
          'HH:mm'
        ).format('HH:mm')
      }

      const toggle = this.entity.terminer != this.activity.terminer
      let response = await this.updateActivity({
        toggle: toggle,
        entity: this.entity,
        activity: this.activity,
        project: this.project
      })
      if (response.succes == false) {
        this.error = response.error
      } else {
        this.hideModal('Modifier-activite')
        this.resetPageValue({ object: 'activity' })
        this.fetchAllActivities({
          project: this.project,
          page: this.page,
          reset: 1
        })
        this.fetchProjectHistoriques({
          project: this.project,
          page: 1,
          reset: 1
        })
        this.refrechProject({
          project: this.project,
          reset: 1
        })
        this.fetchListNotification(this.currentUser.id)
      }
    },
    hundleClickDeleteActivity() {
      this.$refs['Supprimer-activite'].show()
    },
    SupprimerActivity() {
      this.deleteActivity({
        activity: this.entity,
        project: this.project
      })
      this.resetPageValue({ object: 'activity' })
      this.fetchProjectHistoriques({
        project: this.project,
        page: 1,
        reset: 1
      })
      this.fetchAllActivities({
        project: this.project,
        page: 1,
        reset: 1
      })
      this.refrechProject({
        project: this.project,
        reset: 1
      })
      this.fetchListNotification(this.currentUser.id)
      this.hideModal('Supprimer-activite')
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsUsersLoading',
      'getProjectsUsers',
      'currentUser'
    ])
  },
  filters: {
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return '--:--'
      return moment(value).format(format)
    }
  }
}
</script>

<style lang="scss">
.activity-activity {
  .done-activity {
    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #8bc34a;
      background-color: #8bc34a;
    }
    .custom-checkbox {
      .custom-control-label {
        &:before {
          border-radius: 10px;
        }
      }
    }
  }
  .activity-description {
    p {
      margin: 0;
    }
  }

  .activity-dropdown-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
    padding: 9px;
    width: 28px;
    height: 25px;
    line-height: 0;
    box-shadow: none !important;
  }
}
</style>
<style lang="scss" scoped>
.page-content-activity {
  padding-bottom: 9px;
}
.error {
  padding: 25px;
  color: #ff5722;
}
.activity-activity {
  position: relative;
  .activity-name {
    color: #5e5e5e;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .done-activity {
      display: flex;
      align-items: center;
    }
  }
  .informations {
    font-weight: 600;
  }
}
.description-bloc-activity {
  position: relative;
  height: auto;
  font-size: 12px;
  margin-top: 6px;
  padding: 8px 15px;
  background-color: #f9f9f97a;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin-left: 26px;
  &.contact {
    margin-top: 50px;
    padding: 38px 20px;
  }
  &:before {
    content: '';
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #d8d8d8;
    left: -9px;
    top: 20px;
  }
  &:after {
    content: '';
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fcfcfc;
    left: -8px;
    top: 20px;
  }
}

.icon-entity-activity {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 35px;
  height: 35px;
  z-index: 2;
  top: 8px;
  left: -34px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 50%;
  font-size: 16px;
}

#Supprimer-activite {
  .text {
    padding-bottom: 10px;
  }
}
</style>
