<template>
  <div class="editable-input">
    <div class="edit-view" v-if="field.editing == true">
      <dynamicInputHasRegroupement
        :input="{
          type: field.input_type,
          options: field.options ? field.options.data : [],
          name: field.key,
          links: field.links ? field.links : [],
          selected: field.selection_type,
          valuePossible: field.possible_values
            ? field.possible_values.data
            : [],
          formatDate: field.format ? true : false,
          message: computedButtonValider,
          sousCategory: sousCategory,
          hasRegroupementSelect: field.has_regroupement,
          logistiqueTravaux: field.logistique_travaux,
          typeIsolation: field.type_isolation,
          parent: field.parent,
          tableParent:
            computedHasRegroupementSelect &&
            computedHasRegroupementSelect.length
              ? computedHasRegroupementSelect
              : [],
          project: entity
        }"
        v-model="newValue"
        @save="update($event, false)"
      ></dynamicInputHasRegroupement>
      <div
        class="edit-action flex justify-content-end mt-1"
        v-if="
          computedAffichageButtonsActions &&
            computedAffichageButtonsActions.key == field.key
        "
      >
        <div @click="close">Annuler</div>
        <div
          @click="update(null, true)"
          v-if="computedButtonValider"
          class="green ml-2"
        >
          Enregistrer
        </div>
      </div>
    </div>
    <div class="show-view" v-else>
      <!-- multiple_select && type de selection multiple  -->
      <div
        v-if="
          field.input_type === 'multiple_select' &&
            field.selection_type === 'multiple'
        "
        class="value mr-2"
        :class="{ 'auto-width': autoWidth }"
      >
        <!-- set -->
        {{ Array.isArray(value) && value.length ? value.join(', ') : '-' }}
      </div>

      <div v-else class="value mr-2" :class="{ 'auto-width': autoWidth }">
        {{
          value && !value.name ? value : value && value.name ? value.name : '-'
        }}
      </div>
      <div v-if="!readonly" class="show-action" @click="openInput">
        <font-awesome-icon class="icon" icon="pencil-alt" />
      </div>
      <div
        class="show-action ml-1"
        v-if="!readonly && field.pre_rempli_field"
        @click="envoiCurrentUser"
      >
        <DoightLeveIcon></DoightLeveIcon>
      </div>
    </div>
  </div>
</template>

<script>
import dynamicInputHasRegroupement from '@/components/ui/dynamic-form/dynamic-input-has-regroupement.vue'
import DoightLeveIcon from '@/components/ui/DoightLeveIcon.vue'
import { mapGetters } from 'vuex'

// import moment from 'moment'
export default {
  name: 'EditableCustomFieldCategorie',
  components: {
    dynamicInputHasRegroupement,
    DoightLeveIcon
  },
  props: {
    field: { required: true },
    entity: { required: true },
    value: { required: false },
    updateFunction: { required: true },
    autoWidth: { default: false },
    readonly: { default: false },
    sousCategory: { required: false },
    category: { required: false }
  },
  data() {
    return {
      newValue: this.value,
      fieldAction: null,
      valueGroupe: [],
      egaliteObject: false
    }
  },
  mounted() {
    this.$emit('openHasRegroupemnt', {
      sousCategory: this.sousCategory,
      data: this.entity,
      value: false,
      field: this.field,
      order: this.sousCategory.order
    })
    if (this.field.input_type === 'multiple_select') {
      this.value = this.field.value
    }
  },
  methods: {
    envoiCurrentUser() {
      const payload = {
        entity: this.entity
      }
      let field_group = [
        {
          key: this.field.key,
          value: this.currentUser.id
        },
        {
          key: 'pre_rempli_field',
          value: this.field.pre_rempli_field == 1 ? 1 : 0
        }
      ]
      payload.field = field_group
      payload.value = field_group
      //  pour tester ce fields dans file projet js
      payload.field_group = true
      this.updateFunction(payload)
    },
    openInput() {
      this.$emit('openHasRegroupemnt', {
        sousCategory: this.sousCategory,
        data: this.entity,
        value: true,
        field: this.field,
        order: this.sousCategory.order
      })
    },
    close() {
      this.$emit('openHasRegroupemnt', {
        sousCategory: this.sousCategory,
        data: this.entity,
        value: false,
        field: this.field,
        order: this.sousCategory.order
      })
    },
    update(payload, value) {
      if (!value) {
        localStorage.setItem('payloadRegrup', JSON.stringify(payload))
      } else {
        if (JSON.parse(localStorage.getItem('payloadRegrup'))) {
          let newValue = JSON.parse(localStorage.getItem('payloadRegrup'))
          const payload = {
            entity: this.entity,
            field: this.field.key
          }
          // envoyer dans body
          if (this.field.logistique_travaux) {
            payload.logistique_travaux = this.field.logistique_travaux
          }
          if (this.field.type_travaux) {
            payload.type_travaux = this.field.type_travaux
          }
          if (this.field.type_isolation) {
            payload.type_isolation = this.field.type_isolation
          }

          // TYPE INPUT GROUPE SELECT
          //  has regroupement select
          if (this.field.has_regroupement == 1) {
            let field_group = [
              {
                key: newValue.key_1,
                value:
                  newValue.value_1 && typeof newValue.value_1 == 'object'
                    ? newValue.value_1.id
                    : newValue.value_1
              },
              {
                key: newValue.key_2,
                value:
                  newValue.value_2 && typeof newValue.value_2 == 'object'
                    ? newValue.value_2.id
                    : newValue.value_2
              },
              {
                key: newValue.key_3,
                value:
                  newValue.value_3 && typeof newValue.value_3 == 'object'
                    ? newValue.value_3.id
                    : newValue.value_3
              }
            ]
            payload.field = field_group
            payload.value = field_group
            //  pour tester ce fields dans file projet js
            payload.field_group = true
          }
          const response = this.updateFunction(payload)
          if (response) {
            localStorage.removeItem('payloadRegrup')
          }
        } else {
          this.$emit('openHasRegroupemnt', {
            sousCategory: this.sousCategory,
            data: this.entity,
            value: false,
            field: this.field,
            order: this.sousCategory.order
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    computedButtonValider: function() {
      if (
        this.field.input_type == 'radio' &&
        (!this.field.possible_values ||
          !this.field.possible_values.data ||
          !this.field.possible_values.data.length)
      ) {
        return false
      } else {
        return true
      }
    },
    computedHasRegroupementSelect: function() {
      let table = []
      if (this.sousCategory.fields && this.sousCategory.fields.length) {
        for (let index = 0; index < this.sousCategory.fields.length; index++) {
          if (this.sousCategory.fields[index].has_regroupement == 1) {
            table.push(this.sousCategory.fields[index])
          }
        }
      }

      return table
    },
    computedAffichageButtonsActions: function() {
      let table = []
      let tableIndex = []
      if (this.sousCategory.fields && this.sousCategory.fields.length) {
        for (let index = 0; index < this.sousCategory.fields.length; index++) {
          if (this.sousCategory.fields[index].has_regroupement == 1) {
            table.push(this.sousCategory.fields[index])
          }
        }
        if (table && table.length) {
          for (let index = 0; index < table.length; index++) {
            if (table.length - 1 == index) {
              tableIndex = table[index]
            }
          }
        }
      }

      return tableIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.editable-input {
  width: 65%;
  .edit-view {
    display: block;
    .border-input {
      border: 1px solid #e5e5e5;
    }
    .edit-action {
      margin-left: 187px;
      cursor: pointer;
      div {
        color: #5e5e5e;
        border: 1px solid #a9adb1;
        user-select: none;
        margin: 0;
        padding: 0px 2px 0px 2px;
        border-radius: 2px;
        font-size: 11px;
        font-weight: 600;
        &.green {
          background: #8bc34a;
          color: #fff;
          border: 1px solid #a9adb1;
          &:hover {
            background: #6bc00a;
            color: #fff;
            border: 1px solid #a9adb1;
          }
        }
      }
      &.button-phone-emails {
        margin-left: 260px;
      }
    }
  }
  .show-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      .show-action {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #bdbdbd;
        border-radius: 4px;
        border: 1px solid #bdbdbd;
        width: 25px;
        height: 20px;
      }
    }

    .show-action {
      display: none;
      cursor: pointer;
      &:hover {
        border: 1px solid #404346 !important;
        color: #404346 !important;
      }
    }

    .value {
      width: 130px;
      &.auto-width {
        width: auto;
      }
    }

    .value {
      width: 140px;
      &.auto-width {
        width: auto;
      }

      .subventions-sub-field {
        font-size: 11px;
        line-height: 14px;
        padding-bottom: 5px;
        padding-left: 5px;
        border-left: 2px solid #d5d5d5;
      }
      .item {
        .width-value {
          width: 200px;
          .value-phones,
          .value-emails {
            color: #5e5e5e;
            &.value-emails {
              text-decoration: underline;
            }
            &.value-phones {
              text-decoration: none;
            }
          }
          &:hover {
            width: 180px;
          }
        }
      }
    }
  }
}
</style>
