<template>
  <div>
    <form-wizard
      @on-complete="onComplete"
      @on-loading="setLoading"
      @on-error="handleErrorMessage"
      class="wizard"
      shape="circle"
      color="#2dabe2"
      error-color="#ff6961"
      title="Modification de projet"
      :subtitle="subtitle"
      ref="wizard"
    >
      <!-- Coordonnées -->
      <tab-content
        title="Coordonnées"
        icon="ti-user"
        :before-change="validateClientInfo"
      >
        <!-- <label class="tab-content-label">Informations de client : </label> -->
        <!-- Nom -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Nom"
          label-for="input-last-name"
        >
          <b-form-input
            v-model="form.contact.lastName"
            id="input-last-name"
            size="sm"
            :disabled="readonly"
          ></b-form-input>
        </b-form-group>
        <!-- Prénom -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Prénom"
          label-for="input-first-name"
        >
          <b-form-input
            v-model="form.contact.firstName"
            id="input-first-name"
            size="sm"
            :disabled="readonly"
          ></b-form-input>
        </b-form-group>
        <!-- E-mail -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="E-mail"
          label-for="input-mail"
        >
          <b-form-input
            v-model="form.contact.email"
            type="email"
            id="input-mail"
            size="sm"
            :disabled="readonly"
          ></b-form-input>
        </b-form-group>
        <!-- Téléphone -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Téléphone"
          label-for="input-phone"
        >
          <input
            :disabled="readonly"
            class="input-phone"
            v-model="form.contact.phone"
            id="input-phone"
            size="sm"
            spellcheck="false"
            maxlength="10"
            @input="
              form.contact.phone = form.contact.phone
                .replace(/[^0-9]/g, '')
                .replace(/(\..*)\./g, '$1')
            "
          />
        </b-form-group>
        <!-- Code postal -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Code postal"
          label-for="input-cp"
        >
          <b-form-input
            :disabled="readonly"
            v-model="form.contact.codePostal"
            id="input-cp"
            size="sm"
            type="number"
          ></b-form-input>
        </b-form-group>
        <!-- Ville -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Ville"
          label-for="input-city"
        >
          <b-form-input
            :disabled="readonly"
            v-model="form.contact.city"
            id="input-city"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Address -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Adresse"
          label-for="input-address"
        >
          <b-form-input
            :disabled="readonly"
            v-model="form.contact.address"
            id="input-address"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Source -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Source"
          label-for="input-source"
          v-if="typeProjet && typeProjet.id != 17"
        >
          <b-form-select
            :disabled="readonly"
            v-model="form.contact.source"
            :options="getProjectsUsersSources"
            id="input-source"
            size="sm"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>
      </tab-content>
      <!-- Civilité -->
      <tab-content
        title="Civilité"
        icon="ti-layout-cta-left"
        v-if="typeProjet && typeProjet.id != 17"
      >
        <!-- Nom de naissance -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Nom de naissance"
          label-for="input-birth-name"
        >
          <b-form-input
            :disabled="readonly"
            v-model="form.contact.nomNaissance"
            id="input-last-birth-name"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Date de naissance -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Date de naissance"
          label-for="input-birthday"
        >
          <b-form-input
            :disabled="readonly"
            v-model="form.contact.dateNaissance"
            type="date"
            id="input-birthday"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Lieu de naissance -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Lieu de naissance"
          label-for="input-birth_city"
        >
          <b-form-input
            :disabled="readonly"
            v-model="form.contact.lieuNaissance"
            id="input-birth_city"
            size="sm"
          ></b-form-input>
        </b-form-group>
      </tab-content>
      <!-- Informations Logement -->
      <tab-content
        title="Informations Logement"
        icon="ti-files"
        v-if="typeProjet && typeProjet.id != 17"
      >
        <!-- Occupation -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Occupation"
          label-for="input-occupation"
        >
          <b-form-select
            :disabled="readonly"
            v-model="form.projet.occupation"
            :options="
              [
                { text: '', value: null },
                { text: 'Locataire', value: 'Locataire' },
                {
                  text: 'Propriétaire occupant',
                  value: 'Propriétaire_occupant'
                },
                {
                  text: 'Propriétaire Bailleur',
                  value: 'Propriétaire_Bailleur'
                }
              ] || []
            "
            id="input-occupation"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- Type de logement -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Type de logement"
          label-for="input-type_logement"
        >
          <b-form-select
            :disabled="readonly"
            v-model="form.projet.type_logement"
            :options="
              [
                { text: '', value: null },
                { text: 'Maison individuelle', value: 'Maison individuelle' },
                { text: 'Maison co-propriété', value: 'Maison co-propriété' },
                { text: 'Appartement', value: 'Appartement' }
              ] || []
            "
            id="input-type_logement"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- Parcelle Cadastrale -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Parcelle Cadastrale"
          label-for="input-parcelle_cadastrale"
        >
          <b-form-input
            :disabled="readonly"
            v-model="form.projet.parcelle_cadastrale"
            id="input-parcelle_cadastrale"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Type de chauffage -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Type de chauffage"
          label-for="input-type_chauffage"
        >
          <b-form-select
            :disabled="readonly"
            v-model="form.projet.type_chauffage"
            :options="
              [
                { text: '', value: null },
                { text: 'Fioul', value: 'Fioul' },
                { text: 'Gaz', value: 'Gaz' },
                { text: 'Bois', value: 'Bois' },
                { text: 'Électricité', value: 'Électricité' }
              ] || []
            "
            id="input-type_chauffage"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- Type d'énergie -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Type d'énergie"
          label-for="input-type_energy"
        >
          <b-form-select
            :disabled="readonly"
            v-model="form.projet.type_energy"
            :options="
              [
                { text: '', value: null },
                { text: 'Fioul', value: 'Fioul' },
                { text: 'Bois', value: 'Bois' },
                { text: 'Granule', value: 'Granule' },
                { text: 'Électrique', value: 'Électrique' },
                { text: 'autres', value: 'autres' }
              ] || []
            "
            id="input-type_energy"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- Déjà profité ISO1€ -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Déjà profité ISO1€"
          label-for="input-profite_iso"
        >
          <b-form-checkbox-group
            :disabled="readonly"
            v-model="form.projet.profite_iso"
            :options="[
              { text: 'Oui', value: 'Oui' },
              { text: 'Non', value: 'Non' }
            ]"
            name="input-profite_iso"
            plain
            @change="handleRadio(form.projet.profite_iso, 'profite_iso')"
          ></b-form-checkbox-group>
        </b-form-group>
        <!-- Déjà changé chauffage -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Déjà changé chauffage"
          label-for="input-change_chauffage"
        >
          <b-form-checkbox-group
            :disabled="readonly"
            v-model="form.projet.change_chauffage"
            :options="[
              { text: 'Oui', value: 'Oui' },
              { text: 'Non', value: 'Non' }
            ]"
            name="input-change_chauffage"
            plain
            @change="
              handleRadio(form.projet.change_chauffage, 'change_chauffage')
            "
          ></b-form-checkbox-group>
        </b-form-group>
        <!-- Surface -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Surface"
          label-for="input-surface"
        >
          <b-form-input
            :disabled="readonly"
            v-model="form.projet.surface"
            id="input-surface"
            size="sm"
          ></b-form-input>
        </b-form-group>
      </tab-content>
      <!-- RDV - Relevé Technique AMO -->
      <tab-content
        title="RDV - Relevé Technique AMO"
        icon="ti-calendar"
        :before-change="validateRdvAmoTech"
        v-if="typeProjet && typeProjet.id != 17"
      >
        <!-- filiale -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Filiale"
          label-for="input-filiale"
        >
          <b-form-select
            :disabled="readonly"
            v-model="form.projet.filiale"
            :options="getProjectsFilialeAgence || []"
            @change="changeFiliale($event)"
            value-field="id"
            text-field="name"
            id="input-filiale"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- Agence -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Agence"
          label-for="input-agence"
        >
          <b-form-select
            :disabled="readonly"
            v-model="form.projet.agence"
            :options="getProjectRouteDynamic || []"
            @change="changeAgence($event)"
            value-field="id"
            text-field="name"
            id="input-agence"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- AMO  -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="AMO"
          label-for="input-amo"
        >
          <b-form-select
            :disabled="readonly"
            v-model="form.projet.amo"
            :options="[...optionAmo] || []"
            value-field="id"
            text-field="full_name"
            id="input-amo"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- Date RDV Le -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="RDV le"
          label-for="input-rdv-le"
        >
          <b-form-input
            :disabled="readonly"
            v-model="form.projet.dateRdv"
            type="datetime-local"
            id="input-rdv-le"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Statut Relevé Technique -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Statut Relevé Technique"
          label-for="input-statut-relvee-tech"
        >
          <b-form-select
            :disabled="readonly"
            v-model="form.projet.statutReleveTech"
            :options="[
              { value: 'Planifié', text: 'Planifié' },
              { value: 'Confirmé', text: 'Confirmé' },
              { value: 'Réalisé', text: 'Réalisé' },
              { value: 'Annulé', text: 'Annulé' }
            ]"
            id="input-statut-relvee-tech"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </tab-content>
      <div class="three-dots-loading" v-if="loadingWizard">
        Chargement en cours
      </div>
      <div v-if="errorMsg">
        <span class="error">{{ errorMsg }}</span>
      </div>
      <div class="message" v-if="getContactsError">
        <div v-if="getContactsError" class="error">
          <ul v-if="Array.isArray(getContactsError)">
            <li v-for="(e, index) in getContactsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getContactsError }}</span>
        </div>
      </div>
      <div class="message" v-if="getProjectsError">
        <div v-if="getProjectsError" class="error">
          <ul v-if="Array.isArray(getProjectsError)">
            <li v-for="(e, index) in getProjectsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsError }}</span>
        </div>
      </div>
      <b-button variant="outline-secondary" slot="prev" size="sm">
        Précédent
      </b-button>
      <b-button variant="outline-secondary" slot="next" size="sm">
        Suivant
      </b-button>
      <b-button variant="success" slot="finish" size="sm">
        Enregistrer
      </b-button>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    FormWizard,
    TabContent
  },
  props: {
    entity: {
      required: true
    },
    categoryContact: {
      required: false
    },
    categoryProjet: {
      required: false
    },
    readonly: {
      required: false
    },
    typeProjet: {
      required: true
    }
  },
  data() {
    return {
      subtitle: 'Formulaire rapide',
      loadingWizard: false,
      errorMsg: null,
      form: {
        contact: {
          // Coordonnées
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          codePostal: '',
          city: '',
          address: '',
          source: [],
          // Civilité
          nomNaissance: '',
          dateNaissance: '',
          lieuNaissance: ''
        },
        projet: {
          // Informations Logement
          occupation: '',
          type_logement: '',
          parcelle_cadastrale: '',
          type_chauffage: '',
          type_energy: '',
          profite_iso: [],
          change_chauffage: [],
          surface: '',
          // RDV - Relevé Technique AMO
          agence: '',
          amo: '',
          dateRdv: '',
          statutReleveTech: ''
        }
      },
      copyForm: {
        contact: {
          // Coordonnées
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          codePostal: '',
          city: '',
          address: '',
          // Civilité
          nomNaissance: '',
          dateNaissance: '',
          lieuNaissance: ''
        },
        projet: {
          // Informations Logement
          occupation: '',
          type_logement: '',
          parcelle_cadastrale: '',
          type_chauffage: '',
          type_energy: '',
          profite_iso: [],
          change_chauffage: [],
          surface: '',
          // RDV - Relevé Technique AMO
          filiale: '',
          agence: '',
          amo: '',
          dateRdv: '',
          statutReleveTech: ''
        }
      },
      egaliteProjet: true,
      egaliteContact: true,
      optionAmo: [],
      bloqueAppelRouteContact: false,
      bloqueAppelRouteProjet: false
    }
  },
  methods: {
    ...mapActions([
      'fetchProjectFilialeIDName',
      'fetchProjectAmoCommercialIte',
      'fetchProjectRouteDynamic',
      'updateProject',
      'updateContact',
      'resetErrorContact',
      'resetErrorProject',
      'fetchProjectSources'
    ]),
    handleRadio(data, label) {
      if (label == 'change_chauffage') {
        this.form.projet.change_chauffage = []
        this.form.projet.change_chauffage = [data[data.length - 1]]
      } else {
        this.form.projet.profite_iso = []
        this.form.projet.profite_iso = [data[data.length - 1]]
      }
    },
    changeFiliale(id_filiale) {
      this.fetchProjectRouteDynamic('/api/teams/' + id_filiale + '/depots')
      this.form.projet.agence = null
      this.form.projet.amo = null
      this.optionAmo = []
    },
    async changeAgence(id_agence) {
      await this.fetchProjectAmoCommercialIte(id_agence)
      this.form.projet.amo = null
      if (
        this.getProjectsUsersAmoCommIte &&
        this.getProjectsUsersAmoCommIte.length
      ) {
        this.optionAmo = this.getProjectsUsersAmoCommIte.sort(function compare(
          a,
          b
        ) {
          if (a.full_name < b.full_name) return -1
          if (a.full_name > b.full_name) return 1
          return 0
        })
      }
    },
    async onComplete() {
      this.loadingWizard = true
      // contact
      const payloadContact = {
        entity: this.entity,
        formLeadContact: {
          first_name: this.form.contact.firstName,
          last_name: this.form.contact.lastName,
          emails: [{ email: this.form.contact.email, typeE: '' }],
          phones: [{ number: this.form.contact.phone, typeP: '' }],
          zipCode: this.form.contact.codePostal,
          city: this.form.contact.city,
          adresse: this.form.contact.address
        }
      }
      // NOT UPDATE THE CHAMP WITH TYPE PHOTOVOLTAIQUE
      if (this.typeProjet && this.typeProjet.id != 17) {
        payloadContact.formLeadContact.nom_naissance = this.form.contact.nomNaissance
        payloadContact.formLeadContact.city_naissance = this.form.contact.lieuNaissance
        payloadContact.formLeadContact.date_naissance = this.form.contact
          .dateNaissance
          ? moment(this.form.contact.dateNaissance, 'YYYY-MM-DD').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : this.form.contact.dateNaissance
      }
      // projet
      let date_time = this.form.projet.dateRdv
        ? moment(this.form.projet.dateRdv, 'YYYY-MM-DDTHH:mm').format(
            'YYYY-MM-DD HH:mm:ss'
          )
        : this.form.projet.dateRdv
      const payloadProject = {
        entity: this.entity,
        formLeadProject: {
          // Informations Logement
          occupation: this.form.projet.occupation,
          housing_type: this.form.projet.type_logement,
          cadastral_plot: this.form.projet.parcelle_cadastrale,
          type_heating: this.form.projet.type_chauffage,
          type_energie: this.form.projet.type_energy,
          profite_iso:
            this.form.projet.profite_iso &&
            Array.isArray(this.form.projet.profite_iso)
              ? this.form.projet.profite_iso[0]
              : null,
          changer_chauff:
            this.form.projet.change_chauffage &&
            Array.isArray(this.form.projet.change_chauffage)
              ? this.form.projet.change_chauffage[0]
              : null,
          // RDV - Relevé Technique AMO
          team_comm_ite_id: this.form.projet.filiale,
          depot_comm_ite_id: this.form.projet.agence,
          commercial_itinerant: this.form.projet.amo,
          date_previsite: date_time,
          hour_previsite: date_time
            ? moment(date_time.split(' ')[1], 'HH:mm:ss').format('HH:mm')
            : date_time,
          statut_releve_technique: this.form.projet.statutReleveTech,
          // Coordonnées
          zipCode: this.form.contact.codePostal
            ? this.form.contact.codePostal
            : null,
          //
          source_id: this.form.contact.source
        }
      }
      // surface
      payloadProject.formLeadProject[
        '814c739850155e4e6776b33e5c2db929c243257cd8a6eb3d3d'
      ] = this.form.projet.surface
      //
      this.shallowEqual(this.form, this.copyForm)
      if (this.form.projet.profite_iso) {
        this.form.projet.profite_iso = [this.form.projet.profite_iso]
      }
      if (this.form.projet.change_chauffage) {
        this.form.projet.change_chauffage = [this.form.projet.change_chauffage]
      }
      this.$nextTick(async () => {
        if (!this.egaliteContact) {
          if (!this.bloqueAppelRouteContact) {
            const response = await this.updateContact(payloadContact)
            if (response) {
              this.$emit('actionFormLeads', true)
            } else {
              this.$emit('actionFormLeads', false)
            }
          }
          // source
          if (this.form.contact.source != this.copyForm.contact.source) {
            this.bloqueAppelRouteProjet = true
            if (this.typeProjet && this.typeProjet.id != 17) {
              const response = await this.updateProject(payloadProject)
              if (response) {
                this.$emit('actionFormLeads', true)
              } else {
                this.$emit('actionFormLeads', false)
              }
            } else {
              this.$emit('actionFormLeads', true)
            }
          }
        }
        if (!this.egaliteProjet) {
          if (!this.bloqueAppelRouteProjet) {
            const response = await this.updateProject(payloadProject)
            if (response) {
              this.$emit('actionFormLeads', true)
            } else {
              this.$emit('actionFormLeads', false)
            }
          }
        }
        if (this.egaliteProjet == true && this.egaliteContact == true) {
          this.$emit('actionFormLeads', true)
        }
        this.loadingWizard = false
      })
    },
    setLoading: function(value) {
      this.loadingWizard = value
    },
    handleErrorMessage: function(errorMsg) {
      this.errorMsg = errorMsg
    },
    // Validations info.contact
    validateClientInfo: function() {
      return new Promise((resolve, reject) => {
        if (!this.readonly) {
          if (!this.form.contact.codePostal) {
            reject('Le champs "Code postal" est obligatoire')
          } else if (
            this.form.contact.codePostal &&
            !this.validatePostalCode(this.form.contact.codePostal)
          ) {
            reject('Le champs "Code postal" est invalide')
          } else {
            resolve(true)
          }
        } else {
          resolve(true)
        }
      })
    },
    //  validation rdv-techn.amo
    validateRdvAmoTech: function() {
      return new Promise((resolve, reject) => {
        if (!this.readonly) {
          if (!this.form.projet.amo && this.form.projet.agence) {
            reject('Le champs "AMO" est obligatoire')
          } else {
            resolve(true)
          }
        } else {
          resolve(true)
        }
      })
    },
    // Validations EMAIL
    validateEmail(value) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(value).toLowerCase()
      )
    },
    // Validations CODE POSTAL
    validatePostalCode(value) {
      return /^([0-9]{5})$/.test(value)
    },
    // Comparison deux object
    shallowEqual(object1, object2) {
      const keys_1_contact = Object.keys(object1.contact)
      const keys_1_projet = Object.keys(object1.projet)
      const keys_2_contact = Object.keys(object2.contact)
      const keys_2_projet = Object.keys(object2.projet)

      if (keys_1_contact.length !== keys_2_contact.length) {
        this.egaliteContact = false
      }
      if (keys_1_projet.length !== keys_2_projet.length) {
        this.egaliteProjet = false
      }
      // contact
      for (let key of keys_1_contact) {
        if (object1.contact[key] !== object2.contact[key]) {
          this.egaliteContact = false
          if (key == 'source') {
            this.bloqueAppelRouteContact = true
          }
          break
        } else {
          this.egaliteContact = true
        }
      }
      // projet
      for (let key of keys_1_projet) {
        if (key == 'profite_iso' || key == 'change_chauffage') {
          if (object1.projet[key] && object1.projet[key].length) {
            object1.projet[key] = object1.projet[key][0]
          } else {
            object1.projet[key] = null
          }
          if (object2.projet[key] && object2.projet[key].length) {
            object2.projet[key] = object2.projet[key][0]
          } else {
            object2.projet[key] = null
          }
        }
        if (object1.projet[key] !== object2.projet[key]) {
          this.egaliteProjet = false
          break
        } else {
          this.egaliteProjet = true
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectRouteDynamic',
      'getProjectsFilialeAgence',
      'getProjectsFiliale',
      'getProjectsUsersAmoCommIte',
      'getProjectsError',
      'getContactsError',
      'getProjectsUsersSources'
    ])
  },
  async mounted() {
    this.resetErrorContact()
    this.resetErrorProject()
    await this.fetchProjectSources()
    this.fetchProjectFilialeIDName()
    if (this.form && this.form.projet && this.form.projet.amo) {
      this.optionAmo = this.getProjectsUsersAmoCommIte.sort(function compare(
        a,
        b
      ) {
        if (a.full_name < b.full_name) return -1
        if (a.full_name > b.full_name) return 1
        return 0
      })
    }
    // contact
    if (this.categoryContact && this.categoryContact.length) {
      for (let index = 0; index < this.categoryContact.length; index++) {
        // Coordonnées
        if (this.categoryContact[index].name == 'Coordonnées') {
          if (
            this.categoryContact[index].fields &&
            this.categoryContact[index].fields.length
          ) {
            for (
              let j = 0;
              j < this.categoryContact[index].fields.length;
              j++
            ) {
              switch (this.categoryContact[index].fields[j].key) {
                case 'last_name':
                  this.form.contact.lastName = this.categoryContact[
                    index
                  ].fields[j].value
                  this.copyForm.contact.lastName = this.categoryContact[
                    index
                  ].fields[j].value
                  break
                case 'first_name':
                  this.form.contact.firstName = this.categoryContact[
                    index
                  ].fields[j].value
                  this.copyForm.contact.firstName = this.categoryContact[
                    index
                  ].fields[j].value
                  break
                case 'emails':
                  this.form.contact.email =
                    this.categoryContact[index].fields[j].value[0] &&
                    this.categoryContact[index].fields[j].value[0].email
                      ? this.categoryContact[index].fields[j].value[0].email
                      : ''
                  this.copyForm.contact.email =
                    this.categoryContact[index].fields[j].value[0] &&
                    this.categoryContact[index].fields[j].value[0].email
                      ? this.categoryContact[index].fields[j].value[0].email
                      : ''
                  break
                case 'phones':
                  this.form.contact.phone =
                    this.categoryContact[index].fields[j].value[0] &&
                    this.categoryContact[index].fields[j].value[0].number
                      ? this.categoryContact[index].fields[j].value[0].number
                      : ''
                  this.copyForm.contact.phone =
                    this.categoryContact[index].fields[j].value[0] &&
                    this.categoryContact[index].fields[j].value[0].number
                      ? this.categoryContact[index].fields[j].value[0].number
                      : ''
                  break
                case 'zipCode':
                  this.form.contact.codePostal = this.categoryContact[
                    index
                  ].fields[j].value
                  this.copyForm.contact.codePostal = this.categoryContact[
                    index
                  ].fields[j].value
                  break
                case 'adresse':
                  this.form.contact.address = this.categoryContact[
                    index
                  ].fields[j].value
                  this.copyForm.contact.address = this.categoryContact[
                    index
                  ].fields[j].value
                  break
                case 'city':
                  this.form.contact.city = this.categoryContact[index].fields[
                    j
                  ].value
                  this.copyForm.contact.city = this.categoryContact[
                    index
                  ].fields[j].value
                  break
                // Civilité
                case 'date_naissance':
                  this.form.contact.dateNaissance = this.categoryContact[index]
                    .fields[j].value
                    ? moment(
                        this.categoryContact[index].fields[j].value,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('YYYY-MM-DD')
                    : this.categoryContact[index].fields[j].value
                  this.copyForm.contact.dateNaissance = this.categoryContact[
                    index
                  ].fields[j].value
                    ? moment(
                        this.categoryContact[index].fields[j].value,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('YYYY-MM-DD')
                    : this.categoryContact[index].fields[j].value
                  break
                default:
                  break
              }
            }
          }
        }
        // Civilité
        if (this.categoryContact[index].name == 'Civilité') {
          if (
            this.categoryContact[index].fields &&
            this.categoryContact[index].fields.length
          ) {
            for (
              let j = 0;
              j < this.categoryContact[index].fields.length;
              j++
            ) {
              switch (this.categoryContact[index].fields[j].key) {
                case 'nom_naissance':
                  this.form.contact.nomNaissance = this.categoryContact[
                    index
                  ].fields[j].value
                  this.copyForm.contact.nomNaissance = this.categoryContact[
                    index
                  ].fields[j].value
                  break
                case 'city_naissance':
                  this.form.contact.lieuNaissance = this.categoryContact[
                    index
                  ].fields[j].value
                  this.copyForm.contact.lieuNaissance = this.categoryContact[
                    index
                  ].fields[j].value
                  break
                default:
                  break
              }
            }
          }
        }
      }
    }
    // project
    if (this.categoryProjet && this.categoryProjet.length) {
      for (let index = 0; index < this.categoryProjet.length; index++) {
        if (
          this.categoryProjet[index].subcategories &&
          this.categoryProjet[index].subcategories.length
        ) {
          for (
            let index_1 = 0;
            index_1 < this.categoryProjet[index].subcategories.length;
            index_1++
          ) {
            // Informations Logement
            if (this.categoryProjet[index].subcategories[index_1].id == 10) {
              if (
                this.categoryProjet[index].subcategories[index_1].fields &&
                this.categoryProjet[index].subcategories[index_1].fields.length
              ) {
                for (
                  let j = 0;
                  j <
                  this.categoryProjet[index].subcategories[index_1].fields
                    .length;
                  j++
                ) {
                  switch (
                    this.categoryProjet[index].subcategories[index_1].fields[j]
                      .key
                  ) {
                    case 'occupation':
                      this.form.projet.occupation =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      this.copyForm.projet.occupation =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      break
                    case 'housing_type':
                      this.form.projet.type_logement =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      this.copyForm.projet.type_logement =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      break
                    case 'cadastral_plot':
                      this.form.projet.parcelle_cadastrale =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      this.copyForm.projet.parcelle_cadastrale =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      break
                    case 'type_heating':
                      this.form.projet.type_chauffage =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      this.copyForm.projet.type_chauffage =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      break
                    case 'type_energie':
                      this.form.projet.type_energy =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      this.copyForm.projet.type_energy =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      break
                    case 'profite_iso':
                      this.form.projet.profite_iso =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : [
                              this.categoryProjet[index].subcategories[index_1]
                                .fields[j].value
                            ]
                      this.copyForm.projet.profite_iso =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : [
                              this.categoryProjet[index].subcategories[index_1]
                                .fields[j].value
                            ]
                      break
                    case 'changer_chauff':
                      this.form.projet.change_chauffage =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : [
                              this.categoryProjet[index].subcategories[index_1]
                                .fields[j].value
                            ]
                      this.copyForm.projet.change_chauffage =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : [
                              this.categoryProjet[index].subcategories[index_1]
                                .fields[j].value
                            ]
                      break
                    case '814c739850155e4e6776b33e5c2db929c243257cd8a6eb3d3d':
                      this.form.projet.surface =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      this.copyForm.projet.surface =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      break
                    default:
                      break
                  }
                }
              }
            }
            // Rendez-vous & Dates
            if (this.categoryProjet[index].subcategories[index_1].id == 12) {
              if (
                this.categoryProjet[index].subcategories[index_1].fields &&
                this.categoryProjet[index].subcategories[index_1].fields.length
              ) {
                for (
                  let j = 0;
                  j <
                  this.categoryProjet[index].subcategories[index_1].fields
                    .length;
                  j++
                ) {
                  switch (
                    this.categoryProjet[index].subcategories[index_1].fields[j]
                      .key
                  ) {
                    case 'team_comm_ite_id':
                      this.form.projet.filiale =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      this.copyForm.projet.filiale =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      break
                    case 'depot_comm_ite_id':
                      if (this.form.projet.filiale) {
                        await this.fetchProjectRouteDynamic(
                          '/api/teams/' + this.form.projet.filiale + '/depots'
                        )
                      }
                      this.form.projet.agence =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      this.copyForm.projet.agence =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      break
                    case 'commercial_itinerant':
                      if (this.form.projet.agence) {
                        const response = await this.fetchProjectAmoCommercialIte(
                          this.form.projet.agence
                        )
                        if (
                          response &&
                          this.getProjectsUsersAmoCommIte &&
                          this.getProjectsUsersAmoCommIte.length
                        ) {
                          this.optionAmo = this.getProjectsUsersAmoCommIte.sort(
                            function compare(a, b) {
                              if (a.full_name < b.full_name) return -1
                              if (a.full_name > b.full_name) return 1
                              return 0
                            }
                          )
                        }
                      }

                      this.form.projet.amo =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      this.copyForm.projet.amo =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      break
                    case 'date_previsite':
                      this.form.projet.dateRdv = this.categoryProjet[index]
                        .subcategories[index_1].fields[j].value
                        ? moment(
                            this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('YYYY-MM-DDTHH:mm')
                        : this.categoryProjet[index].subcategories[index_1]
                            .fields[j].value
                      this.copyForm.projet.dateRdv = this.categoryProjet[index]
                        .subcategories[index_1].fields[j].value
                        ? moment(
                            this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('YYYY-MM-DDTHH:mm')
                        : this.categoryProjet[index].subcategories[index_1]
                            .fields[j].value
                      break
                    case 'statut_releve_technique':
                      this.form.projet.statutReleveTech = this.categoryProjet[
                        index
                      ].subcategories[index_1].fields[j].value
                      this.copyForm.projet.statutReleveTech = this.categoryProjet[
                        index
                      ].subcategories[index_1].fields[j].value
                      break
                    default:
                      break
                  }
                }
              }
            }
            // Informations marketing
            if (this.categoryProjet[index].subcategories[index_1].id == 28) {
              if (
                this.categoryProjet[index].subcategories[index_1].fields &&
                this.categoryProjet[index].subcategories[index_1].fields.length
              ) {
                for (
                  let j = 0;
                  j <
                  this.categoryProjet[index].subcategories[index_1].fields
                    .length;
                  j++
                ) {
                  switch (
                    this.categoryProjet[index].subcategories[index_1].fields[j]
                      .key
                  ) {
                    case 'source_id':
                      this.form.contact.source =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      this.copyForm.contact.source =
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value &&
                        this.categoryProjet[index].subcategories[index_1]
                          .fields[j].value.id
                          ? this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value.id
                          : this.categoryProjet[index].subcategories[index_1]
                              .fields[j].value
                      break
                    default:
                      break
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.wizard {
  .tab-content-label {
    font-weight: 600;
    position: relative;
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #5e5e5e;
      display: block;
      position: absolute;
      left: -10px;
      top: 8px;
    }
  }
  .input-phone {
    border: 1px solid #ced4da;
    width: 100%;
    height: 32px;
  }
  .error {
    color: #ff6961;
    font-size: 14px;
  }
  .wizard-icon-circle,
  .ProseMirror {
    &:focus {
      outline: unset;
    }
  }
  .form-check-inline {
    .form-check-input {
      height: 17px;
      width: 24px;
    }
  }
}
</style>
