<template>
  <div class="activity-file" :class="{ preview: preview }">
    <div v-if="gestionFile">
      <b-form-checkbox
        class="checkbox-terminer"
        v-model="entity.checkUpload"
        :value="1"
        :unchecked-value="0"
        @change="selectedChange"
      >
      </b-form-checkbox>
    </div>
    <div class="icon-entity-file">
      <font-awesome-icon class="icon-historique-files" icon="paperclip" />
    </div>
    <div class="page-content-file">
      <div class="description-bloc-file">
        <div class="activity-file-icon">
          <font-awesome-icon v-if="isImage" icon="file-image" />
          <font-awesome-icon v-else-if="isWord" icon="file-word" />
          <font-awesome-icon v-else-if="isPowerpoint" icon="file-powerpoint" />
          <font-awesome-icon v-else-if="isExcel" icon="file-excel" />
          <font-awesome-icon v-else-if="isPdf" icon="file-pdf" />
          <font-awesome-icon v-else-if="isCsv" icon="file-csv" />
          <font-awesome-icon v-else-if="isDocument" icon="file-alt" />
          <font-awesome-icon v-else-if="isArchive" icon="file-archive" />
          <font-awesome-icon v-else-if="isAudio" icon="file-audio" />
          <font-awesome-icon v-else icon="file" />
        </div>
        <div class="file-name-action">
          <a v-if="isImage" @click="showImg" href="" class="name">
            {{ entity.title }}
          </a>
          <a
            v-else-if="
              isArchive ||
                isWord ||
                isPowerpoint ||
                isExcel ||
                isCsv ||
                isDocument ||
                isAudio
            "
            target="_blank"
            class="name-not-show"
          >
            {{ entity.title }}
          </a>
          <a v-else :href="entity.url" target="_blank" class="name">
            {{ entity.title }}
          </a>
          <vue-easy-lightbox
            :visible="visible"
            :imgs="entity.url"
            @hide="handleHide"
          >
          </vue-easy-lightbox>
          <b-dropdown
            v-if="!readonly"
            size="lg"
            variant="transparent"
            toggle-class="activity-dropdown-button"
            no-caret
            text="..."
          >
            <b-dropdown-item @click="hundleClickUpdateFile">
              Modifier la description
            </b-dropdown-item>
            <b-dropdown-item @click="hundleClickTelechargerFile">
              Télécharger
            </b-dropdown-item>
            <b-dropdown-item @click="hundleClickDeleteFile">
              Supprimer
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="file-description">
          {{ entity.description ? entity.description : '' }}
        </div>
        <div class="file-category">
          <span class="file-category-label">Catégorie:</span>
          {{ entity.categorie ? entity.categorie : '' }}
        </div>
        <div class="file-info">
          <span v-if="entity.created_at">
            {{ entity.created_at | date('DD MMM') }}
            {{ entity.created_at | time('HH:mm') }}
          </span>
          -
          <span class="file-info-user">
            {{
              entity.created_by && entity.created_by.full_name
                ? entity.created_by.full_name
                : ''
            }}
          </span>
        </div>
      </div>
    </div>
    <b-modal
      id="update-file"
      no-close-on-backdrop
      ref="update-file"
      :hide-footer="true"
      title="Modifier la description"
    >
      <div class="name-file">Fichier : {{ entity.title }}</div>
      <div class="text-file mt-2">
        <label>Description :</label>
        <b-form-textarea
          class="mt-2"
          rows="3"
          no-resize
          v-model="description"
        ></b-form-textarea>
      </div>
      <div class="form-actions mt-2">
        <b-button variant="outline-secondary" @click="hideModal('update-file')">
          Annuler
        </b-button>
        <b-button variant="success" type="submit" @click="addDescriptionFile">
          Enregistrer
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="delete-file"
      no-close-on-backdrop
      ref="delete-file"
      :hide-footer="true"
      title="Supprimer le fichier"
    >
      <div class="text">
        Êtes-vous sûr de vouloir supprimer ce fichier ?
      </div>
      <div class="form-actions">
        <b-button variant="outline-secondary" @click="hideModal('delete-file')">
          Annuler
        </b-button>
        <b-button variant="danger" @click="doDeleteFile">
          Supprimer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
moment.locale('fr')
import { mapActions } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  components: {
    VueEasyLightbox
  },
  props: {
    entity: { required: true },
    project: { required: false },
    preview: { default: false },
    readonly: {
      default: false
    },
    gestionFile: { required: false },
    checkedTous: { required: false },
    categorie: { required: false }
  },
  data() {
    return {
      visible: false,
      description: this.entity.description
    }
  },
  methods: {
    ...mapActions([
      'UpdateFile',
      'deleteFile',
      'downloadFile',
      'fetchProjectHistoriques',
      'resetPageValue',
      'fetchNumbersFilesSubCategoriePJ'
    ]),
    selectedChange(checked) {
      let check = []
      //  categ Tous
      if (this.categorie && this.categorie.name == 'Tous') {
        for (let index = 0; index < this.project.files.length; index++) {
          if (this.entity.id == this.project.files[index].id) {
            this.project.files[index].checkUpload = checked
          }
        }
        check = this.project.files.filter(item => item.checkUpload == 1)
      }
      // autres catego
      else {
        for (let index = 0; index < this.categorie.files.length; index++) {
          if (this.entity.id == this.categorie.files[index].id) {
            this.categorie.files[index].checkUpload = checked
          }
        }
        check = this.categorie.files.filter(item => item.checkUpload == 1)
      }
      this.$emit('checkedBox', check, this.categorie)
    },
    showImg(event) {
      event.preventDefault()
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    hundleClickUpdateFile() {
      this.description = this.entity.description
      this.$refs['update-file'].show()
    },
    hideModal(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].hide()
      }
    },
    hundleClickTelechargerFile() {
      this.downloadFile(this.entity)
    },
    async addDescriptionFile() {
      await this.UpdateFile({
        data: this.description,
        file: this.entity,
        project: this.project
      })
      this.hideModal('update-file')
    },
    async hundleClickDeleteFile() {
      this.$refs['delete-file'].show()
    },
    async doDeleteFile() {
      await this.deleteFile({
        file: this.entity,
        project: this.project
      })
      this.resetPageValue({ object: 'tous' })
      this.fetchProjectHistoriques({
        project: this.project,
        page: 1,
        reset: 1
      })
      this.fetchNumbersFilesSubCategoriePJ(this.project.id)
      this.hideModal('delete-file')
    }
  },
  computed: {
    isImage: function() {
      return (
        this.entity.extension &&
        ['jpg', 'jpeg', 'png', 'bmp', 'gif'].find(
          e => e == this.entity.extension.toLowerCase()
        )
      )
    },
    isWord: function() {
      return (
        this.entity.extension &&
        ['doc', 'docx'].find(e => e == this.entity.extension.toLowerCase())
      )
    },
    isPowerpoint: function() {
      return (
        this.entity.extension &&
        ['ppt', 'pptx'].find(e => e == this.entity.extension.toLowerCase())
      )
    },
    isExcel: function() {
      return (
        this.entity.extension &&
        ['xls', 'xlsx'].find(e => e == this.entity.extension.toLowerCase())
      )
    },
    isPdf: function() {
      return (
        this.entity.extension && this.entity.extension.toLowerCase() == 'pdf'
      )
    },
    isCsv: function() {
      return (
        this.entity.extension && this.entity.extension.toLowerCase() == 'csv'
      )
    },
    isDocument: function() {
      return (
        this.entity.extension &&
        ['txt', 'odt', 'ods'].find(
          e => e == this.entity.extension.toLowerCase()
        )
      )
    },
    isArchive: function() {
      return (
        this.entity.extension &&
        ['rar', 'zip', '7z'].find(e => e == this.entity.extension.toLowerCase())
      )
    },
    isAudio: function() {
      return (
        this.entity.extension &&
        ['mp3', 'ogg', 'wav', 'wma'].find(
          e => e == this.entity.extension.toLowerCase()
        )
      )
    }
  },
  filters: {
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return '--:--'
      return moment(value).format(format)
    }
  },
  watch: {
    checkedTous(e) {
      this.entity.checkUpload = e
    }
  }
}
</script>

<style lang="scss">
.activity-file {
  align-items: center;
  display: flex;
  .activity-dropdown-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
    padding: 9px;
    width: 28px;
    height: 25px;
    line-height: 0;
    box-shadow: none !important;
  }
}
</style>
<style lang="scss" scoped>
.activity-file {
  position: relative;
  .icon-entity-file {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 35px;
    height: 35px;
    z-index: 2;
    top: 12px;
    left: -34px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 50%;
    font-size: 16px;
    .icon-historique-files {
      transform: rotateY(180deg);
    }
  }
  .page-content-file {
    padding-bottom: 9px;
    width: 94%;
    max-width: 100%;

    .description-bloc-file {
      position: relative;
      height: auto;
      font-size: 12px;
      margin-top: 6px;
      padding: 8px 15px 8px 50px;
      background-color: #f9f9f97a;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      margin-left: 26px;
      .activity-file-icon {
        position: absolute;
        display: block;
        left: 10px;
        top: 20px; //8px
        font-size: 40px;
      }
      .file-name-action {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .name,
        .name-not-show {
          display: block;
          max-width: 85%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #2dabe2;
        }
        .name-not-show {
          &:hover {
            text-decoration: none;
          }
        }
      }

      .file-category {
        .file-category-label {
          font-weight: 600;
        }
      }
      .file-info {
        font-weight: 600;
        .file-info-user {
          text-transform: capitalize;
        }
      }

      &:before {
        content: '';
        position: absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #d8d8d8;
        left: -9px;
        top: 20px;
      }

      &:after {
        content: '';
        position: absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #fcfcfc;
        left: -8px;
        top: 20px;
      }
    }
  }

  &.preview {
    .icon-entity-file {
      display: none;
    }
    .page-content-file {
      .description-bloc-file {
        margin-left: 0;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}
#update-file {
  .name-file {
    font-weight: bold;
  }
}
#delete-file {
  .text {
    padding-bottom: 12px;
  }
}
</style>
